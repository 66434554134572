import React, { CSSProperties } from 'react'
import s from './Container.module.scss'

interface ContainerProps {
    children: React.ReactNode
    className?: string
    wide?: boolean
    xlWide?: boolean
    extraClass?: string
    style?: CSSProperties
}

const Container = ({
    children,
    className,
    wide,
    xlWide,
    extraClass,
    style
}: ContainerProps): JSX.Element => {
    // const { children, className, wide, extraClass } = props

    // console.log('Container', { children, className, wide, xlWide, extraClass })

    const classes = [s.container]
    if (wide) {
        classes.push(s.wide)
    }

    if (extraClass) {
        classes.push(extraClass)
    }

    if (xlWide) {
        classes.push(s.xlWide)
    }

    return (
        <div
            style={style}
            className={`${classes.join(' ')} ${
                className !== undefined ? className : null
            }`}
        >
            {children}
        </div>
    )
}

Container.defaultProps = {
    className: '',
    wide: false,
    xlWide: false,
    extraClass: '',
}

export default Container
