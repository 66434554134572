import React from 'react'
// import s from './Platforms.module.scss'

const IconSteam = (): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="12" cy="12" r="12" fill="#101010" />
        <path
            d="M11.9791 0C5.66463 0 0.491566 4.86068 0 11.0377L6.4425 13.6967C6.98811 13.3243 7.64796 13.1063 8.35757 13.1063C8.42109 13.1063 8.48414 13.1081 8.54719 13.1114L11.4127 8.96588C11.4127 8.94619 11.4122 8.92696 11.4122 8.90726C11.4122 6.41197 13.4458 4.38141 15.9453 4.38141C18.4449 4.38141 20.4785 6.41197 20.4785 8.90726C20.4785 11.4026 18.4449 13.4331 15.9453 13.4331C15.9107 13.4331 15.8766 13.4322 15.8425 13.4312L11.7564 16.342C11.7582 16.3955 11.7601 16.4489 11.7601 16.5029C11.7601 18.3763 10.2338 19.9 8.35805 19.9C6.71128 19.9 5.33423 18.7262 5.02185 17.1721L0.4143 15.2705C1.84112 20.3075 6.47663 24 11.9796 24C18.6184 24 24 18.6272 24 12.0005C23.9991 5.37278 18.6179 0 11.9791 0Z"
            fill="white"
        />
        <path
            d="M7.55189 18.1362L6 17.5346C6.27501 18.0718 6.75079 18.5218 7.383 18.7693C8.74856 19.3032 10.3229 18.6952 10.8923 17.4123C11.1678 16.7912 11.1693 16.1062 10.8963 15.4846C10.6238 14.8627 10.1086 14.3774 9.44704 14.1183C8.79041 13.862 8.08745 13.8713 7.46919 14.09L9.07239 14.7125C10.0797 15.1069 10.556 16.1924 10.1365 17.1379C9.71656 18.0834 8.55875 18.5302 7.55189 18.1362Z"
            fill="white"
        />
        <path
            d="M13.6889 8.88438C13.6889 7.65496 14.7488 6.6587 16.0553 6.6587C17.3629 6.6587 18.4218 7.65496 18.4218 8.88438C18.4218 10.1138 17.3624 11.1101 16.0553 11.1101C14.7488 11.1101 13.6889 10.1133 13.6889 8.88438ZM19.2004 8.88945C19.2004 7.25544 17.787 5.92603 16.0504 5.92603C14.3132 5.92603 12.9004 7.25544 12.9004 8.88945C12.9004 10.523 14.3132 11.8519 16.0504 11.8519C17.787 11.8519 19.2004 10.523 19.2004 8.88945Z"
            fill="white"
        />
    </svg>
)

export default IconSteam
