import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Icon, AlertMessageV2 } from 'layouts'
import { getLoggedInJwt, isLoggedIn } from 'packages/authentication'
import { ButtonWithTarget } from 'layouts/button-with-target'
import { getQuery } from 'lib/location/selectors'
import {
    submitLinkRequest,
    getTwitchLinkStatus,
    submitUnlinkRequest,
} from 'services/api/twitch'
import { LinkingRequestModel } from 'models/twitch/LinkingModels'
import { ClipLoader } from 'react-spinners'
import { Translate, TranslateFunction } from 'react-localize-redux'
import getConfig from 'config/web'
import Logger from 'utils/logging'
import ReactMarkdown from 'utils/markdown'
import { GlobalState } from 'types'
import s from './components/page/twitch.module.scss'
import {
    LinkingStateTypeEnum,
    ErrorCodeType,
    QueryParameterProps,
} from './types'
import TwitchPage from './components/page'
import UnlinkTwitchButton from './components/unlink-button'

const { webBaseUrl } = getConfig()

const Twitch = (): JSX.Element => {
    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))
    const userToken = useSelector((state: GlobalState) => getLoggedInJwt(state))

    const [displayState, setDisplayState] = useState<LinkingStateTypeEnum>(
        LinkingStateTypeEnum.Start
    )
    const [errorCode, setErrorCode] = useState<ErrorCodeType>(null)
    const [twitchUsername, setTwitchUsername] = useState<string>('')

    const queryParameters: QueryParameterProps = useSelector(
        (state: GlobalState) =>
            (getQuery(state) as unknown) as QueryParameterProps
    )

    const requestBody: LinkingRequestModel = {
        successCallbackUrl: `${webBaseUrl}/twitch?linked=true`,
        errorCallbackUrl: `${webBaseUrl}/twitch/?linked=false`,
    }

    useEffect(() => {
        if (loggedIn) {
            // First load shows loading briefly when checking for twitch link status
            setDisplayState(LinkingStateTypeEnum.Loading)

            // This happens when user gets back from successful linking, then show Linked right away and nothing more
            if (queryParameters?.linked === 'true') {
                setDisplayState(LinkingStateTypeEnum.Linked)
            } else if (queryParameters?.linked === 'false') {
                // This happens when user gets back from failed linking, then show error right away and nothing more
                // Set error code for error display to work with, can have specific error messages for each error code
                if (queryParameters?.error_code) {
                    setErrorCode(queryParameters?.error_code)
                }
                setDisplayState(LinkingStateTypeEnum.Error)
            } else {
                // First page load and we start with checking if user is linked or not
                const getTwitchLinkStatusRequest = async (): Promise<void> => {
                    const response = await getTwitchLinkStatus(userToken)
                    if (response) {
                        // User is already linked, show that
                        if (response.isLinked) {
                            if (response.twitchUsername) {
                                setTwitchUsername(response.twitchUsername) // Set twitch username for display
                            }
                            setDisplayState(LinkingStateTypeEnum.AlreadyLinked)
                        } else {
                            // If we get here, user can start linking
                            setDisplayState(LinkingStateTypeEnum.Start)
                        }
                    } else {
                        // This might happen if we get nothing back from the api,
                        // then the linking process has not started and we don't know if user is linked or not
                        setErrorCode('500')
                        setDisplayState(LinkingStateTypeEnum.GenericError)
                    }
                }
                getTwitchLinkStatusRequest()
            }
        }
    }, [loggedIn])

    const twitchLinkRequest = async (): Promise<void> => {
        setDisplayState(LinkingStateTypeEnum.Linking)
        const linkRequestResponse = await submitLinkRequest(
            userToken,
            requestBody
        )
        if (linkRequestResponse) {
            // check for errors first
            if (linkRequestResponse.hasError) {
                // Display error if we have it and nothing more we can do
                setErrorCode(linkRequestResponse.statusCode)
                setDisplayState(LinkingStateTypeEnum.Error)
                return
            }

            // If we are here, we have a redirect url to go through twitch linking
            if (window) {
                window.location.href = linkRequestResponse.redirectUrl
            }
        }
    }

    const twitchUnlinkRequest = async (): Promise<void> => {
        // Set display state to unlinking in progress message
        setDisplayState(LinkingStateTypeEnum.Unlinking)

        const unlinkRequestResponse = await submitUnlinkRequest(userToken)
        if (unlinkRequestResponse) {
            if (unlinkRequestResponse.unlinked) {
                setDisplayState(LinkingStateTypeEnum.Start)
            } else if (unlinkRequestResponse.hasError) {
                setErrorCode(unlinkRequestResponse.statusCode)
                setDisplayState(LinkingStateTypeEnum.Error)
            }
        }
    }

    function renderLink(translate: TranslateFunction): JSX.Element {
        return (
            <>
                <div onClick={() => twitchLinkRequest()}>
                    <ButtonWithTarget color="#fff" backgroundColor="#65469C">
                        {translate('TwitchLinking.LinkAccount').toString()}
                    </ButtonWithTarget>
                </div>
            </>
        )
    }

    function renderAlreadyLinked(translate: TranslateFunction): JSX.Element {
        const withBoldUsername = translate('TwitchLinking.TwitchUsernameIs')
            .toString()
            .replace('{twitch_username}', ` __${twitchUsername}__ `)

        return (
            <>
                <AlertMessageV2
                    icon={<Icon fixedWidth regular name="check-circle" />}
                    success
                >
                    {translate('TwitchLinking.AlreadyLinked').toString()}
                    <br />
                    <div className={s.usernameis}>
                        <ReactMarkdown source={withBoldUsername} />
                    </div>
                </AlertMessageV2>
                <UnlinkTwitchButton
                    unlinkFn={twitchUnlinkRequest}
                    translate={translate}
                />
            </>
        )
    }

    function renderLoading(translate: TranslateFunction): JSX.Element {
        return (
            <AlertMessageV2
                info
                icon={<ClipLoader size={52} color="#5CCBCB" loading />}
            >
                {translate('TwitchLinking.Loading').toString()}
            </AlertMessageV2>
        )
    }

    function renderLinking(translate: TranslateFunction): JSX.Element {
        return (
            <AlertMessageV2
                info
                icon={<ClipLoader size={52} color="#5CCBCB" loading />}
            >
                {translate('TwitchLinking.Linking').toString()}
            </AlertMessageV2>
        )
    }

    function renderLinked(translate: TranslateFunction): JSX.Element {
        return (
            <>
                <AlertMessageV2
                    icon={<Icon fixedWidth regular name="check-circle" />}
                    success
                >
                    {translate(
                        'TwitchLinking.AccountLinkedSuccesfully'
                    ).toString()}
                </AlertMessageV2>
                <UnlinkTwitchButton
                    unlinkFn={twitchUnlinkRequest}
                    translate={translate}
                />
            </>
        )
    }

    function renderUnlinking(translate: TranslateFunction): JSX.Element {
        return (
            <AlertMessageV2
                info
                icon={<ClipLoader size={52} color="#5CCBCB" loading />}
            >
                {translate('TwitchLinking.Unlinking').toString()}
            </AlertMessageV2>
        )
    }

    function renderError(translate: TranslateFunction): JSX.Element {
        // Error codes to handle
        // 400 Bad Request: invalid request parameters.
        // 401 Unauthorized: could not complete linking due to invalid authorization code.
        // 403 Forbidden: Twitch account is already linked to another EVE account. // WE CAN SIMULATE THIS
        // 404 Not Found: linking attempt not found
        // 409 Conflict: EVE account is already linked to Twitch account.
        // 500 Internal Server Error: something went really wrong.

        let errorMessage: string

        let showLink: boolean

        switch (errorCode) {
            // Error codes to show specific messages if needed

            // 400 Bad Request: invalid request parameters.
            // 401 Unauthorized: could not complete linking due to invalid authorization code.
            // 403 Forbidden: Twitch account is already linked to another EVE account. // WE CAN SIMULATE THIS
            // 404 Not Found: linking attempt not found
            // 408 Request Timeout: Twitch linking flow took too long.
            // 409 Conflict: EVE account is already linked to Twitch account.
            // 500 Internal Server Error: something went really wrong.
            case '403':
                errorMessage = translate(
                    'TwitchLinking.AccountLinkingErrorTwitchAccountAlreadyLinked'
                ).toString()
                break
            case '408':
                errorMessage =
                    translate(
                        'TwitchLinking.AccountLinkingTimeout'
                    ).toString() + (errorCode ? ` (${errorCode})` : '')
                showLink = true
                break
            case '409':
                errorMessage = translate(
                    'TwitchLinking.AccountLinkingErrorEveAccountAlreadyLinked'
                ).toString()
                break
            case '400':
            case '401':
            case '404':
            case '429':
            case '500':
            default:
                errorMessage =
                    translate('TwitchLinking.AccountLinkingError').toString() +
                    (errorCode ? ` (${errorCode})` : '') // Append error code to error message if error code exists
                // showLink = true
                break
        }

        try {
            Logger.captureException(
                new Error(
                    `Error Message Displayed in Twitch Linking. error_code: ${errorCode}`
                ),
                null,
                {
                    category: 'twitch-linking',
                    functionName: 'Twitch.renderError()',
                }
            )
        } catch (e) {
            console.log(e)
        }

        return (
            <>
                <AlertMessageV2
                    icon={<Icon fixedWidth regular name="circle-xmark" />}
                    error
                >
                    {errorMessage}
                </AlertMessageV2>

                {showLink && (
                    <div style={{ marginTop: '30px' }}>
                        {renderLink(translate)}
                    </div>
                )}
            </>
        )
    }

    function renderGenericError(translate: TranslateFunction): JSX.Element {
        const errorMessage =
            translate('TwitchLinking.AccountLinkingGenericError').toString() +
            (errorCode ? ` (${errorCode})` : '') // Append error code to error message if error code exists

        return (
            <AlertMessageV2
                icon={<Icon fixedWidth regular name="circle-xmark" />}
                error
            >
                {errorMessage}
            </AlertMessageV2>
        )
    }

    function renderLinkingDisplay(
        translate: TranslateFunction,
        linkingStatus: LinkingStateTypeEnum
    ): JSX.Element {
        switch (linkingStatus) {
            case LinkingStateTypeEnum.Start:
                return renderLink(translate)
            case LinkingStateTypeEnum.Loading:
                return renderLoading(translate)
            case LinkingStateTypeEnum.AlreadyLinked:
                return renderAlreadyLinked(translate)
            case LinkingStateTypeEnum.Linking:
                return renderLinking(translate)
            case LinkingStateTypeEnum.Linked:
                return renderLinked(translate)
            case LinkingStateTypeEnum.Unlinking:
                return renderUnlinking(translate)
            case LinkingStateTypeEnum.Error:
                return renderError(translate)
            case LinkingStateTypeEnum.GenericError:
                return renderGenericError(translate)
            default:
                return renderLink(translate)
        }
    }

    return (
        <Translate>
            {({ translate }) => (
                <TwitchPage
                    display={renderLinkingDisplay(translate, displayState)}
                />
            )}
        </Translate>
    )
}

export default Twitch
