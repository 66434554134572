import { isClient } from 'config/web'

const SESSION_FULL_NAME = 'PayletterVerificationFullName'
const SESSION_TID = 'PayletterVerificationTid'
const SESSION_AUTHINFO = 'PayletterVerificationAuthInfo'
const SESSION_EMAILADDRESS = 'PayletterVerificationEmail'

export default class VerificationManager {
    storage: Storage

    sessionFullName: string

    sessionEmail: string

    sessionTid: string

    sessionauthInfo: string

    constructor(storage?: Storage) {
        if (isClient) {
            this.sessionFullName = SESSION_FULL_NAME
            this.sessionTid = SESSION_TID
            this.sessionauthInfo = SESSION_AUTHINFO
            this.sessionEmail = SESSION_EMAILADDRESS

            if (storage) this.storage = storage
            else {
                this.storage = sessionStorage // Default set sessionStorage, can be overwritten, e.g. localStorage or from unit test
            }
        }
    }

    setSessionStorage(key: string, value: string): void {
        this.storage?.setItem(key, value)
    }

    getSessionStorage(key: string): string {
        return this.storage?.getItem(key)
    }

    removeSessionStorage(key: string): void {
        this.storage?.removeItem(key)
    }
}
