import { useQuery, UseQueryResult } from 'react-query'
import { CharacterSheet } from 'types/account/Character'
import Logger from 'utils/logging'
import client from '../client'


export default async function getCharacterSheet(characterId: number): Promise<CharacterSheet> {
    // Only call the service if token is set, else just return false

    const characterSheet = await client({
        url: `/v5/characters/${characterId}`,
        method: 'get',
    })
        .then((result) => {
            return result && result.data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'character/characterSheet',
                'esi.character.characterSheet'
            )
            return null
        })

    return characterSheet
}

export const useCharacterSheet = (characterId: number): UseQueryResult<CharacterSheet> => {
    return useQuery({
        cacheTime: 3600000,
        keepPreviousData: true,
        staleTime: Infinity,
        refetchInterval: 3600000,
        retry: true,
        retryDelay: 5000,
        queryKey: ["characterSheet", characterId],
        queryFn: () => getCharacterSheet(characterId),
    })
}