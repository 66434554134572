import Logger from 'utils/logging'
import client from '../client'

interface TwitchStatusResponseModel {
    isLinked: boolean
    errorMessage: string | null
    hasError: boolean
    twitchId: string
    twitchUsername: string
}

export async function getTwitchLinkStatus(
    token: string
): Promise<TwitchStatusResponseModel> {
    if (!token) {
        throw new Error('Invalid token')
    }

    try {
        const response = await client({
            url: '/twitch/status',
            headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
            },
            method: 'get',
        })

        return response.data
    } catch (error) {
        Logger.captureNetworkException(
            error,
            'twitch/linkStatus',
            'api.twitch.linkStatus'
        )
        return null
    }
}
