import NewsType from 'models/types/ts/newsType'
import { useQuery } from '@apollo/client'
import { isServer } from 'config/web'

import { ARTICLE_RICHTEXT_BY_SLUG_QUERY } from 'queries/article-by-slug/query'
import { NewsHooksModel } from './NewsHookModel'

export function useContentfulNewsItem(
    slug: string,
    locale: string,
    preview: boolean
): NewsHooksModel {
    const query = ARTICLE_RICHTEXT_BY_SLUG_QUERY
    const fetchPolicy = isServer ? 'cache-and-network' : 'cache-first'
    const { loading, error, data } = useQuery(query, {
        variables: { slug, locale, preview },
        fetchPolicy,
        errorPolicy: 'ignore',
        ssr: true,
    })

    let article: NewsType
    if (data && data.articleCollection && data.articleCollection.items[0]) {
        // ;[dataToRender] = data?.articleCollection.items // This does the same as below except fulfills destructuring rule. Still not sure this is more readable :(
        article = data.articleCollection.items[0]
    }

    return [loading, !!error, article] // !!error will convert error to boolean
}

export default useContentfulNewsItem
