import { Middleware } from 'redux'
import Sentry from 'config/sentry'

// TODO, don't think we need this and it might make the Sentry logging worse.
const createLoggingMiddleware: Middleware = (store) => (next) => (action) => {
    // Adds the action type to breadcrumb if an error is caught later.
    Sentry.addBreadcrumb({
        message: action.type,
        category: 'redux.action',
        type: 'navigation',
    })

    return next(action)
}

export default createLoggingMiddleware
