import {
    css,
    FlattenSimpleInterpolation,
    SimpleInterpolation,
} from 'styled-components'

// These sizes are defined in Chrome DevTools
// const size = {
//     mobileS: '320px',
//     mobileM: '375px',
//     mobileL: '425px',
//     tablet: '768px',
//     laptop: '1024px',
//     laptopL: '1440px',
//     desktop: '2560px',
// }

// Sizes we have been using
// (These sizes and names are 5 years old)
const size = {
    xsmall: 480, // mobile x-small
    small: 768, // mobile
    medium: 992, // tablet
    large: 1200, // desktop
    xlarge: 1800, // desktop x-large
}

// Keeping sized the same as we use in mixins.scss but can change here to reflect what is more common to use
const sizes = {
    mobileSmall: size.xsmall, // 480px
    mobile: size.small, // 768px
    tablet: size.medium, // 992px
    desktop: size.large, // 1200px
    desktopLarge: size.xlarge, // 1800px
}

// ------------------- Mobile Small --------------------
/**
 * 480px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (768px) and down
 */
const mobileSmallDown = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (max-width: ${`${sizes.mobileSmall}px`}) {
        ${content};
    }
`

/**
 * 480px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (768px) and down
 */
const mobileSmallUp = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (min-width: ${`${sizes.mobileSmall}px`}) {
        ${content};
    }
`
// -----------------------------------------------
// ------------------- Mobile --------------------
/**
 * 768px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (768px) and down
 */
const mobileDown = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (max-width: ${`${sizes.mobile}px`}) {
        ${content};
    }
`

/**
 * 768px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (768px) and down
 */
const mobileUp = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (min-width: ${`${sizes.mobile}px`}) {
        ${content};
    }
`

/**
 * Mobile media queries - 480px
 */
export const mobileSmall = {
    down: mobileSmallDown,
    up: mobileSmallUp,
}

/**
 * Mobile media queries - 768px
 */
export const mobile = {
    down: mobileDown,
    up: mobileUp,
}
// -----------------------------------------------
// ------------------- Tablet --------------------
/**
 * 992px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (992px) and down
 */
const tabletDown = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (max-width: ${`${sizes.tablet}px`}) {
        ${content};
    }
`

/**
 * 992px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (992px) and up
 */
const tabletUp = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (min-width: ${`${sizes.tablet}px`}) {
        ${content};
    }
`

/**
 * Tablet media queries - 992px
 */
export const tablet = {
    down: tabletDown,
    up: tabletUp,
}
// -----------------------------------------------
// ------------------- Desktop --------------------
/**
 * 1200px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for desktop (1200px) and down
 */
const desktopDown = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (max-width: ${`${sizes.desktop}px`}) {
        ${content};
    }
`

/**
 * 1200px
 * @param content Styles to apply inside media query
 * @returns Content wrapped in media query for tablet (1200px) and up
 */
const desktopUp = (
    content: SimpleInterpolation
): FlattenSimpleInterpolation => css`
    @media (min-width: ${`${size.large}px`}) {
        ${content};
    }
`

/**
 * Desktop media queries - 1200
 */
export const desktop = {
    down: desktopDown,
    up: desktopUp,
}
// -----------------------------------------------
// ------------------- Desktop Large --------------------
// Add when needed
// -----------------------------------------------

/**
 * Helper for media queries
 * @info desktop - xlarge - 1800px
 * @info desktop - large - 1200px
 * @info tablet - medium - 992px
 * @info mobile - small - 768px
 * @info mobile - xsmall - 480px
 */
export const device = {
    desktop,
    tablet,
    mobile,
}
