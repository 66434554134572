import React from 'react'
import s from './BlockQuote.module.scss'

interface BlockQuoteProps {
    children: React.ReactNode
}

const BlockQuote = ({ children }: BlockQuoteProps): JSX.Element => (
    <blockquote className={s.blockQuote}>{children}</blockquote>
)

export default BlockQuote
