import React from 'react'
import { isMobile } from 'react-device-detect'
import ReactTooltip from 'react-tooltip'
import { NewsEntryType } from '../../types'
import s from './NewsEmbedInline.module.scss'

interface Props {
    content: NewsEntryType
    children?: React.ReactNode
}

const NewsEmbedInline = ({ content, children }: Props): JSX.Element => {
    const link = `<a href="#">
            <img src="${content.metaImageUrl?.url}?w=320" alt="${content.metaImageUrl?.description}" loading="lazy" />
            <span>
                <h3>${content.title}</h3>
                <p>${content.metaDescription}</p>
            </span>
        </a>`

    return (
        <span className={s.embedInline}>
            <a
                href={`/news/view/${content.slug}`}
                data-tip={link}
                data-for={content.slug}
                data-html
                data-background-color="#29353a"
                data-border={false}
                data-effect="solid"
                data-type="dark"
            >
                {/* This nbsp is hacky, done like this to be able to render children or text with extra space */}
                {children ? <>{children}</> : <>{content.title}</>}
            </a>
            {!isMobile && (
                <ReactTooltip
                    className={s.tooltip}
                    wrapper="span"
                    id={content.slug}
                />
            )}
        </span>
    )
}

export default NewsEmbedInline
