const mailcheckDomains = {
    defaultDomains: [
        't-online.de',
        'freenet.de',
        'naver.com',
        'seznam.cz',
        'list.ru',
    ],
    defaultSecondLevelDomains: ['yandex', 'protonmail', 'orange'],
    defaultTopLevelDomains: ['is', 'pl', 'bg'],
}

export default mailcheckDomains
