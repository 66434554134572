type Characters =
    | 'aura'
    | 'explorer'
    | 'industrialist'
    | 'enforcer'
    | 'soldierOfFortune'

type CharVideos = {
    [key in Characters]: {
        webm: string
        mp4: string
    }
}

const characterVideos: CharVideos = {
    aura: {
        webm:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-aura.webm',
        mp4:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-aura.mp4',
    },
    explorer: {
        webm:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-explorer.webm',
        mp4:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-explorer.mp4',
    },
    industrialist: {
        webm:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-industrialist.webm',
        mp4:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-industrialist.mp4',
    },
    enforcer: {
        webm:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-enforcer.webm',
        mp4:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-enforcer.mp4',
    },
    soldierOfFortune: {
        webm:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-soldier-of-fortune.webm',
        mp4:
            'https://web.ccpgamescdn.com/aws/eveonline/videos/careers/careers-soldier-of-fortune.mp4',
    },
}

export default characterVideos
