import React from 'react'
import cx from 'classnames'
import ReactMarkdown from 'utils/markdown'
import { CSSProperties } from 'styled-components'
import style from './headings.module.scss'

interface HeadingsProps {
    title: string | React.ReactNode
    subTitle?: string
    color?: string
    id?: string
    isMarkdownSubHeading?: boolean
    className?: string
    displayHeadingsLine?: boolean
}

const HeadingUnderline2 = ({
    title,
    id,
    subTitle,
    color = '#BA1F7E',
    isMarkdownSubHeading,
    className,
    displayHeadingsLine = true,
}: HeadingsProps): JSX.Element => {
    return (
        <div className={cx(style.heading, className)} id={id}>
            <div className={cx(style.inner, displayHeadingsLine ? '' : style.noPadding, style.beforePadding)}>
                
                <h3 style={{ '--headingHoverColor': color } as CSSProperties}>{title}</h3>
                {subTitle && (
                    <h4>
                        {isMarkdownSubHeading ? (
                            <ReactMarkdown source={subTitle} />
                        ) : (
                            subTitle
                        )}
                    </h4>
                )}
                {displayHeadingsLine && <div
                    className={style.line}
                    style={{ backgroundColor: color }}
                />}
            </div>
        </div>
    )
}

HeadingUnderline2.defaultProps = {
    subTitle: undefined,
    color: '#BA1F7E',
    isMarkdownSubHeading: false,
    className: '',
    id: undefined,
}

export default HeadingUnderline2
