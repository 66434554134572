import React from 'react'
import { useSelector } from 'react-redux'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import { findSectionByIdentifier } from 'lib/pages/api'
import { useHasMounted } from 'hooks/client-only'
import { GlobalState } from 'types'
import { PageType } from 'models/types/ts'

const LoadableComponent = React.lazy(
    () => import(/* webpackChunkName: "page-omega" */ './Omega')
)

// CODE_IMPROVEMENTS
// TODO convert to .tsx if used

export default function LoadableOmega(): JSX.Element {
    const slug = 'omega2'
    const language = useSelector((state: GlobalState) => state.language)

    const hasMounted = useHasMounted()

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                let hasContent = false
                if (page) {
                    hasContent = true

                    const sections = page.sectionsCollection.items

                    const Hero = findSectionByIdentifier(sections, 'omega-hero')
                    const CTA = findSectionByIdentifier(sections, 'omega-cta')
                    const Video = findSectionByIdentifier(
                        sections,
                        'omega-video-section'
                    )
                    const Advantage = findSectionByIdentifier(
                        sections,
                        'omega-advantage'
                    )
                    const Comparison = findSectionByIdentifier(
                        sections,
                        'alpha-omega-comparison'
                    )
                    const MediaQuotes = findSectionByIdentifier(
                        sections,
                        'media-quotes'
                    )
                    const Quotes = findSectionByIdentifier(sections, 'quotes')

                    return (
                        <PageWithSeo
                            page={page}
                            showLoading={false}
                            hideSitename
                        >
                            {hasMounted && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableComponent
                                        hero={Hero}
                                        cta={CTA}
                                        video={Video}
                                        advantage={Advantage}
                                        comparison={Comparison}
                                        mediaquotes={MediaQuotes}
                                        quotes={Quotes}
                                        hasContent={hasContent}
                                    />
                                </React.Suspense>
                            )}
                        </PageWithSeo>
                    )
                }

                // Fallback error message
                return <h1>Not Found</h1>
            }}
        </PageBySlugQuery>
    )
}
