enum SmallIconEnum {
    GallenteIcon = 'GallenteIcon',
    MinmatarIcon = 'MinmatarIcon',
    CaldariIcon = 'CaldariIcon',
    AmarrIcon = 'AmarrIcon',
    OreIcon = 'OreIcon',
    CapsuleIcon = 'CapsuleIcon',
    CorvetteIcon = 'CorvetteIcon',
    ShuttleIcon = 'ShuttleIcon',
    FrigateIcon = 'FrigateIcon',
    DestroyerIcon = 'DestroyerIcon',
    CruiserIcon = 'CruiserIcon',
    BattlecruiserIcon = 'BattlecruiserIcon',
    BattleshipIcon = 'BattleshipIcon',
    CarrierIcon = 'CarrierIcon',
    DreadnoughtIcon = 'DreadnoughtIcon',
    TitanIcon = 'TitanIcon',
    MiningFrigateIcon = 'MiningFrigateIcon',
    MiningBargeIcon = 'MiningBargeIcon',
    IndustrialIcon = 'IndustrialIcon',
    CapitalIndustrialIcon = 'CapitalIndustrialIcon',
    FreighterIcon = 'FreighterIcon',
    ExplorerIcon = 'ExplorerIcon',
    IndustrialistIcon = 'IndustrialistIcon',
    EnforcerIcon = 'EnforcerIcon',
    SoldierOfFortuneIcon = 'SoldierOfFortuneIcon',
}

export default SmallIconEnum
