import React from 'react'
import ContentType from 'models/types/ts/contentType'
import getInternalLinkPath from 'lib/link'
import { Link } from 'features'
// import LinkArrow from 'layouts/link-arrow'
import Icons from 'pages/academy/components/icons'
import IconColorEnum from 'pages/academy/models/icon-color-enum'
import IconTypeEnum from 'pages/academy/models/icon-type-enum'
import { Item, Details } from './CareerLink.styles'

interface Props {
    content: ContentType
    index: number
    itemCount: number // number of items total, to know when last item is being rendered
    active: boolean
    setHoveredIndex: (index: number) => void
}

interface JsonData {
    icon?: string
    learnMore?: boolean
}

export const CareerLink = ({
    content,
    index,
    active,
    setHoveredIndex,
}: Props): JSX.Element => {
    const jsonData = content?.data as JsonData
    const isLearnMoreLink = jsonData.learnMore

    return (
        <Link
            path={getInternalLinkPath(content.buttonUrl)}
            onMouseEnter={
                !isLearnMoreLink ? () => setHoveredIndex(index) : null
            }
            onMouseLeave={!isLearnMoreLink ? () => setHoveredIndex(0) : null}
            onFocus={!isLearnMoreLink ? () => setHoveredIndex(index) : null}
            onBlur={!isLearnMoreLink ? () => setHoveredIndex(0) : null}
            tabIndex={0}
        >
            <Item learnMore={isLearnMoreLink} active={active}>
                <h3>{content.headline}</h3>
                {jsonData && (
                    <Icons
                        icon={IconTypeEnum[jsonData.icon]}
                        color={IconColorEnum.PinkColor}
                    />
                )}
                {content.body && (
                    <Details active={active}>{content.body}</Details>
                )}
            </Item>
        </Link>
    )
}
