import React from 'react'
import SectionById from 'queries/section-by-id'
import cx from 'classnames'
import { Target } from '@ccpgames/eve-web/components/target/Target'
import { useSelector } from 'react-redux'
import { getLanguage } from 'lib/language/selectors'
import SectionType from 'models/types/ts/sectionType'
// import PriceTag from './PriceTag'
import { GlobalState } from 'types'
import style from './Sale.module.scss'

interface Props {
    showOnlyOnMobile?: boolean
    visible?: boolean
}

const Sale = ({ showOnlyOnMobile = false, visible }: Props): JSX.Element => {
    const language = useSelector((state: GlobalState) => getLanguage(state))

    return (
        <SectionById
            identifier="www-sale-cta-development-only-for-now"
            locale={language}
        >
            {(saleSection: SectionType) => {
                if (!saleSection || !saleSection.buttonUrl) {
                    return <></>
                }
                if (saleSection) {
                    const {
                        headline,
                        teaser,
                        // body,
                        buttonText,
                        buttonUrl,
                        imageFile,
                        // theme,
                    } = saleSection
                    // const css = validateJSON(headline)
                    //     ? JSON.parse(headline)
                    //     : null
                    if (!buttonText) {
                        return <></>
                    }
                    return (
                        <div
                            className={cx(style.saleWrapper, {
                                [style.onlyMobile]: showOnlyOnMobile,
                                [style.hide]: !visible,
                            })}
                        >
                            {/* {visible ? <p>visible</p> : <p>not visible</p>} */}
                            <Target color="#5ccbcb">
                                <a
                                    href={buttonUrl}
                                    data-id="eveNav_sale"
                                    className={style.inner}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className={style.content}>
                                        <div className={style.copy}>
                                            <div className={style.headline}>
                                                {headline}
                                            </div>
                                            <div className={style.teaser}>
                                                {teaser}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style.img}>
                                        {imageFile && imageFile.url && (
                                            <img
                                                src={`${imageFile.url}?w=120&h=60&fit=fill&q=90&fm=jpg`}
                                                alt=""
                                                width={120}
                                                height={60}
                                            />
                                        )}
                                    </div>
                                </a>
                            </Target>
                        </div>
                    )
                }
            }}
        </SectionById>
    )
}

Sale.defaultProps = {
    showOnlyOnMobile: false,
    visible: true,
}

export default Sale
