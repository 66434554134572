import React, { memo } from 'react'
import SocialIcon, { SocialIconType } from '../social-icon'
import styles from './styles.module.scss'

interface Props {
    ids?: SocialIconType[]
    className?: string
}

const SocialIcons = memo(
    ({
        ids = [
            'facebook',
            'twitter',
            'youtube',
            'twitch',
            'discord',
            'instagram',
            'vk',
        ],
        className,
    }: Props): JSX.Element => {
        const icons = ids.map((id) => <SocialIcon key={id} id={id} />)
        const cName = `${styles.social}${className ? ` ${className}` : ''}`
        return <div className={cName}>{icons}</div>
    }
)

export default SocialIcons
