import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getLanguage } from 'selectors'
import { GlobalState } from 'types'
import NavigationItemType from '../../models/navigation-item-type'
import NavItem from './components/navitem'

interface Props {
    className: string
    page: string
    subpage: string
    items: Array<NavigationItemType>
    fpThemeColor: boolean
    isMobile?: boolean
}

const Navigation = ({
    className,
    page,
    subpage,
    items,
    fpThemeColor,
    isMobile,
}: Props): JSX.Element => {
    const [active, setActive] = useState<number>(undefined)
    const language = useSelector((state: GlobalState) => getLanguage(state))

    const handleClick = (idx: number): void => {
        if (isMobile) {
            setActive(idx)
        }
    }

    useEffect(() => {
        if (active) {
            handleClick(undefined)
        }
    }, [page, language])

    return (
        <nav className={className}>
            {items.map((item, i) => {
                if (item.disabled) {
                    return null
                }

                const props: NavigationItemType = {
                    key: item.dataId,
                    translationId: item.translationId,
                    dataId: item.dataId,
                    fpThemeColor,
                }

                if (item.href) {
                    props.href = item.href
                    props.external = true
                    // Can overwrite external links with LOC link if they are set.
                    // Then the string {language} in the loc link will be replaces with the selected language on page
                    if (language !== 'en' && item.locHref) {
                        // extra check just for japan for now. can be removed when updates is sunsetted.
                        if (
                            !(
                                language === 'ja' &&
                                item.locHref.includes('updates.eveonline.com')
                            )
                        ) {
                            props.href = item.locHref.replace(
                                '{language}',
                                language
                            )
                        }
                    }
                } else {
                    props.page = item.page
                    props.subpage = subpage

                    if (item.sub) {
                        props.sub = item.sub
                    }

                    if (item.selectedValues && item.selectedValues.length > 0) {
                        props.selected = item.selectedValues.includes(page)

                        if (subpage && item.sub) {
                            props.selected = item.selectedValues.includes(
                                subpage
                            )
                        }
                    }
                }

                if (item.children) {
                    props.children = item.children
                }

                return (
                    <NavItem
                        {...props}
                        isMobile={isMobile}
                        index={i}
                        setActiveItem={isMobile ? handleClick : null}
                        active={active === i}
                    />
                )
            })}
        </nav>
    )
}

Navigation.defaultProps = {
    isMobile: false,
}

export default Navigation
