import React from 'react'
import ContentType from 'models/types/ts/contentType'
import flags from 'config/flags'
import { Target } from '@ccpgames/eve-web/components/target/Target'
import { Button } from '@ccpgames/eve-web/components/button/Button'
import { Icon } from 'layouts'
import style from '../../Hero.module.scss'

interface Props {
    content: Array<ContentType>
}

const CTA = ({ content }: Props): JSX.Element => {
    const playFree = content && content[0]
    const playPremium = content && content[1]
    const { colorThemeEnabled } = flags.features

    const targetColor = '#5ccbcb'
    const buttonBackgroundColor = '#5ccbcb'
    const buttonTextColor = '#111418'

    return (
        <div
            className={style(style.buttons, {
                [style.colorTheme]: colorThemeEnabled,
            })}
        >
            {playFree && (
                <>
                    <Target color={targetColor}>
                        <Button
                            href={playFree.buttonUrl}
                            className={style.btn}
                            backgroundColor={buttonBackgroundColor}
                            style={{ color: buttonTextColor, height: '60px' }}
                        >
                            {playFree.buttonText}
                            <div style={{ marginLeft: '15px' }}>
                                <Icon brand name="windows" />
                            </div>
                            <div style={{ marginLeft: '10px' }}>
                                <Icon brand name="apple" />
                            </div>
                        </Button>
                    </Target>
                </>
            )}
            {playPremium && (
                <Target color="#fff">
                    <Button
                        href={playPremium.buttonUrl}
                        backgroundColor="#fff"
                        className={style.btn}
                        style={{ color: 'black', height: '60px' }}
                    >
                        {playPremium.buttonText}
                    </Button>
                </Target>
            )}
        </div>
    )
}

export default CTA
