import { useHasMounted } from 'hooks/client-only'
import React from 'react'

const LoadableComponent = React.lazy(
    () =>
        import(
            /* webpackChunkName: "page-retro-recruit" */ './RetroactiveRecruitment'
        )
)

export default function LoadableRetroRecruit(): JSX.Element {
    const hasMounted = useHasMounted()
    return (
        <>
            {hasMounted && (
                <React.Suspense fallback={<></>}>
                    <LoadableComponent />
                </React.Suspense>
            )}
        </>
    )
}

// export { default } from './RetroactiveRecruitment'
