import React, { useState } from 'react'

interface Props {
    children: React.ReactNode
    delegated?: unknown
}

export default function ClientOnly({
    children,
    ...delegated
}: Props): JSX.Element {
    const [hasMounted, setHasMounted] = useState(false)

    React.useEffect(() => {
        setHasMounted(true)
    }, [])

    if (!hasMounted) {
        return null
    }

    return <div {...delegated}>{children}</div>
}

/**
 * Hook to determine whether the component has mounted.
 *
 * @returns `true` if the component has mounted, otherwise `false`.
 */
export function useHasMounted(): boolean {
    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    return hasMounted
}
