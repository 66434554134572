import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
// import { getRichTextEntityLinks } from '@contentful/rich-text-links'
import RichTextType from 'models/types/ts/richTextType'
import getRenderOptions from './getRenderOptions'
import s from './RichText.module.scss'

interface Props {
    document: RichTextType
}

export type ImageInListType = {
    index: number
    sysId: string
}

const RichText = ({ document }: Props): JSX.Element => {
    // This is the main content
    const richTextDocument = document.json
    // This is how options are rendered
    const options = getRenderOptions(document)

    // TODO, can we use this in a good way?
    // const entityLinks = getRichTextEntityLinks(document.json)
    // console.log('entityLinks: ', entityLinks)

    return (
        <div className={s.richtext}>
            {documentToReactComponents(richTextDocument, options)}
            {/* Tooltip rendered here to avoid div inside <p> error */}
        </div>
    )
}

export default RichText
