import _isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { getLanguage } from 'selectors'
import { GlobalState } from 'types'
import { Dispatch } from 'redux';
import LanguageType from 'models/language-type'
import DownloadButtonNew from './components/DownloadButtonNew'

interface ReturnObject {
  language: LanguageType;
}

function mapStateToProps(props: GlobalState): ReturnObject {
  return {
    language: getLanguage(props),
  };
}

const mapDispatchToProps = (dispatch: Dispatch): ReturnType<typeof mapDispatchToProps> => ({
    dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButtonNew)
