enum IconColorEnum {
    PinkColor = '#ba1f7e',
    WhiteColor = '#ffffff',
    GrayColor = '#c0c0c0',
    BlueColor = '#30B2E6',
    YellowColor = '#FAB400',
    RedColor = '#DF1A23'
}

export default IconColorEnum
