import styled, { css } from 'styled-components'
import { desktop, tablet } from 'styles/media-queries'

interface LeaderboardStyleProps {
    children?: React.ReactNode
    color?: string
}

const LeaderboardsStyles = styled.div<LeaderboardStyleProps>`
    .leaderboards {
        /* outline: 1px solid red; */
        .lists {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 30px;

            .personal {
                grid-column: 1 / 2;
                grid-row: 1;
            }

            ${tablet.up(css`
                grid-template-columns: 1fr 1fr;
                .personal {
                    grid-column: 1 / 3;
                    grid-row: 1;
                }
            `)};

            ${desktop.up(css`
                grid-template-columns: 1fr 1fr 1fr;
                .personal {
                    grid-column: 3;
                    grid-row: inherit;
                }
            `)};

            section {
                h4 {
                    border-left: 1px solid var(--faction-color, #fff);
                    padding-left: 18px;
                    text-transform: uppercase;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 0.8;
                    margin: 20px 0 50px 0;

                    span {
                        font-size: 18px;
                        color: #8b8b8b;
                        display: block;
                        padding-top: 8px;
                        text-transform: initial;
                    }
            }
        }
    }

    .loginPanel {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .loginBlur {
            width: 100%;
            filter: blur(6px) brightness(0.7);
            > * {
                cursor: default;
                user-select: none;
            }
        }

        .loginBtnContainer {
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            p {
                font-size: 18px;
            }
        }
    }

    .loading {
        display: flex;
        min-height: 400px;
        position: relative;

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .characterPanel {
        margin-bottom: 20px;

        @media (min-width: 1300px) {
            min-height: 150px;
        }

        /* min-height: 150px; */

        &.blur {
            img {
                height: 100px;
                @media (min-width: 1300px) {
                    height: 150px;
                }
            }
        }
    }
`

export default LeaderboardsStyles
