import { getPathname } from 'lib/location/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import ErrorBoundary from './ErrorBoundary'

interface Props {
    children: React.ReactNode
}

export default function ErrorBoundaryOuter({ children }: Props): JSX.Element {
    const [hasError, setHasError] = useState(false)
    const pathname = useSelector((state: GlobalState) => getPathname(state))
    useEffect(() => {
        if (hasError) {
            setHasError(false)
        }
    }, [pathname])
    return (
        <ErrorBoundary hasError={hasError} setHasError={setHasError}>
            {children}
        </ErrorBoundary>
    )
}
