import React, { FC } from 'react'
import NewsType from 'models/types/ts/newsType'
import { HeadingXSmall, ParagraphRegular } from 'layouts/typography'
import { DateAndAuthor, ImageLazyLoad } from 'features'
import Tags from '../tags'
import NewsLink from '../../shared/link'
import style from './Card.module.scss'

interface Props {
    newsItem: NewsType
    filled?: boolean
    hover?: boolean
    showDescription?: boolean
    size?: string
    path?: string
    className?: string
}

const Card: FC<Props> = ({
    newsItem,
    filled = false,
    hover = false,
    showDescription = false,
    path = null,
    className,
}) => {
    const renderDescription = hover || showDescription

    return (
        <article
            className={style(style.card, className, {
                [style.filled]: filled,
                [style.hover]: hover,
            })}
        >
            <Tags tagList={newsItem.tags} />
            {newsItem.metaImageUrl && newsItem.metaImageUrl.url && (
                <NewsLink slug={newsItem.slug} path={path}>
                    <div className={style.imgWrapper}>
                        <ImageLazyLoad
                            className={style.img}
                            image={newsItem.metaImageUrl}
                            param="?w=640&fl=progressive"
                            lazyloadProps={{
                                height: 350,
                                offset: 300,
                                once: true,
                            }}
                        />
                    </div>
                </NewsLink>
            )}
            <div className={style.content}>
                <DateAndAuthor
                    date={newsItem.publishingDate}
                    author={newsItem.author}
                />
                <HeadingXSmall
                    fontSize={[20, 26]}
                    textTransform="unset"
                    className={style.title}
                >
                    <NewsLink slug={newsItem.slug} path={path}>
                        {newsItem.title}
                    </NewsLink>
                </HeadingXSmall>
                {renderDescription && (
                    <ParagraphRegular
                        fontSize={[18, 20]}
                        className={style.desc}
                    >
                        {newsItem.metaDescription}
                    </ParagraphRegular>
                )}
            </div>
        </article>
    )
}

export default Card
