import React from 'react'
import { CSSTransition } from 'react-transition-group'
import style from './ErrorMsg.module.scss'

interface ErrorMsgProps {
    message?: string
    show?: boolean
}

const ErrorMsg: React.FC<ErrorMsgProps> = ({ message, show = false }) => {
    return (
        <CSSTransition
            in={show}
            timeout={1000}
            classNames={{
                enter: style.msgEnter,
                enterActive: style.msgEnterActive,
                enterDone: style.msgEnterDone,
                exit: style.msgExit,
                exitActive: style.msgExitActive,
            }}
            unmountOnExit
        >
            <div className={style.invalidFeedback}>
                <div>{message}</div>
            </div>
        </CSSTransition>
    )
}

export default ErrorMsg
