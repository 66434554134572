import React from 'react'
import { PulseLoader } from 'react-spinners'
import classNames from 'classnames'
import { scroller } from 'react-scroll'
import style from './Section.module.scss'

interface SectionProps {
    children?: React.ReactNode
    hasContent?: boolean
    loadingTypeSmall?: boolean
    spinnerSize?: number
    className?: string
    id?: string;
    sectionStyle?: React.CSSProperties;
}

const Section = React.forwardRef<HTMLElement, React.PropsWithChildren<SectionProps>>(({
    children,
    hasContent = true,
    loadingTypeSmall,
    spinnerSize = 20,
    className,
    id = undefined,
    sectionStyle
}, ref) => {
    React.useEffect(()=> {
        const hash = window.location.hash
        if (hash === `#${id}`) {
            scroller.scrollTo(id, {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -50,
            })
        }
    }, [])


    const sectionClass = classNames(
        style({
            sectionLoadingSmall: loadingTypeSmall,
            sectionLoading: !loadingTypeSmall,
        })
    )

    return (
        <>
            {hasContent ? (
                <section  ref={ref} id={id}  className={style(style.section, className)} style={sectionStyle}>
                    {children}
                </section>
            ) : (
                <section  ref={ref} id={id}  className={sectionClass} style={sectionStyle}>
                    <div className={style.spinner}>
                        <PulseLoader
                            size={spinnerSize}
                            color="#b8b8b8"
                            loading
                        />
                    </div>
                </section>
            )}
        </>
    )
})

export default Section