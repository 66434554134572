import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import { GlobalState } from 'types/redux'
import { Translate } from 'react-localize-redux'
import { isLoggedIn } from 'packages/authentication'
import { getPathname } from 'lib/location/selectors'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import { PageType } from 'models/types/ts'
import SectionType from 'models/types/ts/sectionType'

import { Container, Section, Icon } from 'layouts'
import { HeadingMedium, TaglineRegular } from 'layouts/typography'
import { ButtonWithTarget } from 'layouts/button-with-target'

import PageWithSeo from 'features/page-with-seo'
import BackgroundImage from 'features/background-image'
import Picture from 'features/picture'

import Rewards from '../rewards'

import s from './twitch.module.scss'

interface TwitchPageProps {
    display: JSX.Element
}

const TwitchPage = ({ display }: TwitchPageProps): JSX.Element => {
    const slug = 'twitch-linking-page'

    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))

    const language = useSelector((state: GlobalState) => state.language)

    const pathname = useSelector((state: GlobalState) => getPathname(state))
    const loginUrl = `/login?path=${pathname}`

    const dispatch = useDispatch()

    function renderSections(sections: SectionType[]): JSX.Element[] {
        // loop through sections and render Rewards component
        return sections.map((section: SectionType, index) => {
            return <Rewards key={section.headline} section={section} />
        })
    }

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                if (page) {
                    // Render all sections of the page the same way
                    const sections = page?.sectionsCollection?.items

                    const fallbackBackground =
                        'https://images.ctfassets.net/7lhcm73ukv5p/2gCNmVCh4xuxQApftg46jE/4787b41fa6ff9bd3a7535ae87b504323/fanfest-hero-22.jpg'
                    const getPageBackground = (): string =>
                        page?.pageBackground
                            ? page.pageBackground.url
                            : fallbackBackground

                    return (
                        <PageWithSeo
                            page={page}
                            showLoading={false}
                            hideSitename={false}
                        >
                            <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                                <div className={s.twitch}>
                                    <div className={s.heroBg}>
                                        <Picture
                                            src={getPageBackground()}
                                            alt=""
                                        />
                                    </div>
                                    <Section>
                                        <Container>
                                            <Translate>
                                                {({ translate }) => (
                                                    <div className={s.hero}>
                                                        <div
                                                            className={s.inner}
                                                        >
                                                            <HeadingMedium
                                                                className={
                                                                    s.heroTitle
                                                                }
                                                            >
                                                                EVE
                                                                <span> x </span>
                                                                twitch
                                                                <Icon
                                                                    brand
                                                                    name="twitch"
                                                                    className={
                                                                        s.twitchIcon
                                                                    }
                                                                />
                                                            </HeadingMedium>
                                                            <TaglineRegular as="h2">
                                                                {page.body}
                                                            </TaglineRegular>
                                                            {!loggedIn ? (
                                                                <ButtonWithTarget
                                                                    url={
                                                                        loginUrl
                                                                    }
                                                                    color="#1A1A1A"
                                                                    backgroundColor="#ffffff"
                                                                >
                                                                    {translate(
                                                                        'navigation.account.login'
                                                                    )}
                                                                </ButtonWithTarget>
                                                            ) : (
                                                                <div
                                                                    className={
                                                                        s.steps
                                                                    }
                                                                >
                                                                    {display}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </Translate>
                                        </Container>
                                    </Section>
                                    <Section>
                                        <Container>
                                            {renderSections(sections)}
                                        </Container>
                                    </Section>
                                </div>
                            </BackgroundImage>
                        </PageWithSeo>
                    )
                }

                // page not found in contentful
                dispatch({ type: NOT_FOUND })
                return <></>
            }}
        </PageBySlugQuery>
    )
}

export default TwitchPage
