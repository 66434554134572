import axios from 'axios'
import getConfig from 'config/web'

const { esiBaseUrl: baseURL } = getConfig()

const instance = axios.create({
    baseURL,
    headers: {
        'X-User-Agent': 'www.eveonline.com/Team404',
        'Accept': 'application/json'
    }
})

export default instance
