import React from 'react'
import SectionById from 'queries/section-by-id'
import SectionType from 'models/types/ts/sectionType'
import { useSelector } from 'react-redux'
import { getLanguage } from 'lib/language/selectors'
import { GlobalState } from 'types'
import TwitchStream from './TwitchStream'

export default function TwitchStreamContainer(): JSX.Element {
    const language = useSelector((state: GlobalState) => getLanguage(state))

    return (
        <SectionById identifier="twitch-stream-widget" locale={language}>
            {(widgetData: SectionType) => {
                if (!widgetData) {
                    return <></>
                }
                if (widgetData) {
                    return <TwitchStream widgetData={widgetData} />
                }
            }}
        </SectionById>
    )
}
