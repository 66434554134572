import styled, { css } from 'styled-components'
import { desktop, tablet } from 'styles/media-queries'

interface MessageStyleProps {
    children?: React.ReactNode
    image: string
    color?: string
}

const MessageStyles = styled.div<MessageStyleProps>`
    .message {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 20px;
        align-items: start;
        justify-content: start;

        ${desktop.down(css`
            grid-template-columns: 1fr;
        `)};

        .image {
            min-height: 430px;
            background-image: url(${(props) => props.image});
            background-size: cover;
            background-position: center;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-end;
            justify-content: center;
            padding-bottom: 50px;
        }

        .text {
            display: flex;
            flex-wrap: wrap;
            align-content: left;
            justify-content: left;
            margin-right: 40px;
            ${tablet.down(css`
                margin-right: 10px;
            `)};
        }

        p {
            margin: 15px 0;
        }

        .description {
            text-transform: uppercase;
        }

        .headline {
            font-weight: 500;
            margin: 0;
        }

        .play {
            color: ${(props) => props.color};
            text-transform: uppercase;
            margin: 6px 0;
        }

        .button {
            color: red;
            margin-top: 30px;
        }

        .video_wrapper {
            margin-top: 0;
            outline: unset;
        }
    }
`

export default MessageStyles
