import React from 'react'
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'react-scroll'
import TableOfContentsStyle from './TableOfContents.styles'

interface Props {
    body: Document
}
const TableOfContents = ({ body }: Props): JSX.Element => {
    // the body is the json object returned by the rich text field
    // const headingTypes = [BLOCKS.HEADING_2, BLOCKS.HEADING_3] // If we want to include heading 3 later, this is the filter for it
    const headingTypes = [BLOCKS.HEADING_2]

    const headings = body.content.filter((item) =>
        headingTypes.includes(item.nodeType)
    )

    const document: Document = {
        nodeType: BLOCKS.DOCUMENT,
        content: headings,
        data: {},
    }

    let headingIndex = 0

    const renderLink = (children: React.ReactNode): JSX.Element => {
        headingIndex++

        const id = `h2-${headingIndex}` // must be string

        return (
            <>
                <Link
                    key={id}
                    href={`#${id}`}
                    to={id}
                    smooth
                    spy
                    hashSpy
                    offset={-110}
                >
                    {children}
                </Link>
            </>
        )
    }

    const options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => {
                return renderLink(children)
            },
            // NOT doing heading 3 in table of contents, if added then also need to account for how heading 3 is rendered with correct Id's
            // [BLOCKS.HEADING_3]: (node, children) => {
            //     return renderLink(children)
            // },
        },
    }

    return (
        <TableOfContentsStyle>
            {documentToReactComponents(document, options)}
        </TableOfContentsStyle>
    )
}
export default TableOfContents
