import getConfig from 'config/web'
import axios from 'axios'
import {
    AgeVerificationVerifyModel,
    AgeVerificationType,
    AgeVerificationRedirectRequestModel,
    AgeVerificationRedirectResponseModel,
} from 'models/age-verification-model'
import Logger from 'utils/logging'

const { signupServiceUrl } = getConfig()

const fallbackResponse: AgeVerificationType = {
    required: false,
    provider: null,
}

// Check if we need to go through the whole age verification process only for Korean users
// Return true if we need to go through the whole process
// and the available verification methods
export const getAgeVerification = async (
    country: string
): Promise<AgeVerificationType | null> => {
    const url = `${signupServiceUrl}/api/v2/verification/required`
    const data = await axios
        .post(url, {
            country,
        })
        .then((res) => (res.status === 200 ? res.data : fallbackResponse))
        .catch((error) => {
            Logger.captureNetworkException(error, url, 'api.getAgeVerification')

            return fallbackResponse
        })

    return data?.required ? data : fallbackResponse
}

const fallbackAgeVerificationRedirectResponse: AgeVerificationRedirectResponseModel = {
    redirect: 'https://payletter.com',
    country: 'kr',
}

// Get the redirect url for the age verification process, link to Payletter
export const getAgeVerificationRedirectUrl = async (
    redirectRequest: AgeVerificationRedirectRequestModel
): Promise<AgeVerificationRedirectResponseModel | null> => {
    const url = `${signupServiceUrl}/api/v2/verification/redirect`

    const data = await axios
        .post(url, {
            country: redirectRequest.country,
            method: redirectRequest.method,
            userId: redirectRequest.userId,
            state: redirectRequest.state,
            isMobileClient: redirectRequest.isMobileClient,
            returnUrl: redirectRequest.returnUrl,
            cancelUrl: redirectRequest.cancelUrl,
            siteUrl: redirectRequest.siteUrl,
            journeyId: redirectRequest.journeyId,
        })
        .then((res) =>
            res.status === 200
                ? res.data
                : fallbackAgeVerificationRedirectResponse
        )
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                url,
                'api.getAgeVerificationRedirect'
            )

            return null
        })

    return data?.redirect ? data : fallbackAgeVerificationRedirectResponse
}

const fallbackAgeVerificationResponse: AgeVerificationVerifyModel = {
    verified: false,
    fullName: '',
    message: '',
}

// Verify the response we get from Payletter
export const getAgeVerificationResponse = async (
    country: string,
    transactionId: string,
    customParameter: string,
    authInfo: string,
    journeyId: string
): Promise<AgeVerificationVerifyModel | null> => {
    const url = `${signupServiceUrl}/api/v2/verification/verify`
    const data = await axios
        .post(url, {
            country,
            transactionId,
            customParameter,
            authInfo,
            journeyId,
        })
        .then((res) =>
            res.status === 200 ? res.data : fallbackAgeVerificationResponse
        )
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                url,
                'api.getAgeVerificationResponse'
            )

            return fallbackAgeVerificationResponse
        })

    return data?.verified ? data : fallbackAgeVerificationResponse
}
