import gql from 'graphql-tag'
import {
    FRAGMENT_ARTICLE_BASIC,
    FRAGMENT_CONTENT_BASIC,
} from '../fragments/fragments'

export const ARTICLE_RICHTEXT_BY_SLUG_QUERY = gql`
    query ArticleCollectionBySlug(
        $slug: String
        $locale: String
        $preview: Boolean
    ) {
        articleCollection(
            limit: 1
            where: { slug: $slug }
            locale: $locale
            preview: $preview
        ) {
            items {
                sys {
                    publishedAt
                    firstPublishedAt
                }
                title
                slug
                tags
                content
                tableOfContentsSidebar
                richText {
                    json
                    links {
                        entries {
                            inline {
                                sys {
                                    id
                                }
                                ...ArticleBasicField
                                ...ContentBasicFields
                                ... on Label {
                                    text
                                    color
                                    backgroundColor
                                }
                            }
                            block {
                                sys {
                                    id
                                }
                                __typename
                                ...ArticleBasicField
                                ... on MediaEmbed {
                                    title
                                    type
                                    thumbnail {
                                        url
                                    }
                                    mediaUrl
                                    loopVideo
                                }
                                ...ContentBasicFields
                                ... on Button {
                                    text
                                    url
                                    color
                                    backgroundColor
                                }
                                ... on ImageGallery {
                                    title
                                    description
                                    columnCount
                                    imagesCollection {
                                        total
                                        items {
                                            sys {
                                                id
                                            }
                                            contentType
                                            description
                                            url
                                        }
                                    }
                                }
                                ... on Tabs {
                                    title
                                    tabsCollection {
                                        total
                                        items {
                                            ... on Tab {
                                                sys {
                                                    id
                                                }
                                                title
                                                content {
                                                    json
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            hyperlink {
                                sys {
                                    id
                                }
                                __typename
                                ...ArticleBasicField
                            }
                        }
                        assets {
                            block {
                                sys {
                                    id
                                }
                                contentType
                                description
                                url
                            }
                            hyperlink {
                                sys {
                                    id
                                }
                                title
                                contentType
                                url
                            }
                        }
                    }
                }
                relatedArticlesCollection(limit: 6) {
                    total
                    items {
                        ...ArticleBasicField
                    }
                }
                category
                author
                publishingDate
                video
                metaDescription
                metaImageUrl {
                    url
                }
            }
        }
    }
    ${FRAGMENT_ARTICLE_BASIC}
    ${FRAGMENT_CONTENT_BASIC}
`
