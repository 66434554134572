import Logger from 'utils/logging'
import client from '../client'

export default async function getHashedUserId(token: string): Promise<string> {
    // Only call the service if token is set, else just return false
    if (!token) return ''

    const hashedUserId = await client({
        url: `/customer/hashedUserId`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        },
        method: 'get',
    })
        .then((result) => {
            return result && result.data && result.data.hashedUserId
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'customer/hashedUserId',
                'api.customer.hashedUserId'
            )
            return ''
        })

    return hashedUserId
}
