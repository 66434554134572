import Sentry from 'config/sentry'

interface NetworkErrorModel {
    response: {
        status: string
    }
    request: unknown
    message: string
}

type TagCategories =
    | 'eve-anywhere'
    | 'network-error'
    | 'webapi'
    | 'proto'
    | 'events'

interface TagsProps {
    category?: TagCategories
    message?: string
    functionName?: string
    functionDetail?: string
    httpStatusCode?: string
    requestUrl?: string
    eventType?: string
}

class Logger {
    static captureException(
        error: Error | string | unknown,
        extra?: { [prop: string]: string },
        tags?: { [prop: string]: string } | TagsProps, // Remove the first part later, and only use TagsProps
        username?: string
    ): void {
        // Setting tags, extra and username

        Sentry.withScope((scope) => {
            if (extra) {
                scope.setExtras(extra)
            }
            if (tags) {
                scope.setTags(tags as Record<string, string>)
            }
            if (username) {
                scope.setUser({ username })
            }
            // Capturing exception with Browser Sentry which will report straight
            // to Sentry if the code is being executed in client.
            Sentry.captureException(error)
        })
    }

    static addBreadcrumb(message: string): void {
        Sentry.addBreadcrumb({
            message,
        })
    }

    static captureMessage(
        message: string,
        extra?: { [prop: string]: string },
        tags?: { [prop: string]: string } | TagsProps,
        username?: string
    ): void {
        Sentry.withScope((scope) => {
            if (extra) {
                scope.setExtras(extra)
            }
            if (tags) {
                scope.setTags(tags as Record<string, string>)
            }
            if (username) {
                scope.setUser({ username })
            }
            Sentry.captureMessage(message)
        })
    }

    static captureNetworkException(
        error: NetworkErrorModel,
        requestUrl: string,
        functionName: string
    ): void {
        const category = 'network-error'

        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            // console.log('data: ', error.response.data)
            // console.log('status: ', error.response.status)
            // console.log('headers:', error.response.headers)

            Logger.captureException(error, null, {
                category,
                functionName,
                functionDetail: 'error.response',
                message: 'Network error caught and got error.respone',
                httpStatusCode: error?.response?.status, // We know status code here.
                requestUrl,
            })
        } else if (error.request) {
            // console.log('ERROR.REQUEST')
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            // console.log('ERROR: ', error)
            // console.log('request: ', error.request)
            Logger.captureException(error, null, {
                category,
                functionName,
                functionDetail: 'error.request',
                message: 'Network error caught and did not get error.response',
                requestUrl,
            })
        } else {
            // Something happened in setting up the request and triggered an Error
            // console.log('Error: ', error.message)
            Logger.captureException(error, null, {
                category,
                functionName,
                functionDetail: 'error.else',
                message: error.message,
                requestUrl,
            })
        }
        // console.log(error.config)
    }
}

export default Logger
