import React from 'react'
// import { Translate } from 'utils/localize'
import { isMobile } from 'react-device-detect'
import ReactTooltip from 'react-tooltip'
import getConfig from 'config/web'
import { Link } from 'features'
import { useHasMounted } from 'hooks/client-only'
import IconSteam from './IconSteam'
import IconEpic from './IconEpic'
import style from './Platforms.module.scss'
import IconDownload from './IconDownload'

interface Props {
    template: string
}

const { platformSteam, platformEpic } = getConfig()

const Platforms = ({ template }: Props): JSX.Element => {
    const hasMounted = useHasMounted()

    const getSteamLink = (): string =>
        isMobile ? platformSteam.mobile : platformSteam.desktop

    const getEpicLink = (): string =>
        isMobile ? platformEpic.mobile : platformEpic.desktop

    return (
        <div className={style(style.platforms, style[template])}>
            <div className={style.inner}>
                {hasMounted && (
                    <div className={style.icons}>
                        <div>
                            <a
                                data-for="steamTooltip"
                                href={getSteamLink()}
                                className={style.icon}
                                data-tip="Play on Steam"
                                aria-label="Play on Steam"
                            >
                                <IconSteam />
                            </a>
                            <ReactTooltip
                                id="steamTooltip"
                                effect="solid"
                                place="bottom"
                                type="dark"
                            />
                        </div>
                        <div>
                            <a
                                href={getEpicLink()}
                                className={style.icon}
                                data-for="epicTooltip"
                                data-tip="Play on Epic Games"
                                aria-label="Play on Epic Games"
                            >
                                <IconEpic />
                            </a>
                            <ReactTooltip
                                type="dark"
                                effect="solid"
                                className={style.tooltip}
                                aria-haspopup="true"
                                id="epicTooltip"
                                place="bottom"
                            />
                        </div>
                        <div>
                            <Link
                                url="/download"
                                className={style.icon}
                                data-for="downloadTooltip"
                                data-tip="Download EVE Online client"
                                aria-label="Download EVE Online client"
                            >
                                <IconDownload />
                            </Link>
                            <ReactTooltip
                                type="dark"
                                effect="solid"
                                // className={style.tooltip}
                                aria-haspopup="true"
                                id="downloadTooltip"
                                place="bottom"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Platforms
