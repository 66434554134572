import React from 'react'
import { Helmet } from 'react-helmet-async'
import Loading from 'layouts/loading/PageLoading'
import { useHasMounted } from 'hooks/client-only'

const LoadableComponent = React.lazy(
    () => import(/* webpackChunkName: "page-signup-verify" */ './SignupVerify')
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function LoadableSignupVerify(props): JSX.Element {
    const hasMounted = useHasMounted()

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            {hasMounted && (
                <React.Suspense fallback={<Loading />}>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <LoadableComponent {...props} />
                </React.Suspense>
            )}
        </>
    )
}
