import React from 'react'
import LinkArrow from 'layouts/link-arrow'

interface ArrowProps {
    color?: string
}

const Arrow = ({color = "#ba1f7e"}: ArrowProps): JSX.Element => {
    return <LinkArrow color={color} />
}

export default Arrow
