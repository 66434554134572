import styled, { css } from 'styled-components'
import { tablet, desktop } from 'styles/media-queries'

interface FrontlineStyleProps {
    children?: React.ReactNode
}

const FrontlineStyles = styled.div<FrontlineStyleProps>`
    .container {
        padding-bottom: 160px;
        ${tablet.down(css`
            padding-bottom: 60px;
        `)};

        .content {
            display: grid;
            grid-template-columns: 1fr;
            padding-bottom: 100px;
            text-align: center;
            padding: 120px 0 20px;

            ${tablet.down(css`
                padding: 40px 0 0;
            `)};

            .body {
                justify-self: center;
                max-width: 850px;
            }
        }

        .characters {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 30px;
            padding-bottom: 20px;
            margin-top: 50px;

            @media only screen and (max-width: 1250px) {
                grid-template-columns: 1fr;
                padding-bottom: 0;
            }

            ${tablet.down(css`
                grid-template-columns: 1fr;
                padding-bottom: 0;
            `)};

            .pair {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;

                position: relative;

                ${desktop.down(css`
                    margin-bottom: 40px;
                `)};

                .progress {
                    position: absolute;
                    width: 100%;
                    bottom: 35%;

                    @media only screen and (max-width: 1450px) {
                        bottom: 35%;
                    }

                    ${desktop.down(css`
                        bottom: 40%;
                    `)};

                    ${tablet.down(css`
                        bottom: 30%;
                    `)};

                    .outer {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;

                        .inner {
                            width: 115px;
                            /* height: 0; */
                            justify-items: center;
                            justify-content: center;
                            align-self: flex-end;
                            z-index: 2;
                        }

                        .circle {
                            /* outline: 1px solid #fff; */
                            /* margin-bottom: -50px; */
                        }
                    }
                }

                .pirate {
                    z-index: 10;
                    position: absolute;
                    top: 190px;
                    left: 50%;
                    transform: translate(-50%);

                    ${desktop.down(css`
                        img {
                            width: 30vw;
                        }
                    `)};
                }
            }
        }

        .progress-normal {
            display: block;
            ${desktop.down(css`
                display: none;
            `)};
            /* @media only screen and (max-width: 1450px) {
                display: none;
            } */
        }

        .progress-small {
            display: none;
            align-self: flex-end;
            position: relative;
            top: 50px;
            ${desktop.down(css`
                display: block;
            `)};
            /* @media only screen and (max-width: 1450px) {
                display: block;
            } */
        }
    }
`

export default FrontlineStyles
