export async function hashData(input: string): Promise<string> {
    // Convert the email string to a Uint8Array of bytes using TextEncoder
    const encoder = new TextEncoder()
    const data = encoder.encode(input)

    // Use the SubtleCrypto.digest() method to hash the data with SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)

    // Convert the hash buffer to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('')

    return hashHex
}
