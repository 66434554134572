import { Character } from 'types/account'
import Logger from 'utils/logging'
import client from '../client'

export default async function getCharacters(
    token: string
): Promise<Array<Character>> {
    // Only call the service if token is set, else just return false
    if (!token) return []

    const characterList = await client({
        url: `/customer/characters`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        },
        method: 'get',
    })
        .then((result) => {
            return result && result.data && result.data.characters
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'customer/characters',
                'api.customer.characters'
            )
            return null
        })

    return characterList
}
