import React, { FC } from 'react'
import { IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaIconName } from 'config/fontawesome/types'
import { useHasMounted } from 'hooks/client-only'

interface Props {
    name: FaIconName
    className?: string
    brand?: boolean
    regular?: boolean
    solid?: boolean
    light?: boolean
    fixedWidth?: boolean
}

const FontAwesome: FC<Props> = ({
    name,
    className,
    brand,
    regular,
    solid,
    light,
    fixedWidth,
}): JSX.Element => {
    const hasMounted = useHasMounted()

    let prefix: IconPrefix = 'fal'
    if (brand) prefix = 'fab'
    if (regular) prefix = 'far'
    if (solid) prefix = 'fas'
    if (light) prefix = 'fal'

    if (!hasMounted) {
        return null
    }

    return (
        <FontAwesomeIcon
            icon={[prefix, name]}
            className={className}
            fixedWidth={fixedWidth}
            // alt={name}
        />
    )
}

export default FontAwesome
