import React, { useContext } from 'react'
import ReactMarkdown from 'utils/markdown'
import { Container } from 'layouts'
import LazyLoad from 'react-lazyload'
import Link from 'features/link'
import SectionType from 'models/types/ts/sectionType'
import SelectLanguage from 'features/select-language'
import SocialIcons from 'features/social-icons'
import { flags, version } from 'config'
import getConfig, { isProduction, environment } from 'config/web'
import SectionById from 'queries/SectionByIdQuery'
import { useSelector } from 'react-redux'
import { getLanguage } from 'lib/language/selectors'
import { UserContext } from 'utils/context/UserContext'
import { Translate } from 'utils/localize'
import ContentType from 'models/types/ts/contentType'
import { useHasMounted } from 'hooks/client-only'
import { GlobalState } from 'types'
import style from './Footer.module.scss'

const config = getConfig()
const {
    authentication,
    slices,
    contentful,
    webApiUrl,
    signupServiceUrl,
    recruitmentServiceUrl,
    sentryDsn,
    testVariable,
} = config

interface FooterProps {
    frontpageTheme?: boolean
}

const Footer = ({ frontpageTheme = false }: FooterProps): JSX.Element => {
    const language = useSelector((state: GlobalState) => getLanguage(state))
    const hasMounted = useHasMounted()

    const { country } = useContext(UserContext)

    const renderSocial = (headline: string): JSX.Element => (
        <div className={`${style.row} ${style.top}`}>
            <h3>{headline}</h3>
            <SocialIcons />
        </div>
    )

    const handleCookiebotShow = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ): void => {
        e.preventDefault()
        if (window && window.Cookiebot) {
            window.Cookiebot.show()
        }
    }

    const addManageCookiesLink = (): JSX.Element => {
        return (
            <Translate>
                {({ translate }) => (
                    <ul className={style.cookieLink}>
                        <li>
                            <a
                                onClick={(e) => handleCookiebotShow(e)}
                                style={{ cursor: 'pointer' }}
                                href="#"
                            >
                                {translate(`common.manageCookies`)}
                            </a>
                        </li>
                    </ul>
                )}
            </Translate>
        )
    }

    const renderColumn = (content: ContentType): JSX.Element =>
        content && (
            <div className={style.col}>
                <ReactMarkdown source={content.body} escapeHtml={false} />
            </div>
        )

    const renderColumnWithManageCookiesLink = (content): JSX.Element =>
        content && (
            <div className={style.col}>
                {renderColumn(content)}
                {addManageCookiesLink()}
            </div>
        )

    const renderDevLinks = (): JSX.Element => (
        <div className={style.col}>
            <ul>
                <li>DEV LINKS</li>
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'signup' }}>Signup</Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                            path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'false',
                                    signupSuccess: 'true',
                                    download: 'false',
                                },
                            }}
                        >
                            Signup Success - Manual
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                            path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'true',
                                    signupSuccess: 'true',
                                    download: 'false',
                                },
                            }}
                        >
                            Recruit Signup Success - Manual
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                            path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'false',
                                    signupSuccess: 'true',
                                    download: 'true',
                                },
                            }}
                        >
                            Signup Success - Auto
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link
                            path={{
                                page: 'signup-verify',
                                query: {
                                    rec: 'true',
                                    signupSuccess: 'true',
                                    download: 'true',
                                },
                            }}
                        >
                            Recruit Signup Success - Auto
                        </Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <Link path={{ page: 'discord' }}>Discord</Link>
                    </li>
                )}
                {flags.isDevelopment && (
                    <li>
                        <a href="/test?gclid=gclid_test&yclid=yclid_test&utm_source=utm_source_test&utm_medium=utm_medium_test&utm_campaign=utm_campaign_test&utm_term=utm_term_test">
                            Signup Event Test
                        </a>
                    </li>
                )}
                {flags.isDevelopment && <li>TEST_VERSION 0.1.7</li>}
            </ul>
        </div>
    )

    const renderLanguage = (): JSX.Element => (
        <div className={style.language}>
            <SelectLanguage iconColor="#5ccbcb" />
        </div>
    )

    const renderPegi = (): JSX.Element => (
        <div className={style.pegi}>
            <a
                id="pegi-info"
                href="http://www.pegi.info/"
                title="Visit the PEGI webpage"
            >
                <LazyLoad height={50} offset={200} once>
                    <img
                        src="https://web.ccpgamescdn.com/aws/eveonline/images/pegiinfo.png"
                        width="71"
                        height="50"
                        alt="The PEGI info logo"
                    />
                </LazyLoad>
            </a>
        </div>
    )

    const renderKoRating = (): JSX.Element => (
        <div className={style.ratingKo}>
            <div className={style.ratingWrapper}>
                <div className={style.posters}>
                    <div>
                        <img
                            src="https://images.ctfassets.net/7lhcm73ukv5p/7DCdypBfdvtaKPizwccGpQ/b869e86d8ebb000bb73ad5ab0babecae/koran-rating2.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className={style.copy}>
                    <div className={style.col1}>
                        <div className={style.wrapp}>
                            <div className="notranslate">제명</div>
                            <div className="notranslate">EVE Online</div>
                        </div>
                        <div className={style.wrapp}>
                            <div className="notranslate">이용등급</div>
                            <div className="notranslate">청소년이용불가</div>
                        </div>
                        <div className={style.wrapp}>
                            <div className="notranslate">등급분류 일자</div>
                            <div className="notranslate">2019년 10월 10일</div>
                        </div>
                    </div>

                    <div className={style.col2}>
                        <div className={style.wrapp}>
                            <div className="notranslate">상호</div>
                            <div className="notranslate">CCP Games</div>
                        </div>
                        <div className={style.wrapp}>
                            <div className="notranslate">등급분류번호</div>
                            <div className="notranslate">
                                제CC-NP-191010-001호
                            </div>
                        </div>
                        <div className={style.wrapp}>
                            <div className="notranslate">제작업 신고번호 </div>
                            <div className="notranslate">제4506973469호</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${style.copyright} notranslate`}>
                Copyright © CCP 1997-
                {new Date().getFullYear()}
            </div>
        </div>
    )

    // const renderEsrb = (): JSX.Element => (
    //     <div className={style.esrb}>
    //         <img
    //             id="ESRB-rating"
    //             src="https://web.ccpgamescdn.com/aws/eveonline/images/esrbnotrated.png"
    //             alt="ESRB Rated T for violence"
    //             height="50"
    //             width="177"
    //         />
    //     </div>
    // )

    const renderSEOText = (seoText: string): JSX.Element => (
        <div className={style.seo}>
            <ReactMarkdown source={seoText} escapeHtml={false} />
        </div>
    )

    const renderRating = (): JSX.Element => {
        const rating = renderPegi()
        return <div className={style.rating}>{rating}</div>
    }

    const renderRequired = (): JSX.Element => (
        <div className={`${style.row} ${style.required}`}>
            {hasMounted && (
                <div className={`${style.copyright} notranslate`}>
                    Copyright © CCP 1997-
                    {new Date().getFullYear()}
                </div>
            )}
            {renderRating()}
        </div>
    )

    // const language = this.props.language

    return (
        <footer className={style.footer}>
            <SectionById identifier="footer" locale={language}>
                {(section: SectionType) => {
                    let headline
                    let seoText
                    let playContent
                    let playerContent
                    let universeContent
                    let siteinfoContent

                    if (section) {
                        headline = section.headline
                        seoText = section.teaser
                        playContent = section.contentCollection.items[0]
                        playerContent = section.contentCollection.items[1]
                        universeContent = section.contentCollection.items[2]
                        siteinfoContent = section.contentCollection.items[3]
                    }
                    return (
                        <Container>
                            {renderSocial(headline)}
                            <div className={`${style.row} ${style.below}`}>
                                {renderLanguage()}
                                {renderColumn(playContent)}
                                {renderColumn(playerContent)}
                                {renderColumn(universeContent)}
                                {renderColumnWithManageCookiesLink(
                                    siteinfoContent
                                )}
                                {hasMounted &&
                                    flags.isDevelopment &&
                                    renderDevLinks()}
                            </div>
                            {renderSEOText(seoText)}

                            <div id="rating_container">
                                {(country && country === 'ko') ||
                                language === 'ko' ? (
                                    <div id="ko_rating">{renderKoRating()}</div>
                                ) : (
                                    <div id="pegi_rating">
                                        {renderRequired()}
                                    </div>
                                )}
                            </div>
                            {hasMounted && !isProduction && (
                                <div className="notranslate">
                                    <div className={style.dark}>
                                        DEV PROPERTIES
                                    </div>
                                    <div className={style.dark}>
                                        env: {environment}
                                    </div>
                                    <div className={style.dark}>
                                        contentful environment:{' '}
                                        {contentful.environment}
                                    </div>
                                    <div className={style.dark}>
                                        contentful preview:{' '}
                                        {contentful.preview ? 'true' : 'false'}
                                    </div>
                                    <div className={style.dark}>
                                        Backend slice: {slices.backend}
                                    </div>
                                    <div className={style.dark}>
                                        Build slice: {slices.build}
                                    </div>
                                    <div className={style.dark}>
                                        Content slice: {slices.content}
                                    </div>
                                    <div className={style.dark}>
                                        sso login: {authentication?.authUrl}
                                    </div>
                                    <div className={style.dark}>
                                        backend api: {webApiUrl}
                                    </div>
                                    <div className={style.dark}>
                                        signup api: {signupServiceUrl}
                                    </div>
                                    <div className={style.dark}>
                                        recruitment api: {recruitmentServiceUrl}
                                    </div>
                                    <div className={style.dark}>
                                        sentry url: {sentryDsn}
                                    </div>
                                    <div className={style.dark}>
                                        serverless runtime: nodejs14.x
                                    </div>
                                    <div className={style.dark}>
                                        serverless version: 2.57.0
                                    </div>
                                    <div className={style.dark}>
                                        sentry/cli version: 1.68.0
                                    </div>
                                    <div className={style.dark}>
                                        REACT_APP_TEST_VARIABLE: {testVariable}
                                    </div>
                                    <div className={style.dark}>
                                        version: {version}
                                    </div>
                                    <div className={style.dark}>
                                        commit id:{' '}
                                        {process.env.REACT_APP_COMMIT}
                                    </div>
                                    {process.env.REACT_APP_SHOW_ALL_SETTINGS ===
                                    '1' ? (
                                        <div className={style.dark}>
                                            <pre style={{overflow: 'hidden'}}>
                                                {JSON.stringify(
                                                    config,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )}
                        </Container>
                    )
                }}
            </SectionById>
        </footer>
    )
}

Footer.defaultProps = {
    frontpageTheme: false,
}

export default Footer
