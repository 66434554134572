import React from 'react'
import { useSelector } from 'react-redux'
import { getLocationPayload } from 'lib/location/selectors'
import { Link } from 'features'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { GlobalState } from 'types'
import { Icons } from '../icons'
import frontLinesSitemap from '../../sitemap'
import { NavigationItems, NavigationItem } from './Navigation.styles'

interface Props {
    alignment?: 'horizontal' | 'vertical'
}

const Navigation = ({ alignment }: Props): JSX.Element => {
    const location = useSelector((state: GlobalState) =>
        getLocationPayload(state)
    )

    return (
        <NavigationItems>
            {frontLinesSitemap.map(({ slug, icon }) => (
                <NavigationItem
                    key={slug}
                    active={location.subpage === slug}
                    activeColor={getFaction(slug)?.color}
                >
                    <div className={alignment}>
                        <Link path={{ page: 'frontlines', subpage: slug }}>
                            <Icons icon={icon} />
                        </Link>
                    </div>
                </NavigationItem>
            ))}
        </NavigationItems>
    )
}

Navigation.defaultProps = {
    alignment: 'vertical',
}

export default Navigation
