import qs from 'query-string'
import { useCallback, useEffect, useRef, useState } from 'react'

interface ConnectionParameters {
    path: string
    parameters: Record<string, string>
}

interface ProtocolParameters extends ConnectionParameters {
    protocol?: string
}

interface PortParameters extends ConnectionParameters {
    portTimeout?: number
    port?: number
    method?: 'GET' | 'POST'
}

export interface ConfigurationParameters
    extends ProtocolParameters,
        PortParameters {
    automatic?: boolean
    automaticInterval?: number
    automaticAttempts?: number
    keepTrying?: boolean
}

const defaultParameters: Partial<ConfigurationParameters> = {
    portTimeout: 1000,
    port: 3274,
    protocol: 'evelauncher',
    automaticInterval: 1000,
    automaticAttempts: 90,
    automatic: true,
    method: 'POST',
    keepTrying: true,
}

const stringify = (parameters: Record<string, string> = {}): string =>
    Object.entries(parameters).length > 0 ? `?${qs.stringify(parameters)}` : ''

const pathWithPrefix = (path: string): string =>
    `${path.startsWith('/') ? '' : '/'}${path}`

// It should be noted that we don't actually get told if the protocol handler
// exists or not. This has been deliberately removed from chrome/ff to prevent
// browser fingerprinting. We return false only if something goes hideously wrong.
const tryOpenProtocolHandler = ({
    protocol,
    path,
    parameters,
}: ProtocolParameters): boolean => {
    try {
        window.location.href = `${protocol}:/${pathWithPrefix(path)}${stringify(
            parameters
        )}`
        return true
    } catch {
        return false
    }
}

// While tryProtocolHandler is synchronous, tryOpenPort is not. This is because we
// have to wait for the fetch to timeout before we can return false.
const tryOpenPort = async ({
    port,
    portTimeout,
    method = 'POST',
    path,
    parameters = {},
}: PortParameters): Promise<boolean> => {
    try {
        const abort = new AbortController()
        const timeout = setTimeout(() => abort.abort(), portTimeout)
        const url = `http://localhost:${port}${pathWithPrefix(path)}${
            method === 'GET' ? stringify(parameters) : ''
        }`
        const body = method === 'POST' ? JSON.stringify(parameters) : undefined
        const headers = {
            'Content-Type': 'application/json',
        }
        const resp = await fetch(url, {
            signal: abort.signal,
            redirect: 'error',
            method,
            headers,
            body,
        })
        clearTimeout(timeout)
        return resp.ok
    } catch {
        return false
    }
}

interface LauncherConnectionState {
    // When openPort succeeds, we set success to true. This is NOT the case
    // for openProtocolHandler, as we can't tell if the protocol handler exists.
    // The main use-case for this is to
    success: boolean
    // Automatically set when `automaticAttempts` have been made. This value is
    // reset when using `start`, `toggle` or `stop`.
    failed: boolean
    // Automatically set when `automatic` is true. This indicates whether we
    // are currently trying to connect to the launcher.
    active: boolean
    // Start trying to connect to the launcher. This is the same as calling
    // `toggle` when `active` is false.
    start: () => void
    // Toggle whether we are trying to connect to the launcher.
    toggle: () => void
    // Stop trying to connect to the launcher. This is the same as calling
    // `toggle` when `active` is true.
    stop: () => void
    // Manually try to connect to the launcher. If this succeeds, `success` will
    // be set to true. `failed` will NOT be set if this fails.
    openPort: () => Promise<boolean>
    // Manually try to open the protocol handler. This will always return true,
    // and `success` will NOT be set.
    openProtocolHandler: () => boolean
}

export const useLauncherConnection = (
    config: ConfigurationParameters
): LauncherConnectionState => {
    const {
        portTimeout,
        port,
        path,
        method,
        parameters,
        protocol,
        automatic,
        automaticAttempts,
        automaticInterval,
        keepTrying,
    } = { ...defaultParameters, ...config }
    const portParameters = { portTimeout, port, path, parameters, method }
    const protocolParameters = { protocol, path, parameters }

    const [active, setActive] = useState<boolean>(automatic)
    const [success, setSuccess] = useState<boolean>(false)
    const [failed, setFailed] = useState<boolean>(false)
    const attemptsMade = useRef<number>(0)

    const openPort = useCallback(async () => {
        const result = await tryOpenPort(portParameters)
        if (result) setSuccess(result)
        return result
    }, [portParameters])
    const openProtocolHandler = useCallback(
        () => tryOpenProtocolHandler(protocolParameters),
        [protocolParameters]
    )
    const toggle = useCallback(() => {
        attemptsMade.current = 0
        setActive((a) => !a)
        setFailed(false)
    }, [])
    const start = useCallback(() => {
        attemptsMade.current = 0
        setActive(true)
        setSuccess(false)
        setFailed(false)
    }, [])
    const stop = useCallback(() => {
        attemptsMade.current = 0
        setActive(false)
        setFailed(false)
    }, [])

    useEffect(() => {
        let interval
        if (active) {
            interval = setInterval(() => {
                openPort().then((result) => {
                    if (result) {
                        setSuccess(result)
                        setFailed(false)
                        setActive(false)
                        clearInterval(interval)
                    } else {
                        attemptsMade.current++
                        if (attemptsMade.current >= automaticAttempts) {
                            setFailed(true)
                            setActive(!!keepTrying)
                            if (!keepTrying) {
                                clearInterval(interval)
                            }
                        }
                    }
                })
            }, automaticInterval)
        }
        return () => {
            clearInterval(interval)
        }
    }, [active])

    return {
        success,
        failed,
        active,
        start,
        toggle,
        stop,
        openPort,
        openProtocolHandler,
    }
}
