import React from 'react'

import _map from 'lodash/map'
import SectionType from 'models/types/ts/sectionType'
import { Section } from 'layouts'
import { HeadingUnderline2 } from 'layouts/headings'
// import { ParagraphLarge } from 'layouts/typography'
import ReactMarkdown from 'utils/markdown'
import style from '../News/News.module.scss'

interface Props {
    section: SectionType
}

const LiveStream = ({ section }: Props): JSX.Element => {
    if (!section) {
        return null
    }

    return (
        <Section>
            <div className={style.news}>
                <HeadingUnderline2 title={section.name} color="#F67C0F" />
                <ReactMarkdown source={section.body} />
                {/* <ParagraphLarge>{section.headline}</ParagraphLarge> */}

                {/* {section.imageFile && (
                    <img
                        alt={section.imageFile.description}
                        src={section.imageFile.url}
                    />
                )} */}
            </div>
        </Section>
    )
}

export default LiveStream
