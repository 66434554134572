import React from 'react'
import ReactMarkdown from 'utils/markdown'
import { useSelector } from 'react-redux'
import { getLanguage } from 'selectors'
import getConfig from 'config/web'
import SectionType from 'models/types/ts/sectionType'
import ContentType from 'models/types/ts/contentType'
import { Container, Heading } from 'layouts'
import CarouselProgress from 'layouts/carousel/CarouselProgress'
import { ParagraphRegular, HeadingXSmall, LabelSmall } from 'layouts/typography'
import { ImageLazyLoad } from 'features'
import LinkArrow from 'layouts/link-arrow'
import Icons from 'pages/academy/components/icons'
import IconTypeEnum from 'pages/academy/models/icon-type-enum'
import { GlobalState } from 'types'
import s from './PatchNotes.module.scss'

interface Props {
    section: SectionType
}

const { webBaseUrl } = getConfig()

const PatchNotes = ({ section }: Props): JSX.Element => {
    const language = useSelector((state: GlobalState) => getLanguage(state))

    const {
        contentCollection: { items, total },
    } = section

    const getIcon = (iconString: string): JSX.Element => {
        let icon

        switch (iconString.toLowerCase()) {
            case 'modules':
                icon = IconTypeEnum.ActivitiesIcon
                break
            case 'technical':
                icon = IconTypeEnum.ActivitiesIcon
                break
            case 'user-interface':
                icon = IconTypeEnum.HomeIcon
                break
            case 'science-and-industry':
                icon = IconTypeEnum.CareersIndustrialistIcon
                break
            case 'events':
                icon = IconTypeEnum.Careers
                break
            case 'ships':
                icon = IconTypeEnum.Ships
                break
            case 'text':
                icon = IconTypeEnum.TextIcon
                break
            default:
                break
        }

        return <Icons icon={icon} />
    }

    const isFullLink = (path: string): boolean => {
        return path.startsWith('https')
    }

    const getLink = (slug: string): string => {
        const isExternalLink = isFullLink(slug)

        // If it is a full path url, we assumes it is an external link and return it as is
        if (isExternalLink) return slug

        let langPrefix = ''
        if (language !== 'en') langPrefix = `/${language}`

        return `${webBaseUrl}${langPrefix}/${slug}`
    }

    function renderHeading(children: React.ReactNode): JSX.Element {
        return (
            <HeadingXSmall
                className={s.heading}
                fontWeight={400}
                textTransform="unset"
            >
                {children}
            </HeadingXSmall>
        )
    }

    const imageParam = '?w=482&h=273&fm=jpg&fl=progressive&q=90&fit=fill'

    return (
        <Container>
            <div className={s.patchNotes}>
                {section.headline && (
                    <Heading size="small">{section.headline}</Heading>
                )}
                <CarouselProgress className={s.slider}>
                    {total > 0 &&
                        items
                            .filter((item) => item !== null)
                            .map((item: ContentType, key: number) => {
                                const jsonContent = item?.data as {
                                    icon: string
                                }

                                return (
                                    <div key={item.name} className={s.carousel}>
                                        <div className={s.inner}>
                                            <div className={s.img}>
                                                <ImageLazyLoad
                                                    image={item?.imageFile}
                                                    param={imageParam}
                                                    lazyloadProps={{
                                                        height: 230,
                                                        offset: 200,
                                                        once: true,
                                                    }}
                                                />
                                                {jsonContent?.icon && (
                                                    <div className={s.icon}>
                                                        {getIcon(
                                                            jsonContent.icon
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div
                                                className={s.text}
                                                key={item.headline}
                                            >
                                                {item.headline && (
                                                    <LabelSmall
                                                        className={s.tag}
                                                    >
                                                        {item.headline}
                                                    </LabelSmall>
                                                )}
                                                {item.body && (
                                                    <ReactMarkdown
                                                        source={item.body}
                                                        renderers={{
                                                            h1: ({
                                                                children,
                                                            }): JSX.Element =>
                                                                renderHeading(
                                                                    children
                                                                ),
                                                            h2: ({
                                                                children,
                                                            }): JSX.Element =>
                                                                renderHeading(
                                                                    children
                                                                ),
                                                            h3: ({
                                                                children,
                                                            }): JSX.Element =>
                                                                renderHeading(
                                                                    children
                                                                ),
                                                            h4: ({
                                                                children,
                                                            }): JSX.Element =>
                                                                renderHeading(
                                                                    children
                                                                ),
                                                            p: ({
                                                                children,
                                                            }) => (
                                                                <ParagraphRegular>
                                                                    {children}
                                                                </ParagraphRegular>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                {item.buttonUrl && (
                                                    <a
                                                        className={s.link}
                                                        lang={language}
                                                        href={getLink(
                                                            item.buttonUrl
                                                        )}
                                                        target={
                                                            isFullLink(
                                                                item.buttonUrl
                                                            )
                                                                ? '_blank'
                                                                : '_self'
                                                        }
                                                        rel="noreferrer"
                                                    >
                                                        <LabelSmall>
                                                            {item.buttonText}
                                                        </LabelSmall>
                                                        <LinkArrow />
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                </CarouselProgress>
            </div>
        </Container>
    )
}

export default PatchNotes
