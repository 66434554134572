import React from 'react'
import { Target } from '@ccpgames/eve-web/components/target/Target'
import ContentType from 'models/types/ts/contentType'
import getInternalLinkPath from 'lib/link'
import { Link } from 'features'
import LinkArrow from 'layouts/link-arrow'
import { CountDown } from 'features/count-down'
import Arrow from 'layouts/arrow'
import { colors } from 'config/styled-theme'
import cx from 'classnames'
import s from './Announcement.module.scss'

interface Props {
    content: ContentType
    countDownContent?: ContentType
}

const Announcement = ({ content, countDownContent }: Props): JSX.Element => {
    const [refresh, setRefresh] = React.useState(false)
    const { headline, body, imageFile, buttonUrl, buttonText } = content

    if (
        countDownContent?.date &&
        new Date() <= new Date(countDownContent.date)
    ) {
        return (
            <div className={cx(s.announcement, s.countDownAnnouncementWrapper)}>
                <Link
                    path={getInternalLinkPath(buttonUrl)}
                    className={s.saleWrapper}
                    data-id="eveNav_sale"
                >
                    <div className={s.countDownWrapper}>
                        <CountDown
                            date={String(countDownContent.date)}
                            title={countDownContent.headline}
                            onComplete={() => {
                                setTimeout(() => setRefresh(!refresh), 2000)
                            }}
                        />
                        <div className={s.buttonWrapper}>
                            {countDownContent.buttonText}
                            <Arrow color={colors.primary} />
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    const isExternal =
        buttonUrl &&
        (buttonUrl.startsWith('http://') || buttonUrl.startsWith('https://'))

    const InnerContent = (
        <>
            <div className={s.content}>
                <div className={s.headline}>{headline}</div>
                <div className={s.teaser}>{body}</div>
                <div className={s.button}>
                    {buttonText}
                    <LinkArrow color="#5ccbcb" />
                </div>
            </div>
            <div className={s.img}>
                {imageFile?.url && (
                    <picture>
                        <source
                            key="announcement-lg"
                            srcSet={`${imageFile.url}?fm=webp&fit=fill&w=145&h=120`}
                            media="(min-width: 500px)"
                            width={145}
                            height={120}
                        />
                        <source
                            key="announcement-sm"
                            srcSet={`${imageFile.url}?fm=webp&fit=fill&w=145&h=100`}
                            media="(min-width: 0px)"
                            width={145}
                            height={100}
                        />
                        <img
                            src={`${imageFile.url}?fm=webp&fit=fill&w=145&h=120`}
                            alt=""
                            width={145}
                            height={120}
                        />
                    </picture>
                )}
            </div>
        </>
    )

    return (
        <div className={s.announcement}>
            <Target color="#5ccbcb">
                {isExternal ? (
                    <a
                        href={buttonUrl}
                        className={s.saleWrapper}
                        data-id="eveNav_sale"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {InnerContent}
                    </a>
                ) : (
                    <Link
                        path={getInternalLinkPath(buttonUrl)}
                        className={s.saleWrapper}
                        data-id="eveNav_sale"
                    >
                        {InnerContent}
                    </Link>
                )}
            </Target>
        </div>
    )
}

export default Announcement
