import React, { useState } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import cx from 'classnames'
import { Translate } from 'utils/localize'
import ReCaptcha from 'react-google-recaptcha'
import { ClipLoader } from 'react-spinners'
import ReactMarkdown from 'utils/markdown'
import { isClient, isLocal } from 'config/web'
import { Button, Input2, Checkbox, Icon, AlertMessage } from 'layouts'
import { Modal, useModal } from 'layouts/modal'
import SectionById from 'queries/section-by-id'
import SectionType from 'models/types/ts/sectionType'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import VerificationManager from 'packages/authentication/components/verification-callback/VerificationManager'
import { FormFieldsType, SignupFormPropsType } from './models/types'
import signupValidation from './signupValidation'
import FormGroup from './components/form-group'
import LoginLink from './components/login-link'
import Debug from './components/debug'
import style from './SignupForm.module.scss'

const validation: { [key: string]: boolean } = {}

async function validateInput(
    key: string,
    value: string,
    validationFunction: (value: string, message: string) => Promise<unknown>,
    message: string
): Promise<unknown> {
    const errorMessage = await validationFunction(value, message).then(
        (data: unknown) => {
            validation[key] = true
            return data
        }
    )
    if (errorMessage) {
        validation[key] = false
        return errorMessage
    }
    validation[key] = true
    return ''
}

let formFields
let formActions
function setFormProps(
    fields: FormFieldsType,
    actions: FormikHelpers<FormFieldsType>
): void {
    formFields = fields
    formActions = actions
}

interface Window {
    recaptchaOptions: {
        useRecaptchaNet: boolean
    }
}
declare let window: Window

const SignupForm = ({
    handleSubmit,
    validateEmail,
    validateUsername,
    theme = 'dark',
    showLogin = true,
    showRecruitLogin = false,
}: SignupFormPropsType): JSX.Element => {
    const recaptchaRef = React.createRef<ReCaptcha>()
    const [reCaptchaValue, setCaptchaValue] = useState(null) // eslint-disable-line
    const [showPassword, toggleShowPassword] = useState(false)
    const { isOpen, toggleModal } = useModal()
    const language = useSelector((state: GlobalState) => state.language)

    // Use recaptcha.net. google.com may not be accessible in all locations
    // https://developers.google.com/recaptcha/docs/faq#can-i-use-recaptcha-globally
    if (isClient) {
        window.recaptchaOptions = { useRecaptchaNet: true }
    }

    const verificationManager = new VerificationManager()
    const userEmailFromAgeVerification = verificationManager.getSessionStorage(
        verificationManager.sessionEmail
    )

    const initialValues: FormFieldsType = {
        email: userEmailFromAgeVerification || '',
        username: '',
        password: '',
        agreedTerms: false,
        global: '',
    }

    return (
        <Translate>
            {({ translate }) => (
                <Formik
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur
                    validationSchema={signupValidation(translate)}
                    onSubmit={(fields, actions) => {
                        // Storing fields and actions to be used after recaptcha has executed.
                        setFormProps(fields, actions)

                        // Reset first to make sure we have fresh value, can happen when there is an error in form and it was submitted to server and recaptcha value used.
                        recaptchaRef.current.reset()

                        // Calling recaptcha, that will call handleSubmit after it gets captcha value.
                        recaptchaRef.current.execute()
                    }}
                >
                    {({ values, errors, isSubmitting, dirty }) => (
                        <Form className={style(style.signup, style[theme])}>
                            {errors.global && (
                                <div className={style.globalError}>
                                    <AlertMessage error>
                                        {errors.global}
                                    </AlertMessage>
                                </div>
                            )}
                            <FormGroup>
                                <Field
                                    id="signup-email"
                                    name="email"
                                    autoFocus
                                    isFormDirty={dirty}
                                    labelText={translate('signup.email')}
                                    type="email"
                                    disabled={
                                        isSubmitting ||
                                        userEmailFromAgeVerification?.length > 0
                                    }
                                    isServerValidated={validation.email}
                                    component={Input2}
                                    //   onChange={handleChange}
                                    validate={(value: string) =>
                                        validateInput(
                                            'email',
                                            value,
                                            validateEmail,
                                            `${translate(
                                                'signup.validation.emailInvalid'
                                            )}`
                                        )
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <span
                                    className={style.usernameDisclaimer}
                                    id="username-not-visible"
                                >
                                    {translate(
                                        'signup.validation.usernameNotVisibleOtherPlayers'
                                    )}
                                </span>
                                <Field
                                    id="signup-username"
                                    name="username"
                                    isFormDirty={dirty}
                                    labelText={translate('signup.username')}
                                    type="text"
                                    disabled={isSubmitting}
                                    isServerValidated={validation.username}
                                    component={Input2}
                                    validate={(value: string) =>
                                        validateInput(
                                            'username',
                                            value,
                                            validateUsername,
                                            `${translate(
                                                'signup.validation.usernameUnavailable'
                                            )}`
                                        )
                                    }
                                />
                            </FormGroup>
                            <FormGroup>
                                <div
                                    id={
                                        showPassword
                                            ? 'hide-password'
                                            : 'show-password'
                                    }
                                    data-id="show/hide"
                                    role="button"
                                    className={style.showHidePass}
                                    tabIndex={-1}
                                    title={
                                        showPassword
                                            ? `${translate(
                                                  'signup.passwordHide'
                                              )}`
                                            : `${translate(
                                                  'signup.passwordShow'
                                              )}`
                                    }
                                    onClick={() => {
                                        toggleShowPassword(!showPassword)
                                    }}
                                >
                                    <Icon
                                        name={
                                            showPassword ? 'eye-slash' : 'eye'
                                        }
                                    />
                                </div>
                                <Field
                                    id="signup-password"
                                    name="password"
                                    isFormDirty={dirty}
                                    type={showPassword ? 'text' : 'password'}
                                    disabled={isSubmitting}
                                    isServerValidated
                                    labelText={translate('signup.password')}
                                    component={Input2}
                                    //   validate={(value: string) => validatePassword(value)}
                                />
                            </FormGroup>
                            <FormGroup className={style.checkbox} slim>
                                <Field
                                    id="agree-terms"
                                    type="checkbox"
                                    disabled={isSubmitting}
                                    name="agreedTerms"
                                    checked={values.agreedTerms}
                                    component={Checkbox}
                                >
                                    <span className={style.terms}>
                                        {translate('signup.iAgreeTo')}{' '}
                                        <span
                                            tabIndex={0}
                                            role="button"
                                            onClick={toggleModal}
                                            className={style.terms__button}
                                        >
                                            {translate('signup.termsOfService')}{' '}
                                        </span>
                                        {translate('signup.iAgreeToAfter')}{' '}
                                    </span>
                                </Field>
                            </FormGroup>
                            <div className={style.recaptchaBadge}>
                                <ReCaptcha
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey="6LeOEbkUAAAAAN6MuQlva4QFH4nIdWXoOZUa0G09"
                                    onExpired={(): void => {}} // Returning empty because by default it will call the onChange with null to signify expired callback.
                                    onChange={(value) => {
                                        // If user gets the challenge then all form values are ready and when he gets it right,
                                        // this executes, sets the code value and submits the form.
                                        // Set the recaptcha value in form to the code we get.
                                        // setFieldValue('recaptcha', value)
                                        setCaptchaValue(value)
                                        // Finally submit form
                                        // submitForm()

                                        // console.log('VALUE: ', value)
                                        handleSubmit(
                                            formFields,
                                            formActions,
                                            value
                                            // isUpSell
                                        )
                                    }}
                                />
                            </div>
                            <div
                                id="signup_extra"
                                className={cx(style.formGroup, style.extra)}
                            />
                            <FormGroup slim>
                                <Button
                                    as="button"
                                    theme={
                                        theme === 'quadrant'
                                            ? 'quadrant'
                                            : 'primary'
                                    }
                                    size="regular"
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={style.submit}
                                >
                                    {isSubmitting ? (
                                        <div>
                                            <ClipLoader
                                                size={30}
                                                color="#5ccbcb"
                                                loading
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            {translate(
                                                'signup.startPlayingForFree'
                                            )}
                                        </div>
                                    )}
                                </Button>
                            </FormGroup>
                            {showLogin && (
                                <LoginLink
                                    showRecruitLogin={showRecruitLogin}
                                />
                            )}
                            <div className={style.recaptchaText}>
                                <ReactMarkdown
                                    escapeHtml
                                    source={translate(
                                        'signup.googleCaptchaText'
                                    ).toString()}
                                    renderers={{
                                        p: ({ children }) => (
                                            <div>{children}</div>
                                        ),
                                        link: ({ children, href }) => (
                                            <a href={href} target="blank">
                                                {children}
                                            </a>
                                        ),
                                    }}
                                />
                            </div>
                            {isLocal && <Debug />}
                            <Modal isOpen={isOpen} hide={toggleModal}>
                                <SectionById
                                    identifier="terms-of-service-used-on-signup"
                                    locale={language}
                                >
                                    {(section: SectionType) => {
                                        if (section) {
                                            return (
                                                <div className={style.content}>
                                                    <ReactMarkdown
                                                        source={section.body}
                                                    />
                                                </div>
                                            )
                                        }
                                    }}
                                </SectionById>
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </Translate>
    )
}

export default SignupForm
