export type ErrorCodeType =
    | '400'
    | '401'
    | '403'
    | '404'
    | '408'
    | '409'
    | '429'
    | '500'

export interface QueryParameterProps {
    linked: string
    error_code: ErrorCodeType
}

export enum LinkingStateTypeEnum {
    Start = 'start',
    Loading = 'loading',
    AlreadyLinked = 'alreadyLinked',
    Linking = 'linking',
    Linked = 'linked',
    Unlinking = 'unlinking',
    Error = 'error',
    GenericError = 'generic-error',
}
