import React from 'react'
import {
    BLOCKS,
    Document,
    TopLevelBlock,
    NodeData,
} from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'react-scroll'
import { Translate } from 'react-localize-redux'
import Heading from 'layouts/headings/components/heading'
import FontAwesome from 'layouts/font-awesome'
import { Collapse } from '@mantine/core'
import StickyTableOfContentsStyle, {
    CollapseCloseIconContainer,
    CollapseHamburgerContainer,
    MobileTableOfContentsContainer,
    CollapseContainer,
} from './StickyTableOfContents.styles'

interface Props {
    body: Document
}

// the body is the json object returned by the rich text field
// const headingTypes = [BLOCKS.HEADING_2, BLOCKS.HEADING_3] // If we want to include heading 3 later, this is the filter for it
const headingTypes = [BLOCKS.HEADING_2]

const getHeadings = (body: Document): TopLevelBlock[] =>
    body.content
        .filter((item) => headingTypes.includes(item.nodeType))
        .map((item, index) => {
            item = { ...item, data: { ...item.data, headerIndex: index + 1 } }
            return item
        })

const getContentfulDocument = (headings: TopLevelBlock[]): Document => ({
    nodeType: BLOCKS.DOCUMENT,
    content: headings,
    data: {},
})

const renderLink = (children: React.ReactNode, index: number): JSX.Element => {
    const id = `h2-${index}` // must be string

    return (
        <li>
            <Link
                key={id}
                href={`#${id}`}
                to={id}
                smooth
                spy
                hashSpy
                offset={-110}
                activeClass="active"
            >
                {children}
            </Link>
        </li>
    )
}

const options = {
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => {
            return renderLink(children, node.data.headerIndex)
        },
        // NOT doing heading 3 in table of contents, if added then also need to account for how heading 3 is rendered with correct Id's
        // [BLOCKS.HEADING_3]: (node, children) => {
        //     return renderLink(children)
        // },
    },
}

const TableOfContentsHeader = (): JSX.Element => {
    return (
        <Translate>
            {({ translate }) => (
                <Heading size="small" isThemed>
                    {translate('news.tableOfContents')}
                </Heading>
            )}
        </Translate>
    )
}

const StickyTableOfContents = ({ body }: Props): JSX.Element => {
    const contentfulDocument = getContentfulDocument(getHeadings(body))

    React.useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            body {
                overflow: unset;
                overflowY: unset;
            }
        `
        document.head.appendChild(style)

        return () => {
            document.head.removeChild(style)
        }
    }, [])

    return (
        <StickyTableOfContentsStyle>
            <TableOfContentsHeader />
            <ul>{documentToReactComponents(contentfulDocument, options)}</ul>
        </StickyTableOfContentsStyle>
    )
}

export const MobileTableOfContents = ({ body }: Props): JSX.Element => {
    const contentfulDocument = getContentfulDocument(getHeadings(body))
    const [opened, setOpened] = React.useState(false)

    React.useEffect(() => {
        const style = document.createElement('style')
        style.innerHTML = `
            body {
                overflow: unset;
                overflowY: unset;
            }
        `
        document.head.appendChild(style)

        return () => {
            document.head.removeChild(style)
        }
    }, [])

    return (
        <MobileTableOfContentsContainer>
            <span onClick={() => setOpened(!opened)}>
                <TableOfContentsHeader />
                {opened && (
                    <CollapseCloseIconContainer
                        onClick={() => setOpened(false)}
                    >
                        <FontAwesome name="xmark" />
                    </CollapseCloseIconContainer>
                )}
                <CollapseHamburgerContainer>
                    <FontAwesome light name="bars" />
                </CollapseHamburgerContainer>
            </span>
            <Collapse in={opened} transitionDuration={300}>
                <CollapseContainer>
                    <ul>
                        {documentToReactComponents(contentfulDocument, options)}
                    </ul>
                </CollapseContainer>
            </Collapse>
        </MobileTableOfContentsContainer>
    )
}

export default StickyTableOfContents
