import { colors } from 'config/styled-theme'
import styled from 'styled-components'

interface StickyTableOfContentsStyleProps {
    children?: React.ReactNode
}

const linkStyles = `
    ul {
        list-style:none;
        margin: 0px;
        padding-left: 26px;
    }

    a {
        display: inline-block;
        color: #FFF;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height 30px;
        padding-bottom: 6px;
        transition: 0.4s all;

        :hover {
            color: ${colors.primary};
        }
    }

    .active {
        color: ${colors.primary};
    }
`

const StickyTableOfContentsStyle = styled.div<StickyTableOfContentsStyleProps>`
    position: sticky;
    top: 8rem;

    h2 {
        margin-bottom: 21px;
    }

    ${linkStyles}
`

export const MobileTableOfContentsContainer = styled.div<StickyTableOfContentsStyleProps>`
    position: sticky;
    bottom: 0;
    background: #101010;
    padding: 16px 20px;
    border: solid 1px #474746;
    font-size: 18px;

    ${linkStyles}

    && h2 {
        font-size: 18px;
    }

    && h2::before {
        min-height: 18px;
    }

    ul {
        padding-left: 20px;
    }
`

export const CollapseCloseIconContainer = styled.span`
    position: absolute;
    right: 20px;
    top: 20px;
    color: #B0B0B0;
`
export const CollapseHamburgerContainer = styled.span`
    position: absolute;
    right: 20px;
    bottom: 4px;
    font-size: 32px;
`

export const CollapseContainer = styled.div`
    max-height: 60vh;
    overflow-y: scroll;

    margin-top: 21px;
    margin-bottom: 32px;
`


export default StickyTableOfContentsStyle
