import styled, { css } from 'styled-components'
import { tablet, mobile, desktop } from 'styles/media-queries'

interface CharacterStyleProps {
    children?: React.ReactNode
    faction?: string
}

const CharacterStyles = styled.div<CharacterStyleProps>`
    position: relative;
    z-index: 1; // this is important, so that the progress is above the character

    :hover {
        .buttonContainer {
            opacity: 1;
        }
        img {
            filter: brightness(85%);
        }
        .factionName {
            opacity: 0;
            ${tablet.down(css`
                opacity: 1;
            `)};
        }
    }

    container-type: inline-size;

    .factionLogo {
        position: absolute;
        left: 10px;
        z-index: 5;
        top: 7vw;

        ${desktop.up(css`
            top: 55px;
        `)};

        ${(props) =>
            (props.faction === 'minmatar' || props.faction === 'caldari') &&
            `
        right: 20px;
        left: auto;
        `}
    }

    img {
        transition: all ease-in-out 0.3s;
    }

    .bottomGradient {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(0deg, #000, transparent);
    }

    .buttonContainer {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        transition: all ease-in-out 0.3s;
        opacity: 0;

        button {
            background-color: #101010;
        }

        ${tablet.down(css`
            display: none;
        `)};
    }

    .factionName {
        opacity: 1;
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        transition: all ease-in-out 0.3s;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 18px;

        ${tablet.down(css`
            bottom: 8px;
        `)};

        // If we want to have icon with faction name, we can manipulate svg here
        svg {
            margin-left: 8px;
            position: relative;
            top: 6px;
        }

        .factionNameColor {
            color: #b0b0b0;
        }
    }

    .factionIcon {
        position: absolute;
        bottom: 5px;
        right: 12px;
    }
`

export default CharacterStyles
