import { Middleware } from 'redux'
import Analytics from 'utils/analytics'

interface AnalyticsAction {
    type: string
    payload?: {
        page?: string
        subpage?: string
        id?: string
    }
    // Define other properties of the action as necessary
}

const analyticsReporter: Middleware = (store) => (next) => (
    action: AnalyticsAction
) => {
    switch (action.type) {
        case 'PAGE':
            // 2019-02-05|GPS - Leaving this structure here intact for the moment, depending on what we do with the products.
            break
        case 'FETCH_PAGE_ARTICLE':
            // Nothing being used here at the moment
            break
        case 'FETCH_PAGE_TRANSITION_EVENT':
            // We´re pushing the Page Transition events manually, so we can properly control what the Google Tag Manager picks up as page hits

            setTimeout(() => {
                Analytics.PushEvent('pageTransition', 'Middleware')
            }, 0)
            break
        default:
            break
    }

    return next(action)
}

export default analyticsReporter
