import React from 'react'
import style from './Border.module.scss'

interface Props {
    primary?: boolean
    secondary?: boolean
    dark?: boolean
    small?: boolean
    clean?: boolean
    transparent?: boolean
    reversed?: boolean
    centered?: boolean
    className?: string
    [x: string]: unknown
}

const Border = ({
    primary,
    secondary,
    dark,
    small,
    clean,
    transparent,
    reversed,
    centered,
    className,
    ...rest
}: Props): JSX.Element => {
    rest.className = style('border', className, {
        primary,
        secondary,
        dark,
        small,
        clean,
        transparent,
        reversed,
    })

    return (
        <div {...rest}>
            <div className={style(style.inner, { reversed, centered })} />
        </div>
    )
}

export default Border
