import React from 'react'
import { Link } from 'features'
import { FactionLabel, FactionLogo } from '@ccpgames/eve-web/components'
import { useMediaQuery } from '@mantine/hooks'
import { TransparentButtonWithTarget } from 'layouts/button-with-target'
import ContentType from 'models/types/ts/contentType'
import { FactionType } from 'pages/frontlines/models/frontlines-types'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import CharacterStyles from './Character.styles'

interface Props {
    faction: FactionType
    content: ContentType
}

const Character = ({ faction, content }: Props): JSX.Element => {
    const matches = useMediaQuery('(min-width: 992px)')

    if (faction && content) {
        const image = content.imageFile.url
        const color = getFaction(faction).color
        const buttonText = content.buttonText

        const width = content.imageFile?.width || 378
        const height = content.imageFile?.height || 427

        return (
            <div>
                <CharacterStyles faction={faction}>
                    <Link path={{ page: 'frontlines', subpage: faction }}>
                        <div className="factionLogo">
                            <FactionLogo
                                faction={faction}
                                size={44}
                                fillColor="white"
                                strokeWidth={0}
                            />
                        </div>

                        <img
                            loading="lazy"
                            src={`${image}?fm=webp&w=${width}&h=${height}&q=90`}
                            alt="faction name"
                            width={width}
                            height={height}
                        />
                        <div className="bottomGradient" />
                        <div className="buttonContainer">
                            <TransparentButtonWithTarget
                                color={color}
                                backgroundColor="transparent"
                            >
                                {buttonText}
                            </TransparentButtonWithTarget>
                        </div>
                        {matches && (
                            <div className="factionName">
                                <FactionLabel
                                    faction={faction}
                                    iconPosition="none"
                                    className="factionNameColor"
                                />
                            </div>
                        )}
                    </Link>
                </CharacterStyles>
            </div>
        )
    }

    return <></>
}

export default Character
