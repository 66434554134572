import React from 'react'

const VgLogo = (): JSX.Element => (
    <svg
        width="380"
        height="435"
        viewBox="0 0 380 435"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_267_3113)">
            <path
                d="M213.231 435.055C210.488 435.055 208.58 434.53 207.602 433.504C206.6 432.477 206.1 430.615 206.1 427.989V392.181H214.543V425.673C214.543 426.342 214.662 426.843 214.925 427.273C215.187 427.679 215.736 428.18 216.88 428.18H220.673C221.818 428.18 222.366 427.679 222.629 427.273C222.891 426.867 223.01 426.342 223.01 425.697V392.205H231.454V428.013C231.454 430.639 230.953 432.501 229.951 433.527C228.973 434.554 227.065 435.079 224.322 435.079H213.207L213.231 435.055Z"
                fill="white"
            />
            <path
                d="M68.9068 392.205L78.4235 435.079H70.1471L64.3512 405.239L58.5553 435.079H50.3027L59.7956 392.205H68.9068Z"
                fill="white"
            />
            <path
                d="M270.594 392.205L280.111 435.079H271.834L266.038 405.239L260.266 435.079H251.99L261.506 392.205H270.594Z"
                fill="white"
            />
            <path
                d="M120.76 435.079L110.79 410.992L110.909 435.079H103.515V392.205H110.623L120.187 415.146V392.205H127.629V435.079H120.76Z"
                fill="white"
            />
            <path
                d="M161.402 435.079C158.659 435.079 156.751 434.554 155.773 433.527C154.771 432.501 154.271 430.639 154.271 428.013V397.48C154.271 395.809 154.676 394.52 155.535 393.565C156.346 392.658 157.61 392.205 159.375 392.205H178.957V399.677H164.813C163.453 399.677 162.547 400.608 162.547 402.016V425.459C162.547 426.867 163.453 427.798 164.813 427.798H171.038V410.777H178.957V435.055H161.378L161.402 435.079Z"
                fill="white"
            />
            <path
                d="M355.004 435.079V392.205H374.896C376.661 392.205 377.925 392.658 378.736 393.565C379.595 394.52 380 395.809 380 397.48V428.013C380 430.639 379.499 432.501 378.498 433.527C377.52 434.554 375.611 435.079 372.869 435.079H355.004ZM363.161 428.204H369.529C370.412 428.204 371.056 427.941 371.437 427.392C371.724 426.986 371.867 426.461 371.867 425.793V401.443C371.867 400.035 370.96 399.104 369.601 399.104H363.161V428.204Z"
                fill="white"
            />
            <path
                d="M9.5167 435.079L0 392.204H8.27642L14.0723 422.068L19.8682 392.204H28.1208L18.6279 435.079H9.5167Z"
                fill="white"
            />
            <path
                d="M327.789 397.933C327.789 396.119 327.336 394.735 326.382 393.708C325.476 392.729 323.925 392.228 321.779 392.228H303.628V435.103H311.857V399.103H317.39C318.821 399.103 319.728 400.058 319.728 401.514V413.641H313.335L320.753 435.079H328.648L323.114 419.108H327.789V397.933Z"
                fill="white"
            />
            <path
                d="M140.699 363.606H170.847V358.449H140.699V363.606ZM140.699 355.823H146.137V354.081H170.847V348.924H140.699V355.823ZM146.137 366.184H140.699V373.131H170.847V367.974H146.137V366.184ZM214.591 366.184H209.129V373.131H239.301V367.974H214.615V366.184H214.591ZM190 367.425C189.356 366.303 179.315 348.924 179.315 348.924H173.399L190 377.834L206.553 348.924H200.638C200.638 348.924 190.644 366.303 190 367.425ZM209.129 348.924V355.823H214.591V354.081H239.277V348.924H209.105H209.129ZM209.129 363.606H239.301V358.449H209.129V363.606Z"
                fill="white"
            />
            <path
                d="M283.736 138.053L286.336 133.565V53.3301L283.736 57.8181V138.053Z"
                fill="white"
            />
            <path
                d="M287.982 130.678V136.431L297.069 120.603V114.826V34.5918L287.982 50.443V130.678Z"
                fill="white"
            />
            <path
                d="M54.9297 65.7206L62.4429 78.8503V33.2305H54.9297V65.7206Z"
                fill="white"
            />
            <path
                d="M207.817 262.953V190.119L203.572 197.495V270.329L207.817 262.953Z"
                fill="white"
            />
            <path
                d="M132.495 189.26L137.408 197.854V129.508L132.495 120.938V189.26Z"
                fill="white"
            />
            <path
                d="M209.964 266.653L210.489 265.698V185.463L209.964 186.394V266.653Z"
                fill="white"
            />
            <path
                d="M214.066 267.25L215.664 264.48V256.698V176.463L214.066 179.233V259.467V267.25Z"
                fill="white"
            />
            <path
                d="M269.783 162.403L270.403 161.305V81.0703L269.783 82.1684V162.403Z"
                fill="white"
            />
            <path
                d="M260.362 98.5928L253.374 110.744H250.702V84.4365H249.438H247.578V120.842L245.383 124.685L244.215 126.714L243.809 127.407L242.545 129.603L238.657 136.383V216.617L242.545 209.837L243.809 207.641L244.215 206.949L245.383 204.92L247.53 201.172V229.795H249.438H249.462V197.782L251.084 194.965L252.61 192.339V241.85H253.063V190.191L260.362 177.514V98.5928Z"
                fill="white"
            />
            <path
                d="M275.46 152.472L280.087 144.451V66.6987L275.46 74.7198V152.472Z"
                fill="white"
            />
            <path
                d="M224.346 241.54V252.426L237.011 230.392V219.506V146.146L224.346 168.181V241.54Z"
                fill="white"
            />
            <path
                d="M281.065 145.454L282.091 143.688V63.0474L281.065 64.8378V145.454Z"
                fill="white"
            />
            <path
                d="M218.24 252.21L218.741 251.375V180.235L218.24 181.071V252.21Z"
                fill="white"
            />
            <path
                d="M156.966 247.078L157.491 247.913V170.567L156.966 169.731V247.078Z"
                fill="white"
            />
            <path
                d="M128.822 194.773L129.8 196.444V116.234L128.822 114.539V194.773Z"
                fill="white"
            />
            <path
                d="M271.953 78.683V158.607L274.481 154.215V74.2905L271.953 78.683Z"
                fill="white"
            />
            <path
                d="M325.595 65.1477V69.4924L336.567 50.3707V46.026V33.2305H325.595V65.1477Z"
                fill="white"
            />
            <path
                d="M321.087 73.0006L323.949 68.0352V34.3037H321.087V73.0006Z"
                fill="white"
            />
            <path
                d="M339.167 33.2305V41.4903L343.556 33.8511V33.2305H339.167Z"
                fill="white"
            />
            <path
                d="M91.5415 129.913L91.8277 130.415V50.2039L91.5415 49.6787V129.913Z"
                fill="white"
            />
            <path
                d="M264.846 171.188L265.132 170.687V90.4521L264.846 90.9773V171.188Z"
                fill="white"
            />
            <path
                d="M140.342 140.44V214.874L141.2 216.378V264.17H141.558V217.023L145.47 223.802V149.393L140.342 140.44Z"
                fill="white"
            />
            <path
                d="M149.548 230.94L152.339 235.834V155.599L149.548 150.706V230.94Z"
                fill="white"
            />
            <path
                d="M95.4541 131.034L104.231 146.36V74.6717L95.4541 59.3457V131.034Z"
                fill="white"
            />
            <path
                d="M105.495 138.435H106.353V162.57L109.287 167.702V77.4651L105.495 70.8286V138.435Z"
                fill="white"
            />
            <path
                d="M316.007 33.2305H313.05V35.1164H299.192V116.568L313.05 92.4098V89.1631L316.365 83.4815V81.2137L319.322 76.0573V33.2305H316.365H316.007Z"
                fill="white"
            />
            <path
                d="M177.073 195.658L175.546 193.008V276.346L177.073 279.019L178.385 281.311V197.973L177.073 195.658Z"
                fill="white"
            />
            <path
                d="M84.5293 115.851L89.7528 124.971V46.3115L84.5293 37.1685V115.851Z"
                fill="white"
            />
            <path
                d="M173.734 279.424L174.282 280.331V195.728L173.734 194.821V279.424Z"
                fill="white"
            />
            <path
                d="M149.548 123.014L152.339 127.884V71.2349L149.548 76.1048V123.014Z"
                fill="white"
            />
            <path
                d="M214.066 128.075L215.664 130.82V49.6784L214.066 46.9092V128.075Z"
                fill="white"
            />
            <path
                d="M203.572 109.861L202.594 108.142V199.238L203.572 197.496V119.386L207.817 126.738V36L203.572 28.5996V109.861Z"
                fill="white"
            />
            <path
                d="M209.964 39.7231V120.913L210.489 121.868V40.6542L209.964 39.7231Z"
                fill="white"
            />
            <path
                d="M172.946 116.759C173.042 116.591 173.137 116.424 173.399 116.09V34.7812L172.946 35.4497V116.759Z"
                fill="white"
            />
            <path
                d="M180.055 203.989V284.2L185.278 293.343L189.643 300.958V220.724L185.278 213.108L180.055 203.989Z"
                fill="white"
            />
            <path
                d="M178.385 295.777H179.673V283.578L178.385 281.31V295.777Z"
                fill="white"
            />
            <path
                d="M210.489 185.463L214.066 179.232V128.074L210.489 121.867V185.463Z"
                fill="white"
            />
            <path
                d="M189.786 301.508L189.762 301.531V321.107H191.432V339.417H192.982V295.945L191.432 298.643L189.786 301.508Z"
                fill="white"
            />
            <path
                d="M145.469 223.802V270.329H145.517H146.519V225.64L145.517 223.921L145.469 223.802Z"
                fill="white"
            />
            <path
                d="M191.575 218.408L193.149 215.71V91.7176L191.575 89.02V218.408Z"
                fill="white"
            />
            <path
                d="M177.073 112.795C177.526 112.007 177.955 111.243 178.385 110.503V25.8286L177.073 28.1203L175.546 30.7702L174.544 32.489V117.14C174.878 116.567 175.212 115.97 175.546 115.421C176.071 114.514 176.572 113.631 177.073 112.771V112.795Z"
                fill="white"
            />
            <path
                d="M274.481 225.641H275.459V152.472L274.481 154.215V225.641Z"
                fill="white"
            />
            <path
                d="M92.854 131.918L94.9529 135.618V55.4077L92.854 51.7075V131.918Z"
                fill="white"
            />
            <path
                d="M169.321 265.459L172.374 270.807V193.914L169.321 188.567V265.459Z"
                fill="white"
            />
            <path
                d="M169.011 119.624L172.374 113.656V36.3096L169.011 42.1583V119.624Z"
                fill="white"
            />
            <path
                d="M153.531 138.626V147.125L154.509 148.843V140.345V67.439L153.531 69.1578V138.626Z"
                fill="white"
            />
            <path
                d="M234.96 49.9883H234.03V64.8129H234.96V49.9883Z"
                fill="white"
            />
            <path
                d="M203.047 102.006V84.0306H202.594V26.8804L194.89 13.4165V94.7492L202.594 108.142V102.006H203.047Z"
                fill="white"
            />
            <path
                d="M258.025 190.812H257.19V219.029H258.335V203.989H258.025V190.812Z"
                fill="white"
            />
            <path
                d="M278.68 152.495H276.843V179.041H278.68V152.495Z"
                fill="white"
            />
            <path
                d="M261.053 227.933V206.4H259.241V234.879H263.033V260.996H263.486V227.933H261.053Z"
                fill="white"
            />
            <path
                d="M262.795 94.3184H262.008H261.054V97.374V134.734H262.008V174.243L263.582 171.497V134.734H263.868V94.3184H263.582H262.795Z"
                fill="white"
            />
            <path
                d="M199.112 199.953H196.679V209.55L194.89 212.677V292.912L202.594 279.472V199.237L199.112 205.301V199.953Z"
                fill="white"
            />
            <path
                d="M263.582 90.6677H263.677V87.063H263.582H262.008H261.245V90.6677H262.008H263.582Z"
                fill="white"
            />
            <path
                d="M136.144 211.675H133.711V217.046H136.144V211.675Z"
                fill="white"
            />
            <path
                d="M190.071 75.0305H192.313V45.1424H189.642V6.23065L188.569 8.09269V0H187.782V9.42954L185.277 13.8459V95.2264C185.42 94.9638 185.587 94.7012 185.683 94.5341L185.921 94.1044V156.864H188.593V89.4493L189.666 87.5873V84.3168H190.119V75.0066L190.071 75.0305Z"
                fill="white"
            />
            <path
                d="M163.239 133.47C164.336 131.584 165.41 129.722 166.459 127.908C167.008 126.929 167.58 125.974 168.105 125.043V43.7585L166.459 46.647L163.239 52.2331V23.1567H161.951V60.2065L158.708 65.8881V141.372C160.234 138.722 161.737 136.12 163.215 133.566C163.215 133.542 163.239 133.518 163.263 133.494L163.239 133.47Z"
                fill="white"
            />
            <path
                d="M168.105 169.063H166.459H163.239L163.192 174.554L158.684 166.7V246.91L166.459 260.47V222.871H168.105V263.358L168.749 264.457V184.246L168.105 183.148V169.063Z"
                fill="white"
            />
            <path
                d="M171.921 179.065H169.584V182.217H171.921V179.065Z"
                fill="white"
            />
            <path
                d="M119.543 82.7896H117.206V86.5614H119.543V82.7896Z"
                fill="white"
            />
            <path
                d="M43.3622 34.1137V33.2305H41.9073H40.6432H39.7607H39.0213H36.4453V33.4453L39.0213 37.9333L40.6432 40.7502V40.7264L41.8834 42.8987L41.9073 42.9465V42.9703L50.6846 58.2963V34.1137H48.538H43.3622Z"
                fill="white"
            />
            <path
                d="M82.4066 113.704V33.4931L82.2635 33.2305H71.8882V100.407L82.4066 118.765V172.859H84.5294V121.964L82.4066 118.287V113.704Z"
                fill="white"
            />
            <path
                d="M64.9238 34.7578V85.1282L70.5289 94.9159L70.9344 95.632V34.7578H70.5289H64.9238Z"
                fill="white"
            />
            <path
                d="M121.07 86.7046H119.544V93.1263H117.206V87.7073H114.416H113.223V7.04297H112.269V55.1694L112.221 68.2753V96.2297L110.552 93.3173V162.833L125.268 188.544V223.803H126.985V198.355H125.769V189.427L127.272 192.053V111.818L121.07 100.98V86.7046Z"
                fill="white"
            />
            <path
                d="M242.545 96.6591L238.657 89.8555V117.356L242.545 110.553V96.6591Z"
                fill="white"
            />
            <path
                d="M234.96 88.9724H234.03V76.4633L234.96 78.0867V67.6784H234.03V76.4633L224.346 59.5857V64.8614L223.106 62.6891V52.0659H222.247V61.1612L218.55 54.7396L218.24 54.1906V135.308L218.55 135.857L222.247 142.255V165.006V196.636H223.106V163.478V143.783L223.297 143.449L224.346 141.611V135.929V133.876L229.617 124.709V109.598H230.667V122.847L237.011 111.818V86.9433V81.6675L234.96 78.0867V88.9724Z"
                fill="white"
            />
            <path
                d="M229.618 139.271H230.667V122.847L229.618 124.709V139.271Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_267_3113">
                <rect width="380" height="435" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

export default VgLogo
