import styled from 'styled-components'
import { device, colors } from 'config/styled-theme'

interface CareerLinkStyleProps {
    isLastItem?: boolean
    learnMore?: boolean
    active?: boolean
    children?: React.ReactNode
}

interface DetailsProps {
    active: boolean
}

// const bgColor = '#141a32'
const careerImgOffset = 0
const transition = '0.2s ease-in-out'

export const Item = styled.div<CareerLinkStyleProps>`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background: ${({ active }) => (active ? '#fff' : '#f1f1f1')};
    padding: 20px 30px;
    height: 100%;
    transition: background ${transition};

    h3,
    svg {
        transition: ${transition};
    }

    > svg {
        margin-left: auto;
    }

    > h3 {
        display: inline-block;
        font-size: 18px;
        text-transform: uppercase;
        margin: 0;
        color: ${({ active, learnMore }) =>
            active || learnMore ? colors.academy : '#101010'};
        backface-visibility: hidden;
    }

    @media ${device.desktop} {
        gap: 0;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: ${({ learnMore }) =>
            !learnMore ? 'space-between' : 'center'};

        ${({ learnMore }) =>
            !learnMore &&
            `
        &::after {
            content: '';
            width: 1px;
            height: 40px;
            background: rgba(176,176,176, 0.4);
            position: absolute;
            right: 0;
            transition: ${transition};
        }
  `}
    }

    &:hover,
    &:focus {
        background: #fff;

        &::after {
            opacity: 0;
        }

        ${({ learnMore }) =>
            learnMore &&
            `
        h3 {
            letter-spacing: 0.015em;
        }
  `}
    }
`

export const Details = styled.div<DetailsProps>`
    z-index: 2;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    padding-right: 40px;
    transition: ${transition};
    transition-property: transform, opacity;

    @media ${device.tablet} {
        max-width: none;
    }

    @media ${device.mobile} {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 30px;
        background: #fff;
        pointer-events: none;
        opacity: ${({ active }) => (active ? 1 : 0)};
        transform: ${({ active }) =>
            active ? 'translate3d(0, -100%, 0)' : 'translate3d(0, 0, 0)'};
    }
`

export const Container = styled.div<CareerLinkStyleProps>`
    position: relative;
    display: flex;

    flex-direction: column;

    :hover {
        > div {
            &::before {
                transform: translate3d(0, 0, 0);
                opacity: 1;
                z-index: -1;
            }
        }
    }

    > div {
        /* min-width: 310px; */
        min-height: 145px;
        clip-path: polygon(
            0 0,
            0 0,
            0px 0px,
            0px 0px,
            0px 0,
            100% 0,
            100% 100%,
            0 100%
        );
        color: white;
        z-index: 1;
        background-color: #f1f1f1;
        padding: 16px;
        transition: 0.3s ease-in-out;
        position: relative;
        display: inline-block;
        // -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
        // clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
        // color: ${(props) => props.color};
        ${({ isLastItem }) =>
            isLastItem &&
            `
        margin-right: ${careerImgOffset + 33}px;
  `}

        &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            width: 110%;
            height: 102%;
            background: #fd5c5c;
            filter: brightness(1.07);
            opacity: 0;
            transform: translate3d(-100%, 0, 0);
            transition: transform 0.4s ease, opacity 0.4s ease;
            pointer-events: none;
            z-index: -1;
        }
    }

    h3 {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 500;
        line-height: 0.8;
        margin: 0;
        padding: 0;
        padding-top: 6px;
        padding-bottom: 10px;

        span {
            margin-left: 12px;
            margin-top: -4px;
            transition: 0.2s ease-in-out;
        }
    }

    img {
        position: absolute;
        top: -27px;
        right: -${careerImgOffset}px;
        ${({ isLastItem }) =>
            isLastItem &&
            `
        right: -${careerImgOffset + 14}px;
        `}
        // border: 1px solid red;
        height: 172px;
        z-index: 5;
    }

    // Applying hover effects on things inside container
    :hover {
        /* background-color: #070a16; // 20% ligheer than background-color */
        h3 {
            // color: red;
            span {
                margin-left: 16px; // Moves arrow when hovering
            }
        }
    }

    p {
        font-size: 16px;
        margin: 0;
        padding: 0;
        max-width: 225px;
        line-height: 1.5;
    }
`
