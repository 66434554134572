import gql from 'graphql-tag'

export const FRAGMENT_ARTICLE_BASIC = gql`
    fragment ArticleBasicField on Article {
        title
        slug
        publishingDate
        metaDescription
        metaImageUrl {
            url
            description
        }
    }
`

export const FRAGMENT_CONTENT_BASIC = gql`
    fragment ContentBasicFields on Content {
        headline
        buttonText
        buttonUrl
        data
    }
`

export const FRAGMENT_TAGS_FIELDS = gql`
    fragment TagsFields on Content {
        name
        headline
    }
`
