// Do we have this model somewhere else?
export interface PageLevelModel {
    page?: string
    subpage?: string
    id?: string
}

export default function getInternalLinkPath(url: string): PageLevelModel {
    if (!url) return {}

    const split = url.split('/')
    const path: PageLevelModel = {}
    if (split[0]) {
        path.page = split[0]
    }

    if (split[1]) {
        path.subpage = split[1]
    }

    if (split[2]) {
        path.id = split[2]
    }

    // console.log('path', path)
    return path
}
