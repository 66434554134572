import React from 'react'
import { Translate } from 'utils/localize'
import { TaglineSmall } from 'layouts/typography'
import Button from 'layouts/button/Button'
import s from './LoginLink.module.scss'

interface Props {
    showRecruitLogin?: boolean
}

const LoginLink = ({ showRecruitLogin }: Props): JSX.Element => {
    return (
        <>
            {showRecruitLogin ? (
                <div id="signup_to_login" className={s.alreadySection}>
                    <TaglineSmall className={s.login}>
                        <span style={{ display: 'block', marginBottom: '4px' }}>
                            <Translate id="signup.alreadyHaveAccount" />
                        </span>
                    </TaglineSmall>
                    <Button
                        size="regular"
                        className={s.btn}
                        path="/login?path=/recruit-retro"
                        theme="secondary"
                        data-id="retroRecruitLoginBtn"
                    >
                        Log in to become a recruit
                    </Button>
                </div>
            ) : (
                <div id="signup_to_login">
                    <TaglineSmall className={s.login}>
                        <Translate id="signup.alreadyHaveAccount" />{' '}
                        <a href="/login?path=/download">
                            <Translate id="signup.login" />
                        </a>
                    </TaglineSmall>
                </div>
            )}
        </>
    )
}

LoginLink.defaultProps = {
    showRecruitLogin: false,
}

export default LoginLink
