// import { FactionType } from 'types/factions'
import { KnownFactionName } from '@ccpgames/eve-web/components/warzone/types/faction'

export type FactionType = KnownFactionName

export enum FactionIcon {
    GallenteIcon,
    CaldariIcon,
    AmarrIcon,
    MinmatarIcon,
}

export interface FrontlinePage {
    faction: FactionType
    name: string
    slug: FactionType
    icon: FactionIcon
    color: string
    enemy: FactionType
    pirate: FactionType
    stats: number // Temporary until we have real start
}
