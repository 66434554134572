import React from 'react'
import { IconAccountTypeEnum } from '../models/icon-type-enum'

interface Props {
    icon: IconAccountTypeEnum
    className?: string
    iconColor?: string
}

const AccountIcon = ({ icon, className, iconColor }: Props): JSX.Element => {
    switch (icon) {
        case IconAccountTypeEnum.AccountAvatar:
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                >
                    <path
                        fill={iconColor}
                        d="m5.996 13.04-3.1 1.001.4 1.647h5.758V3.368c-3.533 0-3.063 3.973-3.063 3.973.007.199-.14.098-.172.094-.329-.024-.195.838-.116 1.207.076.37.107.488.234.819.127.33.389.28.389.28l.166 1.067c.079.499.39.788.39.788s0 .05-.068.697c-.07.648-.818.746-.818.746Z"
                    />
                    <path
                        fill={iconColor}
                        d="M9.056 3.368c3.533 0 3.063 3.973 3.063 3.973-.007.199.14.098.172.094.329-.024.195.838.116 1.208-.076.37-.107.487-.234.818-.127.33-.39.28-.39.28l-.163 1.067c-.079.499-.39.788-.39.788s0 .05.068.697c.068.648.816.749.816.749l3.1 1.001-.4 1.648H9.055V3.368Zm3.574-2.312H5.482L1.174 11.731l1.239 5.084h13.282l1.238-5.084L12.63 1.056ZM13.315.04l4.686 11.616-1.505 6.175H1.616L.111 11.655 4.797.039h8.518Z"
                    />
                </svg>
            )
        case IconAccountTypeEnum.AccountManagement:
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <g clipPath="url(#a)">
                        <path
                            fill="#777"
                            d="m15.122 6.263-1.253-.16a6.304 6.304 0 0 0-.38-.916l.774-.996a.99.99 0 0 0-.081-1.32l-1.05-1.05a.994.994 0 0 0-1.323-.084l-.996.774a6.278 6.278 0 0 0-.916-.38L9.737.88a.998.998 0 0 0-.99-.88H7.253a.998.998 0 0 0-.99.878l-.16 1.253c-.318.104-.625.23-.916.38l-.996-.774a.992.992 0 0 0-1.32.081l-1.05 1.05a.994.994 0 0 0-.084 1.323l.774.996c-.15.292-.276.598-.38.916l-1.251.16a.998.998 0 0 0-.88.99v1.494c0 .504.378.93.878.99l1.253.16c.104.318.231.624.38.916l-.774.996a.991.991 0 0 0 .081 1.32l1.05 1.05c.35.36.92.396 1.323.084l.996-.774c.292.149.598.276.916.379l.16 1.25c.06.504.486.882.99.882h1.494c.504 0 .93-.378.99-.878l.16-1.253c.318-.104.624-.231.916-.38l.996.774a.991.991 0 0 0 1.32-.081l1.05-1.05a.993.993 0 0 0 .084-1.323l-.774-.996c.15-.292.277-.598.38-.916l1.25-.16a.998.998 0 0 0 .88-.99V7.253a.997.997 0 0 0-.877-.99ZM8 11.333A3.337 3.337 0 0 1 4.667 8 3.337 3.337 0 0 1 8 4.667 3.337 3.337 0 0 1 11.333 8 3.337 3.337 0 0 1 8 11.333Z"
                        />
                    </g>
                    <defs>
                        <clipPath id="a">
                            <path fill="#fff" d="M0 0h16v16H0z" />
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconAccountTypeEnum.Recruit:
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill="#777"
                        d="m1.48 12.915.553-.225c.027-.011 2.678-1.09 3.73-1.59.114-.056.192-.435.183-.802a2.23 2.23 0 0 1-.368-.92L5.5 8.88a1.344 1.344 0 0 1-.376-.54 4.61 4.61 0 0 1-.25-.875c-.234-1.108-.032-1.534.168-1.782l.055-.062c.007-.729.156-1.964.98-2.893.338-.38.894-.814 1.76-1.006-.398-.61-1.08-1.09-2.249-1.09-3.195 0-2.77 3.592-2.77 3.592.003.089-.028.112-.063.112-.037 0-.078-.025-.094-.025h-.012c-.282 0-.164.762-.094 1.092.07.333.097.442.21.74.09.228.249.255.318.255.021 0 .034-.002.034-.002l.149.965c.07.45.35.712.35.712s.228 1.525-.66 1.947c-1.088.518-3.777 1.612-3.777 1.612v1.862h2.3v-.58h.001Z"
                        opacity=".75"
                    />
                    <path
                        fill="#777"
                        d="M15.179 15.371V13.51s-2.69-1.092-3.777-1.612c-.888-.424-.661-1.947-.661-1.947s.282-.26.351-.713l.149-.964s.012.002.034.002c.069 0 .23-.027.317-.256.114-.297.14-.406.211-.739.07-.33.188-1.092-.094-1.092h-.012c-.016.002-.057.025-.094.025-.036 0-.066-.023-.064-.112 0 0 .425-3.592-2.77-3.592S6.003 6.106 6.003 6.106c.002.088-.028.111-.063.111-.038 0-.078-.025-.094-.025h-.013c-.282 0-.163.763-.094 1.093.071.333.098.441.211.739.089.229.248.255.318.255.02 0 .033-.002.033-.002s.078.515.15.965c.07.45.35.713.35.713s.227 1.525-.661 1.947c-1.087.517-3.777 1.611-3.777 1.611v1.862H15.18v-.004Z"
                    />
                </svg>
            )
        case IconAccountTypeEnum.LogOut:
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill="#777"
                        d="M16 8c0-4.411-3.589-8-8-8S0 3.589 0 8c0 4.41 3.589 8 8 8 4.412 0 8-3.588 8-8Zm-7.214-.012a.759.759 0 1 1-1.518 0V4.039a.76.76 0 0 1 1.518 0v3.949Zm4.38.025A5.171 5.171 0 0 1 8 13.178a5.171 5.171 0 0 1-5.166-5.165A5.13 5.13 0 0 1 4.35 4.36a.756.756 0 0 1 1.074 0 .759.759 0 0 1 0 1.074 3.624 3.624 0 0 0-1.07 2.58A3.65 3.65 0 0 0 8 11.66a3.65 3.65 0 0 0 3.647-3.646c0-.974-.38-1.89-1.068-2.579a.76.76 0 0 1 1.075-1.073 5.135 5.135 0 0 1 1.512 3.652Z"
                    />
                </svg>
            )
        case IconAccountTypeEnum.Plex:
            return (
                <svg
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="16"
                    fill="none"
                    viewBox="0 0 22 16"
                >
                    <path
                        fill="url(#a)"
                        d="M2.126 0h17.748C21.048 0 22 .971 22 2.169V13.83C22 15.03 21.048 16 19.874 16H2.126C.952 16 0 15.03 0 13.831V2.17C0 .97.952 0 2.126 0Z"
                    />
                    <path
                        fill="#603813"
                        d="M18.75 8.021 22 11.941v.452l-3.426-4.131H0V7.97h18.706l.044.051Zm-.595.555H2.127L0 12.45v.597l2.295-4.179H18.02l3.971 4.898c.004-.059.009-.117.009-.177v-.27l-3.845-4.743ZM4.337 6.756.097 1.538c-.034.117-.06.239-.075.364l4.18 5.144L22 7.006v-.292l-17.663.041ZM0 7.418v.292h22V7.42H0Zm17.518-1.271H4.96L.583.672C.517.745.454.822.399.904L4.78 6.386l.043.054h12.823l4.233-4.966a2.259 2.259 0 0 0-.123-.3l-4.24 4.974Z"
                        opacity=".5"
                    />
                    <path
                        fill="#fff"
                        d="M12.89 8.31h1.745l.746-1.143h-2.718l3.306-5.272h-2.678l-2.248 3.754-2.295-3.754H6.047L9.39 7.167H7.197L6.45 8.309h2.61L5.68 13.581H8.45l2.545-4.042 2.538 4.042h2.786l-3.43-5.272Z"
                    />
                    <defs>
                        <radialGradient
                            id="a"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientTransform="matrix(-13.25913 19.18221 -36.19841 -25.02106 11.691 -2.241)"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset=".002" stopColor="#DD983C" />
                            <stop offset=".546" stopColor="#CC822F" />
                            <stop offset="1" stopColor="#C17427" />
                        </radialGradient>
                    </defs>
                </svg>
            )
        case IconAccountTypeEnum.Offer:
            return (
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_5160_8066)">
                        <path
                            d="M15.2653 8.00056L15.9673 6.16969C16.0442 5.9677 15.9843 5.73872 15.8153 5.60173L14.2934 4.36781L13.9854 2.43094C13.9514 2.21696 13.7834 2.04997 13.5694 2.01597L11.6325 1.70799L10.3996 0.185095C10.2636 0.0161068 10.0297 -0.0438891 9.83267 0.0331056L8.0008 0.736058L6.16992 0.0341055C5.96694 -0.0438891 5.73995 0.0181066 5.60296 0.186095L4.36905 1.70899L2.43218 2.01697C2.21919 2.05097 2.0512 2.21896 2.01721 2.43194L1.70923 4.36881L0.186332 5.60273C0.0183433 5.73872 -0.0426526 5.9677 0.0343422 6.16969L0.736294 8.00056L0.0343422 9.83144C-0.0436525 10.0334 0.0183433 10.2624 0.186332 10.3984L1.70923 11.6313L2.01721 13.5682C2.0512 13.7822 2.21819 13.9502 2.43218 13.9842L4.36905 14.2921L5.60296 15.814C5.73995 15.984 5.96894 16.044 6.17092 15.966L8.0008 15.2651L9.83167 15.967C9.88967 15.989 9.94967 16 10.0107 16C10.1577 16 10.3026 15.935 10.3996 15.814L11.6325 14.2921L13.5694 13.9842C13.7834 13.9502 13.9514 13.7822 13.9854 13.5682L14.2934 11.6313L15.8153 10.3984C15.9843 10.2614 16.0442 10.0334 15.9673 9.83144L15.2653 8.00056Z"
                            fill="#d50f0ff2"
                        />
                        <path
                            d="M6.4999 7.00077C5.67295 7.00077 5 6.32782 5 5.50087C5 4.67393 5.67295 4.00098 6.4999 4.00098C7.32684 4.00098 7.99979 4.67393 7.99979 5.50087C7.99979 6.32782 7.32684 7.00077 6.4999 7.00077ZM6.4999 5.00091C6.22392 5.00091 5.99993 5.22489 5.99993 5.50087C5.99993 5.77686 6.22392 6.00084 6.4999 6.00084C6.77588 6.00084 6.99986 5.77686 6.99986 5.50087C6.99986 5.22489 6.77588 5.00091 6.4999 5.00091Z"
                            fill="#FAFAFA"
                        />
                        <path
                            d="M9.4999 11.9998C8.67295 11.9998 8 11.3268 8 10.4999C8 9.67295 8.67295 9 9.4999 9C10.3268 9 10.9998 9.67295 10.9998 10.4999C10.9998 11.3268 10.3268 11.9998 9.4999 11.9998ZM9.4999 9.99993C9.22492 9.99993 8.99993 10.2249 8.99993 10.4999C8.99993 10.7749 9.22492 10.9999 9.4999 10.9999C9.77488 10.9999 9.99986 10.7749 9.99986 10.4999C9.99986 10.2249 9.77488 9.99993 9.4999 9.99993Z"
                            fill="#FAFAFA"
                        />
                        <path
                            d="M5.5005 12C5.3995 12 5.29851 11.97 5.21052 11.907C4.98553 11.746 4.93354 11.434 5.09452 11.2091L10.0942 4.20954C10.2552 3.98456 10.5672 3.93256 10.7921 4.09355C11.0171 4.25354 11.0681 4.56652 10.9081 4.7905L5.90847 11.79C5.80948 11.927 5.65649 12 5.5005 12Z"
                            fill="#FAFAFA"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_5160_8066">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )
        case IconAccountTypeEnum.Details:
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8 12.61a.781.781 0 1 0 0-1.563.781.781 0 0 0 0 1.562Z"
                        fill="#5ccbcb"
                    />
                    <path
                        d="M8 0C3.579 0 0 3.578 0 8c0 4.421 3.578 8 8 8 4.421 0 8-3.578 8-8 0-4.421-3.578-8-8-8Zm0 14.75A6.746 6.746 0 0 1 1.25 8c0-3.73 3.019-6.75 6.75-6.75 3.73 0 6.75 3.019 6.75 6.75 0 3.73-3.019 6.75-6.75 6.75Z"
                        fill="#5ccbcb"
                    />
                    <path
                        d="M8 4.016a2.503 2.503 0 0 0-2.5 2.5.625.625 0 1 0 1.25 0c0-.69.56-1.25 1.25-1.25s1.25.56 1.25 1.25c0 .689-.56 1.25-1.25 1.25a.625.625 0 0 0-.625.625v1.562a.625.625 0 1 0 1.25 0V8.937A2.504 2.504 0 0 0 10.5 6.516c0-1.379-1.121-2.5-2.5-2.5Z"
                        fill="#5ccbcb"
                    />
                </svg>
            )
        default:
            return <></>
    }
}

AccountIcon.defaultProps = {
    className: '',
    iconColor: '#30B2E6',
}

export { IconAccountTypeEnum }

export default AccountIcon
