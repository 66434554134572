import React from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import { isClient } from 'config/web'
import PageType from 'models/types/ts/pageType'
import SectionType from 'models/types/ts/sectionType'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import { Container } from 'layouts'
import ClientOnly, { useHasMounted } from 'hooks/client-only'
import Overview from './components/overview'
import Single from './components/single'
import style from './policies.module.scss'

// const LoadableOverview: React.LazyExoticComponent<typeof Overview> = React.lazy(
//     () =>
//         import(
//             /* webpackChunkName: "component-overview" */ './components/overview'
//         )
// )

export default function PoliciesContainer(): JSX.Element {
    const pageSlug = 'policies'
    const location = useSelector((state: GlobalState) => state.location)
    const language = useSelector((state: GlobalState) => state.language)

    const policySlug = location && location.payload && location.payload.subpage

    const hasMounted = useHasMounted()
    // console.log('policy Slug', policySlug)
    // console.log('policy subpage', subpage)

    // if (policySlug) {
    //     // We are on a subpage so need to display single policy.
    //     console.log('SINGLE POLICY')
    // } else {
    //     console.log('POLICY OVERVIEW')
    // }

    return (
        <Container>
            <div className={style.policies}>
                <PageBySlugQuery slug={pageSlug} locale={language}>
                    {(page: PageType) => {
                        console.log('waaaat', page)
                        if (page) {
                            if (policySlug) {
                                const policy = page.sectionsCollection.items.find(
                                    (section: SectionType) =>
                                        section.identifier === policySlug
                                )
                                return <Single policy={policy} />
                            }
                            return (
                                <>
                                    <h3>OverView</h3>
                                    <ClientOnly>
                                        <div>Is client with ClientOnly</div>
                                    </ClientOnly>
                                    <Overview
                                        policies={page.sectionsCollection.items}
                                    />
                                    {/* isClient like this is a no no for ssr and hydrate mismatch */}
                                    {/* {isClient && <div>Is client</div>} */}
                                    {hasMounted && (
                                        <div>Is client with hasMounted</div>
                                    )}
                                </>
                            )
                        }
                        return (
                            <div>
                                <h1>Not found</h1>
                            </div>
                        )
                    }}
                </PageBySlugQuery>
            </div>
        </Container>
    )
}
