import React from 'react'
import { ImageLightbox } from './components'
import { AssetContentType, RichTextAssetType } from './types'

const isImage = (contentType: AssetContentType): boolean => {
    if (contentType === 'image/jpeg' || contentType === 'image/png') {
        return true
    }
}

const isVideo = (contentType: AssetContentType): boolean => {
    if (contentType === 'video/mp4' || contentType === 'video/webm') {
        return true
    }
}

const isAudio = (contentType: AssetContentType): boolean => {
    if (
        contentType === 'audio/mp3' ||
        contentType === 'audio/ogg' ||
        contentType === 'audio/wav' ||
        contentType === 'audio/x-wav'
    ) {
        return true
    }
}

export const renderEmbeddedAssets = (asset: RichTextAssetType): JSX.Element => {
    const { contentType } = asset

    // Video handling
    if (isVideo(contentType)) {
        return (
            <video width="100%" controls>
                <source src={asset.url} type={contentType} />
            </video>
        )
    }

    // Audio handling
    if (isAudio(contentType)) {
        return <audio src={asset.url} controls />
    }

    // Image handling
    if (isImage(contentType)) {
        return <ImageLightbox image={asset} />
    }

    // Should we default handle embedded assets?
    // Maybe just return a link to the asset?
    return <></>
}
