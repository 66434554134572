import React, { ReactNode } from 'react'
import { Container } from 'layouts/common'
import style from './AlertMessage.module.scss'

interface Props {
    children: ReactNode
    icon: ReactNode
    container?: boolean
    error?: boolean
    warning?: boolean
    success?: boolean
    info?: boolean
    className?: string
}

const AlertMessageV2 = ({
    children,
    icon,
    container,
    error,
    warning,
    success,
    info,
    className,
}: Props): JSX.Element => {
    const combinedClassName = style('alert', className, {
        error: error || (!warning && !success && !info),
        warning,
        success,
        info,
    })

    const alertBox = (
        <div className={combinedClassName}>
            <div className={style.icon}>{icon}</div>
            <div>{children}</div>
        </div>
    )

    if (container) return <Container>{alertBox}</Container>

    return alertBox
}

export default AlertMessageV2
