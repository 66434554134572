import * as Yup from 'yup'
import { SchemaOf } from 'yup'
import { TranslateFunction } from 'utils/localize'

interface ValidationSchema {
    email: string
}

const AgeVerificationSchema = (
    translate: TranslateFunction
): SchemaOf<ValidationSchema> => {
    return Yup.object().shape({
        email: Yup.string()
            .email(translate('signup.validation.emailInvalid').toString())
            .required(translate('signup.validation.emailNotEmpty').toString()),
    })
}

export default AgeVerificationSchema
