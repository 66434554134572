import styled from 'styled-components'
import imageGallery from '.'

interface ImageGalleryStyleProps {
    columnCount: number
    children?: React.ReactNode
}

const ImageGalleryStyle = styled.span<ImageGalleryStyleProps>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.columnCount}, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;

    img {
        cursor: pointer;
    }
`

ImageGalleryStyle.defaultProps = {
    columnCount: 3,
}

export default ImageGalleryStyle
