import { Middleware } from 'redux'
import { setActiveLanguage } from 'utils/localize'
import { LANGUAGE_CHANGE } from 'lib/language/actions'
import { getLanguage, getLanguages } from 'lib/language/selectors'

interface LanguageAction {
    type: typeof LANGUAGE_CHANGE
    payload: string
}

interface InitializeAction {
    type: '@@localize/INITIALIZE'
}

type MyAction = LanguageAction | InitializeAction // Include other action types as needed

const localizationMiddleware: Middleware = (store) => {
    let initialized = false
    return (next) => (action: MyAction) => {
        const result = next(action)
        switch (action.type) {
            case LANGUAGE_CHANGE: {
                if (
                    initialized &&
                    getLanguages().indexOf(action.payload) > -1
                ) {
                    store.dispatch(setActiveLanguage(action.payload))
                }
                break
            }

            case '@@localize/INITIALIZE': {
                initialized = true
                store.dispatch(setActiveLanguage(getLanguage(store.getState())))
                break
            }

            default:
                break
        }

        return result
    }
}

export default localizationMiddleware
