import React from 'react'
import { TranslateFunction } from 'react-localize-redux'
import s from './UnlinkTwitchButton.module.scss'
import { ConfirmIcon, CloseIcon } from './Icons'

interface UnlinkTwitchButtonProps {
    unlinkFn: () => void
    translate: TranslateFunction
}

const UnlinkTwitchButton = ({
    unlinkFn,
    translate,
}: UnlinkTwitchButtonProps): JSX.Element => {
    const [showConfirmation, setShowConfirmation] = React.useState(false)

    // const [confirmed, setConfirmed] = React.useState(false)

    function userConfirms(): void {
        // setConfirmed(true)
        setShowConfirmation(false)
        unlinkFn()
    }

    if (!showConfirmation) {
        return (
            <button
                type="button"
                className={s.unlinkButton}
                onClick={() => setShowConfirmation(true)}
            >
                {translate('TwitchLinking.UnlinkAccount').toString()}
            </button>
        )
    }
    if (showConfirmation)
        return (
            <div className={s.unlinkButton}>
                {translate('TwitchLinking.UnlinkAccount').toString()}
                {'. '}
                {translate('TwitchLinking.AreYouSure').toString()}
                <div className={s.confirmation}>
                    <div
                        className={s.air}
                        title="Confirm"
                        onClick={() => userConfirms()}
                    >
                        <ConfirmIcon />
                    </div>
                    <div
                        className={s.air}
                        title="Close"
                        onClick={() => setShowConfirmation(false)}
                    >
                        <CloseIcon />
                    </div>
                </div>
            </div>
        )

    return <></>
}

export default UnlinkTwitchButton
