import React from 'react'
import { useSelector } from 'react-redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { findSectionByIdentifier } from 'lib/pages/api'
import { useHasMounted } from 'hooks/client-only'
import PageType from 'models/types/ts/pageType'
import { GlobalState } from 'types'

const LoadableComponent = React.lazy(
    () => import(/* webpackChunkName: "eve-monument" */ './Monument')
)

export default function EveMonument(): JSX.Element {
    const language = useSelector((state: GlobalState) => state.language)
    const hasMounted = useHasMounted()

    const slug = 'eve-monument'

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                if (page) {
                    const sections = page.sectionsCollection.items

                    const hero = findSectionByIdentifier(
                        sections,
                        'eve-monument-hero'
                    )

                    const about = findSectionByIdentifier(
                        sections,
                        'eve-monument-about'
                    )

                    const search = findSectionByIdentifier(
                        sections,
                        'eve-monument-search'
                    )

                    return (
                        <PageWithSeo page={page} showLoading={false}>
                            {hasMounted && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableComponent
                                        hero={hero}
                                        hasContent
                                        about={about}
                                        search={search}
                                        // features={features}
                                        // bottom={bottom}
                                    />
                                </React.Suspense>
                            )}
                        </PageWithSeo>
                    )
                }
            }}
        </PageBySlugQuery>
    )
}
