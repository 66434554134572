import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
// import rehypeSanitize from 'rehype-sanitize' // If we use this like we should, then add to rehypePlugins. There is broken html in content now, example, &nbsp without a ; after it
import remarkGfm from 'remark-gfm'
import Props from './markdown-props'

export default function CustomMarkdown({
    source,
    escapeHtml,
    className,
    allowedTypes,
    disallowedTypes,
    renderers,
    unwrapDisallowed,
}: Props): JSX.Element {
    // If false then render with html, else not to keep all things the same

    // This will make sure that p is rendered as p and helps Gooogle Translate not breaking when switching between languages
    // Bit hacky but works for some reason or I'm going mad
    if (renderers === undefined) {
        renderers = {
            p: ({ children }) => <p>{children}</p>,
        }
    }

    if (escapeHtml === false) {
        return (
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]} // Only for rendering html if that is wanted, we should try and skip that since it is unsafe and the rehype-raw package is large and it defeats the purpose of markdown
                skipHtml={escapeHtml}
                className={className}
                allowedElements={allowedTypes}
                disallowedElements={disallowedTypes}
                components={renderers}
                unwrapDisallowed={unwrapDisallowed}
            >
                {source}
            </ReactMarkdown>
        )
    }

    // We can consider returning all with html support which is with the rehype plugin above, then escapeHtml property would be obsolete for us.
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            skipHtml={escapeHtml}
            className={className}
            allowedElements={allowedTypes}
            disallowedElements={disallowedTypes}
            components={renderers}
            unwrapDisallowed={unwrapDisallowed}
        >
            {source}
        </ReactMarkdown>
    )
}
