import React from 'react'
import { Button, Target } from '@ccpgames/eve-web/components'

interface Props {
    children: React.ReactNode
    url?: string
    color?: string
    backgroundColor?: string
}
export const ButtonWithTarget = ({
    children,
    url,
    color,
    backgroundColor,
}: Props): JSX.Element => {
    return (
        <Target color={backgroundColor}>
            <Button
                backgroundColor={backgroundColor}
                href={url}
                style={{
                    color,
                }}
            >
                {children}
            </Button>
        </Target>
    )
}

export const TransparentButtonWithTarget = ({
    children,
    url,
    color,
    backgroundColor,
}: Props): JSX.Element => {
    return (
        <Target color={color}>
            <Button
                backgroundColor={backgroundColor}
                href={url}
                style={{
                    backgroundColor: 'transparent',
                    border: `1px solid ${color}`,
                    color: 'white',
                }}
            >
                {children}
            </Button>
        </Target>
    )
}

ButtonWithTarget.defaultProps = {
    url: undefined,
    color: 'black',
    backgroundColor: '#5CCBCB',
}

TransparentButtonWithTarget.defaultProps = {
    url: undefined,
    color: 'white',
    backgroundColor: 'transparent',
}
