import { getLoggedInJwt, isLoggedIn } from 'packages/authentication'
import React, { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getPlexBalance, getSpecialOfferCount } from 'services/api/vgs'
import isOmega from 'services/api/customer/isOmega'
import UserContextModel from 'models/UserContextModel'
import { getCountry } from 'services/api/location/country'
import getHashedUserId from 'services/api/customer/hashedUserId'
import Analytics from 'utils/analytics'
import { GlobalState } from 'types'

const UserContextDefaultValues: UserContextModel = {
    country: '',
    plexBalance: 0,
    specialOfferCount: 0,
    isOmegaUser: false,
    hashedUserId: '',
    updatePlex: () => null,
    updatePlexDev: () => null,
}

export const UserContext = createContext<UserContextModel>(
    UserContextDefaultValues
)

export function UserProvider({
    children,
}: {
    children: React.ReactNode
}): JSX.Element {
    const userToken = useSelector((state: GlobalState) => getLoggedInJwt(state))
    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))
    const [plexBalance, setPlexBalance] = useState<number>(0)
    const [specialOfferCount, setSpecialOfferCount] = useState<number>(0)
    const [isOmegaUser, setIsOmegaUser] = useState<boolean>(false)
    const [hashedUserId, setHashedUserId] = useState<string>('')
    const [country, setCountry] = useState<string>(null)

    function useUserContextValue(): UserContextModel {
        async function updatePlex(token: string): Promise<void> {
            //  setPlexBalance(await getPlexBalance(userToken))
            // ONLY FOR TESTING - just subtracting 5 plex from user
            setPlexBalance(await getPlexBalance(token))
        }
        async function updatePlexDev(amount: number): Promise<void> {
            //  setPlexBalance(await getPlexBalance(userToken))
            // ONLY FOR TESTING - just subtracting 5 plex from user
            setPlexBalance(amount)
        }

        return {
            plexBalance,
            specialOfferCount,
            isOmegaUser,
            country,
            hashedUserId,
            updatePlex,
            updatePlexDev,
        }
    }

    const UserContextValue = useUserContextValue()

    useEffect(() => {
        const fetchCountry = async (): Promise<void> => {
            setCountry(await getCountry())
        }

        fetchCountry()
    }, [])

    useEffect(() => {
        const fetchInfo = async (): Promise<void> => {
            setPlexBalance(await getPlexBalance(userToken))
            setSpecialOfferCount(await getSpecialOfferCount(userToken))
            setIsOmegaUser(await isOmega(userToken))
            if (!hashedUserId) {
                getHashedUserId(userToken).then((hashedId) => {
                    if (hashedId) {
                        setHashedUserId(hashedId)
                        Analytics.PushEventObject({ UserID: hashedId })
                    }
                })
            }
        }

        if (loggedIn && userToken) {
            fetchInfo()
        }
    }, [userToken])

    return (
        <UserContext.Provider value={UserContextValue}>
            {children}
        </UserContext.Provider>
    )
}
