export const YT_DOMAIN = 'youtube.com'
export const YT_DOMAIN2 = 'youtu.be'
export const TWITCH_DOMAIN = 'twitch.tv'
export const CDN_VIDEO_EMBED_URL =
    'https://web.ccpgamescdn.com/aws/eveonline/videos'
export const CDN_AUDIO_EMBED_URL =
    'https://web.ccpgamescdn.com/aws/eveonline/audio'

export const YT_REGEXP = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
export const YT_ID_LENGTH = 11
