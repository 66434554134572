import { GlobalState } from "types"

export const isLoggedIn = (state: GlobalState): boolean => {
    const loggedIn = state.auth ? state.auth.isLoggedIn : false;
    const expires = state.auth && state.auth.expiresAt
    if (loggedIn && (+new Date() > +expires)) {
        return false;
    }
    return loggedIn
}
export const getLoggedInUser = (state: GlobalState): string => state.auth && state.auth.username
export const getLoggedInJwt = (state: GlobalState): string => state.auth && state.auth.token
