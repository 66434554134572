import React from 'react'
import { Target } from "@ccpgames/eve-web/components"
import Countdown, { CountdownTimeDelta, zeroPad } from "react-countdown"
import style from './CountDown.module.scss'


interface Props {
    title: string;
    date: string;
    onComplete?: ()=> void
}

const renderer = (title: string, onComplete?: ()=> void)=> ({
    days,
    hours,
    minutes,
    seconds,
    completed
  }: CountdownTimeDelta): JSX.Element => {
    if (completed && onComplete) {
        onComplete()
    }
    return (
      <div className={completed ? style.completed : ''}>
        <div className={style.title}>{title}</div>
        <Target style={{cursor: 'unset'}}>
            <div className={style.countdownContainer}>
            <div className={style.timeUnit}>
                <span className={style.number}>{zeroPad(days)}</span>
                <span className={style.label}>Days</span>
            </div>
            <div className={style.timeUnit}>
                <span className={style.number}>{zeroPad(hours)}</span>
                <span className={style.label}>Hours</span>
            </div>
            <div className={style.timeUnit}>
                <span className={style.number}>{zeroPad(minutes)}</span>
                <span className={style.label}>Minutes</span>
            </div>
            <div className={style.timeUnit}>
                <span className={style.number}>{zeroPad(seconds)}</span>
                <span className={style.label}>Seconds</span>
            </div>
            </div>
        </Target>
      </div>
    )
  }


export const CountDown = ({title, date, onComplete}: Props): JSX.Element => <Countdown date={new Date(date)} renderer={renderer(title, onComplete)} />