import React from 'react'
import { Document } from '@contentful/rich-text-types'
import {
    CallToActionButton,
    ImageGallery,
    StatusLabel,
    TableOfContents,
    MediaEmbed,
    NewsEmbed,
    NewsEmbedInline,
    Tabs,
} from './components'
import {
    BasicEntryType,
    ButtonEntryType,
    ContentEntryType,
    DataConfigTempType,
    ImageGalleryEntryType,
    LabelEntryType,
    NewsEntryType,
    MediaEmbedEntryType,
    TabsEntryType,
} from './types'

const renderContentEntry = (
    entry: ContentEntryType,
    document: Document
): JSX.Element => {
    const data = entry.data as DataConfigTempType

    if (data?.type === 'table-of-contents') {
        return <TableOfContents body={document} />
    }

    return <></>
    // TODO render stuff as is, look at later or remove
}

export const renderEmbeddedInlineEntry = (
    entry: BasicEntryType,
    document: Document
): JSX.Element => {
    if (entry.__typename === 'Label') {
        const labelEntry = entry as LabelEntryType

        if (!labelEntry) return null

        return (
            <StatusLabel
                color={labelEntry.color}
                backgroundColor={labelEntry.backgroundColor}
            >
                {labelEntry.text}
            </StatusLabel>
        )
    }

    // This is a new content type, we need to cast it to the correct type
    if (entry.__typename === 'Content') {
        const contentEntry = entry as ContentEntryType
        return renderContentEntry(contentEntry, document)
    }

    if (entry.__typename === 'Article') {
        return <NewsEmbedInline content={entry as NewsEntryType} />
    }
}

export const renderEmbeddedBlockEntry = (
    entry: BasicEntryType,
    document: Document
): JSX.Element => {
    // This is a new content type, we need to cast it to the correct type
    if (entry.__typename === 'MediaEmbed') {
        const videoEntry = entry as MediaEmbedEntryType

        return (
            <MediaEmbed
                mediaUrl={videoEntry?.mediaUrl}
                type={videoEntry?.type}
                title={videoEntry?.title}
                thumbnail={videoEntry?.thumbnail}
                loopVideo={videoEntry?.loopVideo}
            />
        )
    }

    if (entry.__typename === 'ImageGallery') {
        return <ImageGallery content={entry as ImageGalleryEntryType} />
    }

    if (entry.__typename === 'Button') {
        const buttonEntry = entry as ButtonEntryType

        return (
            <CallToActionButton
                url={buttonEntry.url}
                color={buttonEntry.color}
                backgroundColor={buttonEntry.backgroundColor}
            >
                {buttonEntry.text}
            </CallToActionButton>
        )
    }

    if (entry.__typename === 'Content') {
        return renderContentEntry(entry as ContentEntryType, document)
    }

    // Different render for articles, block renders a preview card
    if (entry.__typename === 'Article') {
        return <NewsEmbed content={entry as NewsEntryType} />
    }

    if (entry.__typename === 'Tabs') {
        const tabEntry = entry as TabsEntryType
        const tabs = tabEntry?.tabsCollection?.items

        return tabs ? <Tabs tabs={tabs} /> : <></>
    }

    // Default of nothing else found, maybe should not render anything?
    return <p>rendering {entry.__typename} type is not implemented</p>
}
