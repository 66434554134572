import pageActions from './lib/pages/pageActions'

const routesMap = {
    ERROR: {
        path: '/error',
    },
    PAGE: {
        path: '/:lang(|en|de|fr|ru|ja|ko|es|zh)?/:page?/:subpage?/:id?/:subid?',
        thunk: pageActions,
        // REMOVING BELOW: Not used anymore, only in EVE ANYWHERE
        // confirmLeave: (state: GlobalState): string => {
        //     const { location, stream } = state
        //     if (location.payload.page === 'anywhere' && stream?.active) {
        //         return 'If you leave, you may lose any unsaved progress'
        //     }
        // },
    },
}

export default routesMap
