import styled, { css } from 'styled-components'
import { mobileSmall } from 'styles/media-queries'

interface CustomFactionWarzoneProgressRoundStyleProps {
    borderColor1: string
    borderColor2: string
    children?: React.ReactNode
}

const CustomFactionWarzoneProgressRoundStyles = styled.div<CustomFactionWarzoneProgressRoundStyleProps>`
    min-height: 185px;
    margin: 20px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
        width: 100%;
        text-align: center;
    }

    span {
        font-weight: 500;
        font-size: 18px;
        min-width: 40px;
        pointer-events: none;

        ${mobileSmall.up(css`
            /* min-width: 70px; */
            font-size: 20px;
        `)};
    }

    ${mobileSmall.down(css`
        > span {
            margin: 0 !important;
        }
    `)};

    .numberContent {
        background-color: black;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .color1 {
        border: 1px solid ${(props) => props.borderColor1 || 'white'};
    }

    .color2 {
        border: 1px solid ${(props) => props.borderColor2 || 'white'};
    }
`

// CustomFactionWarzoneProgressRoundStyles.defaultProps = {
//     color: 'white',
// }

export default CustomFactionWarzoneProgressRoundStyles
