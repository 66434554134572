import React, { useRef } from 'react'
import Slider, { Settings } from 'react-slick'
import ContentType from 'models/types/ts/contentType'
import { ParagraphLarge } from 'layouts/typography'
import style from './Quotes.module.scss'

/* eslint-disable react/jsx-props-no-spreading */

interface Props {
    content: ContentType[]
    className?: string
}

const INTERVAL = 5000

const Quotes = ({ content }: Props): JSX.Element => {
    const sliderRef = useRef(null)

    const settings: Settings = {
        slidesToShow: 1,
        arrows: false,
        lazyLoad: 'progressive',
        autoplay: true,
        autoplaySpeed: INTERVAL,
        speed: 200,
        fade: true,
        infinite: true,
        pauseOnHover: false,
        dots: false,
        dotsClass: style.dots,
    }

    const quotes = content.map((quote) => (
        <div className={style.quote} key={quote.name}>
            <ParagraphLarge>{quote.body}</ParagraphLarge>
            <div className={style.imgContainer}>
                {quote.imageFile && (
                    <img
                        src={quote.imageFile.url}
                        alt=""
                        width={quote.imageFile.width}
                        height={quote.imageFile.height}
                    />
                )}
            </div>
        </div>
    ))

    return (
        <div className={style.quotesWrapper}>
            <Slider ref={sliderRef} className={style.quotes} {...settings}>
                {quotes}
            </Slider>
        </div>
    )
}

Quotes.defaultProps = {
    className: '',
}

export default Quotes
