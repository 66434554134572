import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle'
import { faChevronDoubleDown } from '@fortawesome/pro-regular-svg-icons/faChevronDoubleDown'
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons/faEllipsisH'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown'
import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt'
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark'
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars'

// below is used on /omega advantages section and name id from Contentful
import { faTachometer } from '@fortawesome/pro-regular-svg-icons/faTachometer'
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey'
import { faRocket } from '@fortawesome/pro-regular-svg-icons/faRocket'
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons/faLayerGroup' // TODO: Can be removed but double check - Not used anymore in Contentful but entry exists

export default [
    faTachometer,
    faLayerGroup,
    faKey,
    faRocket,
    faChevronRight,
    faCircleXmark,
    faLink,
    faCheckCircle,
    faChevronDoubleDown,
    faEllipsisH,
    faAngleDown,
    faExternalLinkAlt,
    faBars
]
