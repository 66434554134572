import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { Visibility } from 'layouts'
import Picture from 'features/picture'
import style from './ImageContent.scss'

// CODE_IMPROVEMENTS
// TODO TO .tsx or delete if not used
const ImageContent = ({ children, image, className, direction }) => {
    const renderMedia = (file) => {
        const fileType = file.split('.').pop()

        if (fileType === 'mp4' || fileType === 'webm') {
            return (
                <div className={style.video}>
                    <video playsInline autoPlay muted loop>
                        <source src={file} type={`video/${fileType}`} />
                    </video>
                </div>
            )
        }

        return <Picture widthPercentage={66} src={file} alt="" />
    }

    return (
        <Visibility
            className={style(style.content, style[direction], className)}
            direction={direction}
            transitionDelay="0.5s"
        >
            {image && <LazyLoad>{renderMedia(image)}</LazyLoad>}
            <div className={style.inner}>{children}</div>
        </Visibility>
    )
}

ImageContent.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['fadeLeft', 'fadeRight']),
}

ImageContent.defaultProps = {
    direction: 'fadeLeft',
}

export default ImageContent
