import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Tabs as TabsContainer } from '@ccpgames/eve-web/components/tabs/Tabs'
import { TabList } from '@ccpgames/eve-web/components/tabs/TabList'
import { Tab } from '@ccpgames/eve-web/components/tabs/Tab'
import { TabPanel } from '@ccpgames/eve-web/components/tabs/TabPanel'
import getRenderOptions from '../../getRenderOptions'
import { TabType } from '../../types'

interface Props {
    tabs: TabType[]
}

const Tabs = ({ tabs }: Props): JSX.Element => {
    return (
        <TabsContainer>
            <TabList>
                {tabs.map((tab, index) => (
                    <Tab key={tab.title} value={index}>
                        {tab.title}
                    </Tab>
                ))}
            </TabList>
            {tabs.map((tab, index) => {
                return (
                    <TabPanel key={tab.title} value={index}>
                        {tab.content ? documentToReactComponents(tab.content.json, getRenderOptions(tab.content)) : null}
                    </TabPanel>
                )
            })}
        </TabsContainer>
    )
}

export default Tabs
