import React from 'react'
import { useSelector } from 'react-redux'
import { Translate, TranslateFunction } from 'utils/localize'
import { GlobalState } from 'types/redux'
import PageType from 'models/types/ts/pageType'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { useHasMounted } from 'hooks/client-only'

const LoadableComponent = React.lazy(
    () => import(/* webpackChunkName: "page-signup" */ './Signup')
)

interface PageMeta extends Partial<PageType> {
    robots?: string
}

function renderPageWithSeo(
    translate: TranslateFunction,
    invc: string | null,
    hasMounted: boolean
): JSX.Element {
    // General Signup or Recruit Signup

    let page: PageMeta = {
        metaTitle: translate('signup.metaTitleGeneral').toString(),
        metaDescription: translate('signup.metaDescriptionGeneral').toString(),
        metaImage: {
            url:
                'https://web.ccpgamescdn.com/secure/images/2018/SocialShare/signup-meta-image.jpg',
        },
    }

    if (invc) {
        page = {
            metaTitle: translate('signup.metaTitle').toString(),
            metaDescription: translate('signup.meta').toString(),
            metaImage: {
                url:
                    'https://web.ccpgamescdn.com/aws/eveonline/images/recruit-signup-meta.jpg',
            },
            robots: 'noindex',
        }
    }

    return (
        <PageWithSeo page={page} showLoading={false} hideSitename>
            {hasMounted && (
                <React.Suspense fallback={<Loading />}>
                    <LoadableComponent />
                </React.Suspense>
            )}
        </PageWithSeo>
    )
}

export default function LoadableSignup(): JSX.Element {
    const location = useSelector((state: GlobalState) => state.location)
    const invc = location.query?.invc || ''

    const hasMounted = useHasMounted()

    return (
        <Translate>
            {({ translate }) => renderPageWithSeo(translate, invc, hasMounted)}
        </Translate>
    )
}
