import styled, { css } from 'styled-components'
import { mobile } from 'styles/media-queries'

interface StatsStyleProps {
    children?: React.ReactNode
}

const StatsStyles = styled.div<StatsStyleProps>`
    padding-top: 120px;
    width: 100%;

    ${mobile.down(css`
        padding-top: 30px;
    `)};

    .heading {
    }

    p {
        color: #b0b0b0;
    }

    .main {
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 30px;
        .top {
            .text {
                margin: 0 10px;
                color: white;
                font-size: 22px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }

        .line {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .inner {
                z-index: 1;
                position: absolute;
                top: 10px;
                width: 2px;
                height: 45px;
                background-color: #d9d9d9;
            }
        }

        .factions {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5px;

            span {
                font-weight: 500;
                font-size: 22px;
                gap: 5px;

                &.vs {
                    font-size: 400;
                }
            }
        }
    }

    .info {
        margin: 40px 0 0;
        text-align: left;
        .title {
            display: flex;
            text-transform: uppercase;
            font-size: 18px;
            svg {
                margin-right: 10px;
                margin-top: 5px;
            }
        }

        .text {
            font-size: 18px;
            margin: 8px 0;
            color: white;
            font-weight: 400;
        }
    }
`

export default StatsStyles
