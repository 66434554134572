const values = {
    seo: {
        title: 'EVE Online',
        description:
            'Player-created empires, player-driven markets, and endless ways to embark on your personal sci-fi adventure.',
        image:
            'https://web.ccpgamescdn.com/aws/eveonline/images/www-meta-image.jpg',
        contentType: 'game',
    },
}

export default values
