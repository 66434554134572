import React, { useEffect, useState } from 'react'
import {
    // Button,
    Target,
    FactionNameToId,
    useWarzoneLeaderboardStats,
    WarzoneLeaderboardCharacter,
    WarzoneLeaderboardCharacterPanel,
    WarzoneLeaderboardScoreType,
    WarzoneLeaderboardStatsGroup,
    WarzoneLeaderboardCharacterPanelProps,
} from '@ccpgames/eve-web/components'
import { Button, Visibility } from 'layouts'
import { ClipLoader } from 'react-spinners'
import {
    getLoggedInJwt,
    isLoggedIn,
} from 'packages/authentication/lib/selectors'

import { useSelector } from 'react-redux'
import { getPathname } from 'lib/location/selectors'
import { getLanguage } from 'selectors'
import { getWarzonePersonalLeaderboard } from 'pages/frontlines/helpers/get-warzone-leaderboard'
import { ButtonWithTarget } from 'layouts/button-with-target'
import { FactionType } from 'pages/frontlines/models/frontlines-types'
import { Translate } from 'react-localize-redux'
import {
    HeadingMedium,
    HeadingSmall,
    ParagraphLarge,
    ParagraphRegular,
} from 'layouts/typography'
import { Center } from '@mantine/core'
import { GlobalState } from 'types'
import { usePlaceholderCharacters } from '../../helpers/placeholder-characters'
import LeaderboardsStyles from './Leaderboards.style'

type PickedProps = Pick<
    WarzoneLeaderboardCharacterPanelProps,
    'showRank' | 'showEnlisted' | 'focusStat' | 'showTooltips' | 'colorEnlisted'
>
interface ListProps extends PickedProps {
    loading: boolean
    color: string
    count?: number
    list?: WarzoneLeaderboardCharacter[]
    showRankNumber?: boolean
    faction?: WarzoneLeaderboardCharacterPanelProps['factionTheme']
}

const LeaderboardList = ({
    loading,
    color,
    list,
    count,
    showRankNumber = false,
    faction = 'noFaction',
    ...rest
}: ListProps): JSX.Element => {
    if (loading || !list)
        return (
            <div className="loading">
                <ClipLoader size={50} color={color} loading />
            </div>
        )
    const listToRender = count ? list.slice(0, count) : list
    return (
        <Visibility direction="fadeIn" transitionDelay="0.5s">
            {listToRender.map(
                (character: WarzoneLeaderboardCharacter, index: number) => (
                    <div className="characterPanel" key={character.id}>
                        <WarzoneLeaderboardCharacterPanel
                            rank={showRankNumber ? index + 1 : undefined}
                            showCrown
                            factionTheme={faction}
                            character={character}
                            className="leaderboard-character-panel"
                            statsGroup="LastWeek"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                        />
                    </div>
                )
            )}
        </Visibility>
    )
}

interface Props {
    color?: string
    group?: WarzoneLeaderboardStatsGroup
    faction?: FactionType
    count?: number
    showAllFactionIcons?: boolean
    colorEnlisted?: boolean
}

const Leaderboards = ({
    color,
    group,
    faction,
    count,
    showAllFactionIcons,
    colorEnlisted,
}: Props): JSX.Element => {
    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))
    const userToken = useSelector((state: GlobalState) => getLoggedInJwt(state))
    const language = useSelector((state: GlobalState) => getLanguage(state))
    const pathname = useSelector((state: GlobalState) => getPathname(state))
    const placeholders = usePlaceholderCharacters()
    const factionId = faction === undefined ? 0 : FactionNameToId(faction)
    const { kills, victoryPoints, loading } = useWarzoneLeaderboardStats(
        factionId,
        group
    )
    const [personalLeaderboardData, setPersonalLeaderboardData] = useState<
        WarzoneLeaderboardCharacter[]
    >(null)

    const loginUrl = `/login?path=${pathname}`
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const data = await getWarzonePersonalLeaderboard(userToken)
            if (data) {
                setPersonalLeaderboardData(data)
            }
        }
        if (loggedIn) {
            fetchData()
        }
    }, [loggedIn])

    return (
        <LeaderboardsStyles color={color}>
            <Translate>
                {({ translate }) => (
                    <div className="leaderboards">
                        <Center
                            style={{
                                paddingTop: '40px',
                                paddingBottom: '40px',
                            }}
                        >
                            <ParagraphLarge>
                                <Translate id="warzoneLeaderBoards.introText" />
                            </ParagraphLarge>
                        </Center>
                        <div className="lists">
                            <section className="kills">
                                <h4>
                                    {translate('warzoneLeaderBoards.mostKills')}
                                    <span>
                                        {translate(
                                            'warzoneLeaderBoards.inThePast7Days'
                                        )}
                                    </span>
                                </h4>
                                <LeaderboardList
                                    loading={loading}
                                    color={color}
                                    list={kills}
                                    count={count}
                                    showRankNumber
                                    focusStat="kills"
                                    faction={faction}
                                    showEnlisted={showAllFactionIcons}
                                    colorEnlisted={colorEnlisted}
                                />
                            </section>
                            <section className="points">
                                <h4>
                                    {translate(
                                        'warzoneLeaderBoards.mostVictoryPoints'
                                    )}
                                    <span>
                                        {translate(
                                            'warzoneLeaderBoards.inThePast7Days'
                                        )}
                                    </span>
                                </h4>
                                <LeaderboardList
                                    loading={loading}
                                    color={color}
                                    list={victoryPoints}
                                    count={count}
                                    showRankNumber
                                    focusStat="points"
                                    faction={faction}
                                    showEnlisted={showAllFactionIcons}
                                    colorEnlisted={colorEnlisted}
                                />
                            </section>
                            <section className="personal">
                                <h4>
                                    {translate(
                                        'warzoneLeaderBoards.yourProgress'
                                    )}
                                    <span>
                                        {translate(
                                            'warzoneLeaderBoards.inThePast7Days'
                                        )}
                                    </span>
                                </h4>
                                {!loggedIn ? (
                                    <div className="loginPanel">
                                        <div className="loginBlur">
                                            <LeaderboardList
                                                loading={false}
                                                color={color}
                                                list={placeholders}
                                                showTooltips={false}
                                                faction={faction}
                                                showEnlisted
                                                colorEnlisted={colorEnlisted}
                                            />
                                        </div>
                                        <div className="loginBtnContainer">
                                            <p>
                                                {translate(
                                                    'warzoneLeaderBoards.yourPilotsContribution'
                                                )}
                                            </p>
                                            <ButtonWithTarget
                                                url={loginUrl}
                                                color="#1A1A1A"
                                                backgroundColor={color}
                                            >
                                                Login
                                            </ButtonWithTarget>
                                            <p>
                                                {translate(
                                                    'warzoneLeaderBoards.takePart',
                                                    null,
                                                    { renderInnerHtml: true }
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <LeaderboardList
                                            loading={!personalLeaderboardData}
                                            color={color}
                                            list={personalLeaderboardData}
                                            faction={faction}
                                            showEnlisted
                                            colorEnlisted={colorEnlisted}
                                        />
                                    </div>
                                )}
                            </section>
                        </div>
                    </div>
                )}
            </Translate>
        </LeaderboardsStyles>
    )
}

Leaderboards.defaultProps = {
    color: 'white',
    group: 'LastWeek',
    count: 3,
    showAllFactionIcons: false,
}

export default Leaderboards
