import React from 'react'
import BackgroundImage from 'features/background-image'
import { Section, Container, Frame, Icon } from 'layouts'
import {
    HeadingRegular,
    HeadingMedium,
    ParagraphLarge,
} from 'layouts/typography'
import AnimatedText from 'layouts/animated-text'
import { isClient } from 'config/web'
import SectionType from 'models/types/ts/sectionType'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import { getQuery } from 'lib/location/selectors'
import { Translate } from 'react-localize-redux'
import style from './PaperFormEmbed.module.scss'

interface Props {
    section: SectionType
}

export default function PaperFormEmbed({ section }: Props): JSX.Element {
    const { headline, teaser } = section

    const query = useSelector((state: GlobalState) => getQuery(state))

    React.useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://paperform.co/__embed.min.js'
        document.body.appendChild(script)
    }, [])

    function renderPaperForm(): JSX.Element {
        return (
            <div
                data-paperform-id="ukgnn9xi"
                data-spinner
                className={style.paperform}
            />
        )
    }

    if (query?.success === 'true') {
        return (
            <BackgroundImage
                url="https://images.ctfassets.net/7lhcm73ukv5p/7Gf5SJsblO3N4MvvoTpK7X/016c5556d0cb889ceb5ac327389b6b00/partnership_program_bg.jpg"
                repeat="no-repeat"
                size="cover"
                height={800}
                position="top center"
                className={style.heroBG}
            >
                <div className={style.heroContainer}>
                    <Container className={style.hero}>
                        <div className={style.text}>
                            <HeadingMedium>
                                <AnimatedText>{headline}</AnimatedText>
                            </HeadingMedium>
                            <HeadingRegular>
                                <AnimatedText>{teaser}</AnimatedText>
                            </HeadingRegular>
                        </div>
                    </Container>
                </div>
                <Section className={style.submitForm}>
                    <Frame className={style.frameContainer}>
                        <div className={style.formContainer}>
                            <div className={style.success}>
                                <Icon
                                    regular
                                    name="check-circle"
                                    className={style.success}
                                />
                                <ParagraphLarge>
                                    <Translate id="partnershipProgram.successText">
                                        Thank you for applying to the
                                        Partnership Program! Your application
                                        may take some time to process. An email
                                        will be sent to you with the results of
                                        your application once available.
                                    </Translate>
                                </ParagraphLarge>
                            </div>
                        </div>
                    </Frame>
                </Section>
            </BackgroundImage>
        )
    }

    if (query?.success === 'false') {
        return (
            <BackgroundImage
                url="https://images.ctfassets.net/7lhcm73ukv5p/7Gf5SJsblO3N4MvvoTpK7X/016c5556d0cb889ceb5ac327389b6b00/partnership_program_bg.jpg"
                repeat="no-repeat"
                size="cover"
                height={800}
                position="top center"
                className={style.heroBG}
            >
                <div className={style.heroContainer}>
                    <Container className={style.hero}>
                        <div className={style.text}>
                            <HeadingMedium>
                                <AnimatedText>{headline}</AnimatedText>
                            </HeadingMedium>
                            <HeadingRegular>
                                <AnimatedText>{teaser}</AnimatedText>
                            </HeadingRegular>
                        </div>
                    </Container>
                </div>
                <Section className={style.submitForm}>
                    <Frame className={style.frameContainer}>
                        <div className={style.formContainer}>
                            <div className={style.fail}>
                                <Icon
                                    light
                                    name="times"
                                    className={style.failed}
                                />
                                <ParagraphLarge>
                                    Error occured when submitting your
                                    application. <br />
                                    Please make sure you have entered all the
                                    required information. Please contact{' '}
                                    <a href="https://support.eveonline.com/hc/en-us">
                                        customer support
                                    </a>{' '}
                                    if problem persists.
                                </ParagraphLarge>
                            </div>
                        </div>
                    </Frame>
                </Section>
            </BackgroundImage>
        )
    }

    return (
        <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
            <BackgroundImage
                url="https://images.ctfassets.net/7lhcm73ukv5p/7Gf5SJsblO3N4MvvoTpK7X/016c5556d0cb889ceb5ac327389b6b00/partnership_program_bg.jpg"
                repeat="no-repeat"
                size="contain"
                height={800}
                position="top center"
                className={style.heroBG}
            >
                <div className={style.heroContainer}>
                    <Container className={style.hero}>
                        <div className={style.text}>
                            <HeadingMedium>
                                <AnimatedText>{headline}</AnimatedText>
                            </HeadingMedium>
                            <HeadingRegular>
                                <AnimatedText>{teaser}</AnimatedText>
                            </HeadingRegular>
                        </div>
                    </Container>
                </div>

                <Section className={style.submitForm}>
                    {isClient && renderPaperForm()}
                </Section>
            </BackgroundImage>
        </BackgroundImage>
    )
}
