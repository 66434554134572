import React from 'react'
import { Translate } from 'utils/localize'
import { format } from 'date-fns'
import style from './DateAndAuthor.module.scss'

interface Props {
    date: string
    author: string
    className?: string
    suffix?: string
}

function DateAndAuthor({
    date,
    author,
    className,
    suffix,
}: Props): JSX.Element {
    if (date === undefined) return null

    return (
        <span className={style(style.author_date, className)}>
            {format(new Date(date), 'yyyy-MM-dd')}
            {author && (
                <span>
                    {' '}
                    - <Translate id="news.authorBy" /> {author} {suffix}
                </span>
            )}
        </span>
    )
}

export default DateAndAuthor
