import React from 'react'
import PropTypes from 'prop-types'
import { flags } from 'config'
import Link from 'features/link'
import { SvgIcon } from 'layouts'
import { setLangCookie } from 'utils/langCookie'
import style from './SelectLanguage.scss'

const SelectLanguage = ({
    languages,
    language,
    page,
    subpage,
    id,
    subid,
    query,
    className,
    iconColor,
}) => {
    // Languages text
    const list = {
        en: 'English',
        fr: 'Français',
        de: 'Deutsch',
        ru: 'Русский',
        ja: '日本語',
        ko: '한국어',
        es: 'Español',
        zh: '简体中文',
    }

    const { colorThemeEnabled } = false

    // Map languages
    const langs = languages.map((lang) => (
        <li
            key={lang}
            className={style({
                [style.active]: lang === language,
                [style.fpTheme]: iconColor === '#5ccbcb',
            })}
        >
            <Link
                path={{ page, subpage, id, subid, query }}
                lang={lang}
                hrefLang={lang}
                onClick={() => setLangCookie(lang)}
            >
                <span className={style.lang_text}>{list[lang] || lang}</span>
                <span className={style.lang_code}>{lang}</span>
            </Link>
        </li>
    ))

    return (
        <div
            className={style(
                style.language,
                {
                    [style.colorTheme]: colorThemeEnabled,
                    [style.fpTheme]: iconColor === '#5ccbcb',
                },
                className
            )}
            data-locale={language}
        >
            {/* <div key="bar" className={style.bar} /> */}
            <SvgIcon
                name="globe"
                fill={iconColor}
                className={style.globe}
                width={20}
            />
            <div className={style.selected}>
                <span>{language}</span>
            </div>
            <ul>{langs}</ul>
        </div>
    )
}

SelectLanguage.propTypes = {
    language: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    page: PropTypes.string,
    subpage: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subid: PropTypes.string,
    query: PropTypes.shape({
        query: PropTypes.string,
    }),
    className: PropTypes.string,
    iconColor: PropTypes.string,
}

export default SelectLanguage
