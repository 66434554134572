import DiscordJoinServerModel from 'models/discord/joinServerModel'
import Logger from 'utils/logging'
import client from '../client'

export default async function joinDiscordServer(
    token: string,
    postModel: DiscordJoinServerModel
): Promise<boolean> {
    // Only call the service if token is set, else just return false
    if (!token) return false

    const joinServer = await client({
        url: `/discord/joinserver`,
        headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
        },
        data: postModel,
        method: 'post',
    })
        .then((result) => {
            console.log('xxx result', result)
            return result && result?.data && result?.data?.success
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'discord/joinServer',
                'api.discord.joinServer'
            )
            return false
        })

    return joinServer
}
