import React from 'react'
import { FormikConsumer } from 'formik'

const Debug = (): JSX.Element => (
    <FormikConsumer>
        {({ ...rest }) => (
            <pre
                style={{
                    fontSize: '.85rem',
                    padding: '.25rem .5rem',
                }}
            >
                {JSON.stringify(rest, null, 2)}
            </pre>
        )}
    </FormikConsumer>
)

export default Debug
