import React from 'react'
import { useSelector } from 'react-redux'
import BackgroundImage from 'features/background-image'
import Markdown from 'utils/markdown'
import SectionById from 'queries/section-by-id'
import SectionType from 'models/types/ts/sectionType'
import { getLanguage } from 'selectors'
import { GlobalState } from 'types'
import s from './Anywhere.module.scss'

const Anywhere = (): JSX.Element => {
    const language = useSelector((state: GlobalState) => getLanguage(state))

    return (
        <BackgroundImage
            url="https://images.ctfassets.net/7lhcm73ukv5p/1fw37hFXpFDilaKYnD8s0L/06baa3d66eac18294fa7f16c6fa879ff/anywhere-bg.jpg"
            // repeat="no-repeat"
            size="cover"
            position="top center"
        >
            <div className={s.anywhere}>
                <SectionById
                    identifier="eve-anywhere-sunset-notice"
                    locale={language}
                >
                    {(sunsetNotice: SectionType) => {
                        if (sunsetNotice) {
                            const { headline, body } = sunsetNotice
                            return (
                                <div className={s.sunset}>
                                    <h4>{headline}</h4>
                                    <Markdown source={body} />
                                </div>
                            )
                        }
                        return null
                    }}
                </SectionById>
            </div>
        </BackgroundImage>
    )
}

export default Anywhere
