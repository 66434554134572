import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import cx from 'classnames'
import { GlobalState } from 'types/redux'
import { Character } from 'types/account'
import getCharacters from 'services/api/customer/characters'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import joinDiscordServer from 'services/api/discord/joinServer'
import PageType from 'models/types/ts/pageType'
import { getQuery } from 'lib/location/selectors'
import { getLoggedInJwt, isLoggedIn } from 'packages/authentication'
import getConfig, { isClient } from 'config/web'
import { findSectionByIdentifier } from 'lib/pages/api'
import { Button, Container, Section, Icon } from 'layouts'
import { QueryClient, QueryClientProvider } from 'react-query'
import s from './Discord.module.scss'
import CharacterPanel from './components/CharacterPanel'
import ContentSection from './components/ContentSection'

const queryClient = new QueryClient()

const { discordAuthBaseUrl, discordClientId, discordScopes } = getConfig()

/*
 * Using a simple state machine to track the user's progress through the verification flow.
 */
enum State {
    // We start here. We transition to LoggedIn if the user is found to be logged in.
    Start,

    // If the user is logged in, we start fetching the characters, transitioning to FetchedCharacters if done.
    LoggedIn,

    // The user is not logged in. This is an 'end' state where we ask the user to log in.
    NotLoggedIn,

    // We can now display all the user's characters. If they select one, we transition to SelectedCharacter
    // If they have returned from Discord, we can pre-select their character for them.
    FetchedCharacters,

    // The user has a character selected, they can now proceed to authenticate
    // If the user returned from discord, we can continue directly to ConnectingCharacter
    SelectedCharacter,

    // The user has requested we authenticate with discord, so we can ship them off to discord.
    AuthenticateWithDiscord,

    // The user returned from discord, we have a state (selected character) and code. We can now connect to discord
    ConnectingCharacter,

    // Connecting succeeded
    ConnectingSuccess,

    // Connecting failed
    ConnectingFailed,
}

const LoadingPulse = ({ ...props }): JSX.Element => {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Section hasContent={false} {...props} />
}

const pagePath = '/discord'

const buildRedirectUrl = (): string =>
    isClient ? `${window.location.origin}${pagePath}` : ''
const buildDiscordURL = (state: string | number): string => {
    const scopes = encodeURIComponent(discordScopes.join(' '))
    const redirectUri = encodeURIComponent(buildRedirectUrl())

    return `${discordAuthBaseUrl}/authorize?client_id=${discordClientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=${state}`
}

const DiscordPage = (): JSX.Element => {
    const loggedIn = useSelector((state: GlobalState) => isLoggedIn(state))
    const language = useSelector((state: GlobalState) => state.language)
    const userToken = useSelector((state: GlobalState) => getLoggedInJwt(state))
    const query = useSelector((state: GlobalState) => getQuery(state))

    const [currentState, setState] = useState<State>(State.Start)
    const [characters, setCharacters] = useState<Array<Character>>([])
    const [selectedCharacter, setSelectedCharacter] = useState<Character>()
    const [connectingCharacter, setConnectingCharacter] = useState<Character>()
    const [usedDiscordCode, setUsedDiscordCode] = useState<boolean>(false)
    const [usedDiscordState, setUsedDiscordState] = useState<boolean>(false)
    const [isLoading, setLoading] = useState<boolean>(true)

    const dispatch = useDispatch()

    const slug = 'discord'

    const discordRedirectUrl = useMemo(
        () => buildDiscordURL(selectedCharacter?.id),
        [selectedCharacter]
    )

    const linkButtonVisible = useMemo(
        () =>
            currentState === State.FetchedCharacters ||
            currentState === State.SelectedCharacter,
        [currentState]
    )
    const linkButtonEnabled = useMemo(
        () => currentState === State.SelectedCharacter,
        [currentState]
    )
    const isLinking = useMemo(
        () =>
            currentState === State.ConnectingSuccess ||
            currentState === State.ConnectingFailed ||
            currentState === State.ConnectingCharacter,
        [currentState]
    )

    const resetFlow = useCallback(() => {
        setState(State.Start)
        if (isClient && window !== null && window.history !== null) {
            // Trigger a replaceState to remove all query data from the URL.
            // Note, react-first-router does not detect this, so the query
            // data will still be there, but this avoids confusing behavior
            // if the user would refresh the page after restarting the flow.
            window.history.replaceState(null, '', pagePath)
        }
    }, [])

    const selectCharacter = useCallback(
        (selectedId: number): void => {
            if (isLinking) return
            const selected = characters.find((char) => char.id === selectedId)
            if (selected?.id === selectedCharacter?.id) {
                setSelectedCharacter(null)
                setState(State.FetchedCharacters)
            } else {
                setSelectedCharacter(selected)
                setState(State.SelectedCharacter)
            }
        },
        [selectedCharacter, currentState, isLinking, characters]
    )

    useEffect(() => {
        if (!isClient) return
        switch (currentState) {
            case State.Start:
                setSelectedCharacter(null)
                setConnectingCharacter(null)
                setState(loggedIn ? State.LoggedIn : State.NotLoggedIn)
                break
            case State.LoggedIn:
                setLoading(true)
                getCharacters(userToken).then((chars) => {
                    setLoading(false)
                    setCharacters(chars)
                    setState(State.FetchedCharacters)
                })
                break
            case State.NotLoggedIn:
                setLoading(false)
                // If this state was reached before the auth system could identify us as logged in
                // we can switch back to the LoggedIn state if it's since been updated.
                // This trick works because we've set `loggedIn` as a dependency for this useEffect
                // so if it gets updated, this should trigger.
                if (loggedIn) setState(State.LoggedIn)
                break
            case State.FetchedCharacters:
                if (!usedDiscordState && query?.state) {
                    setUsedDiscordState(true) // Avoid re-using the query param after trying it
                    const selectedId = parseInt(query.state, 10)
                    selectCharacter(selectedId)
                }
                break
            case State.SelectedCharacter:
                if (!usedDiscordCode && query?.code) {
                    setUsedDiscordCode(true) // Avoid re-using the query param after trying it
                    setState(State.ConnectingCharacter)
                }
                if (
                    !usedDiscordCode &&
                    query?.error &&
                    query?.error_description &&
                    query?.state
                ) {
                    setUsedDiscordCode(true) // Avoid re-using the query param after trying it
                    setConnectingCharacter(selectedCharacter) // Select the character that failed to connect
                    setState(State.ConnectingFailed) // Tell the user to try again
                }
                break
            case State.ConnectingCharacter:
                setConnectingCharacter(selectedCharacter)
                joinDiscordServer(userToken, {
                    character_id: selectedCharacter.id,
                    discord_code: query?.code,
                    redirect_url: buildRedirectUrl(),
                }).then((success) => {
                    setState(
                        success
                            ? State.ConnectingSuccess
                            : State.ConnectingFailed
                    )
                })
                break
            default:
                break
        }
    }, [
        currentState,
        query,
        usedDiscordCode,
        usedDiscordState,
        selectedCharacter,
        userToken,
        loggedIn,
    ])

    const LinkStatus = ({ state, reset, sections }): JSX.Element => {
        const linkSuccess = findSectionByIdentifier(sections, 'link-success')
        const linkFailed = findSectionByIdentifier(sections, 'link-failed')

        switch (state) {
            case State.ConnectingFailed:
                return (
                    <>
                        <h2 className={s.linkResult}>
                            {linkFailed?.headline ??
                                'We were unable to link your account'}
                        </h2>
                        <div className={s.buttonBar}>
                            <Button onClick={reset}>
                                {linkFailed?.body ?? 'Try again'}
                            </Button>
                        </div>
                    </>
                )
            case State.ConnectingSuccess:
                return (
                    <>
                        <h2 className={s.linkResult}>
                            {(
                                linkSuccess?.headline ??
                                'Successfully connected %CHARACTER% to Discord'
                            ).replace('%CHARACTER%', connectingCharacter.name)}
                        </h2>
                        <div className={s.buttonBar}>
                            <Button onClick={reset}>
                                {linkSuccess?.body ?? 'Try again'}
                            </Button>
                        </div>
                    </>
                )
            default:
                return <LoadingPulse loadingTypeSmall />
        }
    }

    const CharacterSelection = ({ sections }): JSX.Element => {
        const link = findSectionByIdentifier(sections, 'link')
        const noCharacters = findSectionByIdentifier(sections, 'no-characters')
        const YouHaveNoCharacters = (): JSX.Element =>
            noCharacters !== null ? (
                <ContentSection section={noCharacters} />
            ) : (
                <p>You have no characters.</p>
            )

        return (
            <div>
                <div className={s.characters}>
                    <ul>
                        {characters && characters.length > 0 ? (
                            characters.map((item) => {
                                return (
                                    <li
                                        key={item.id}
                                        onClick={() => selectCharacter(item.id)}
                                    >
                                        <CharacterPanel
                                            characterName={item.name}
                                            characterId={item.id}
                                            selected={
                                                selectedCharacter?.id ===
                                                item.id
                                            }
                                            success={
                                                currentState ===
                                                    State.ConnectingSuccess &&
                                                connectingCharacter.id ===
                                                    item.id
                                            }
                                            failed={
                                                currentState ===
                                                    State.ConnectingFailed &&
                                                connectingCharacter.id ===
                                                    item.id
                                            }
                                        />
                                    </li>
                                )
                            })
                        ) : (
                            <YouHaveNoCharacters />
                        )}
                    </ul>
                    {linkButtonVisible ? (
                        <div className={s.buttonBar}>
                            <Button
                                className={cx(
                                    s.btn,
                                    linkButtonEnabled
                                        ? undefined
                                        : s.btnDisabled
                                )}
                                path={
                                    linkButtonEnabled ? discordRedirectUrl : '#'
                                }
                                small
                            >
                                {link?.headline ?? 'Link your character'}
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                    {isLinking ? (
                        <div>
                            <LinkStatus
                                state={currentState}
                                reset={resetFlow}
                                sections={sections}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        )
    }

    const NotLoggedIn = ({ sections }): JSX.Element => {
        const login = findSectionByIdentifier(sections, 'login')

        return (
            <div className={s.content}>
                <div className={s.dots}>
                    <Button
                        className={s.login}
                        size="large"
                        path={{
                            page: 'login',
                            query: {
                                path: `/${language}/discord`,
                            },
                        }}
                        showPlatform={false}
                        internal
                    >
                        {login?.headline ?? 'Log in'}
                    </Button>
                </div>
            </div>
        )
    }

    function renderContent(sections): JSX.Element {
        switch (currentState) {
            case State.NotLoggedIn:
                return <NotLoggedIn sections={sections} />
            case State.FetchedCharacters:
            case State.SelectedCharacter:
            case State.ConnectingCharacter:
            case State.ConnectingFailed:
            case State.ConnectingSuccess:
                return <CharacterSelection sections={sections} />
            default:
                return <LoadingPulse />
        }
    }

    return (
        <QueryClientProvider client={queryClient}>
            <PageBySlugQuery slug={slug} locale={language}>
                {(page: PageType) => {
                    if (page) {
                        const sections = page?.sectionsCollection?.items
                        const hero = findSectionByIdentifier(sections, 'hero')
                        const content = findSectionByIdentifier(
                            sections,
                            'content'
                        )
                        const footer = findSectionByIdentifier(
                            sections,
                            'discord-footer'
                        )

                        return (
                            <div className={cx(s.discord)}>
                                <Container>
                                    <PageWithSeo
                                        page={page}
                                        showLoading={false}
                                        hideSitename={false}
                                    >
                                        <ContentSection
                                            section={hero}
                                            TitleComponent="h2"
                                            Icon={<Icon brand name="discord" />}
                                        />
                                        <ContentSection
                                            section={content}
                                            className={s.content}
                                        />
                                        {isLoading ? (
                                            <LoadingPulse />
                                        ) : (
                                            renderContent(sections)
                                        )}
                                        <ContentSection
                                            section={footer}
                                            className={s.footer}
                                        />
                                    </PageWithSeo>
                                </Container>
                            </div>
                        )
                    }

                    // page not found in contentful
                    dispatch({ type: NOT_FOUND })
                    return <></>
                }}
            </PageBySlugQuery>
        </QueryClientProvider>
    )
}

export default DiscordPage
