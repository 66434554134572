import React, { ReactNode } from 'react'
import { Link, SelectLanguage } from 'features'
import BackgroundImage from 'features/background-image'
import { Frame, Section, SvgIcon } from 'layouts'
import AssetByIdQuery from 'queries/asset-by-id'
import s from './verificationCallback.module.scss'

interface Props {
    children: ReactNode
}

const FailDisplay = ({ children }: Props): JSX.Element => {
    return (
        <BackgroundImage
            url="//images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg"
            repeat="repeat"
            size="contain"
            position="top center"
        >
            <AssetByIdQuery id="1TGSAWwH9OYVQ2k9a1ItSs">
                {(url: string) => {
                    return (
                        <div className={s.verificationFailWrapper}>
                            <BackgroundImage
                                url={url}
                                repeat="no-repeat"
                                size="contain"
                                position="center top"
                                className={s.hero}
                                lazy={false}
                            />
                            <>
                                <div className={s.language}>
                                    <SelectLanguage />
                                </div>
                                <Link className={s.logo}>
                                    <SvgIcon
                                        width={130}
                                        name="eve-logo-with-text"
                                        fill="#fff" // Depends on key art bg
                                    />
                                </Link>
                                <Section hasContent className={s.content}>
                                    <div className={s.signupContainer}>
                                        <Frame className={s.container}>
                                            {children}
                                        </Frame>
                                    </div>
                                </Section>
                            </>
                        </div>
                    )
                }}
            </AssetByIdQuery>
        </BackgroundImage>
    )
}

export default FailDisplay
