import React, { useState } from 'react'
import { Translate } from 'utils/localize'
import { Link } from 'features'
import { Icon } from 'layouts'
import NewsTagType from 'types/news/NewsTagType'
import style from './TagsList.module.scss'

interface Props {
    tags: Array<NewsTagType>
}

const Tags = ({ tags }: Props): JSX.Element => {
    const [tagsActive, setTagsActive] = useState(false)

    const renderTags = (preview?: boolean): JSX.Element => {
        let size = 50
        if (preview) size = 4
        return (
            <Translate>
                {({ translate }) => (
                    <div className={style.wrapper}>
                        <ul
                            className={style(style.tagsList, {
                                [style.preview]: preview,
                            })}
                        >
                            {tags instanceof Array &&
                                tags.slice(0, size).map((tag: NewsTagType) => (
                                    <li key={tag.name}>
                                        <Link
                                            path={{
                                                page: 'news',
                                                subpage: 't',
                                                id: tag.name,
                                            }}
                                            onClick={() => setTagsActive(false)}
                                        >
                                            {tag.headline}
                                        </Link>
                                    </li>
                                ))}
                            {preview && (
                                <span
                                    className={style.allTags}
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => setTagsActive(true)}
                                    title={translate(
                                        'news.viewMoreTags'
                                    ).toString()}
                                >
                                    <Icon regular name="ellipsis-h" />
                                </span>
                            )}
                        </ul>
                    </div>
                )}
            </Translate>
        )
    }

    return (
        <>
            <div className={style.tags}>{renderTags(true)}</div>
            <div
                className={style(style.tags, style.list, {
                    [style.active]: tagsActive,
                })}
            >
                {renderTags()}
                <span
                    role="button"
                    tabIndex={0}
                    onClick={() => setTagsActive(false)}
                    className={style.close}
                >
                    <Icon light name="times" />
                </span>
            </div>
        </>
    )
}
export default Tags
