import React from 'react'
import s from './Table.module.scss'

interface Props {
    children: React.ReactNode
}

export const Table = ({ children }: Props): JSX.Element => (
    <table className={s.table}>
        <tbody>{children}</tbody>
    </table>
)

export default Table
