import React from 'react'
import s from './ListItem.module.scss'

interface ListProps {
    children: React.ReactNode
}

export const UlList = ({ children }: ListProps): JSX.Element => (
    <ul className={s.ul}>{children}</ul>
)

export const OlList = ({ children }: ListProps): JSX.Element => (
    <ol className={s.ol}>{children}</ol>
)

export const ListItem = ({ children }: ListProps): JSX.Element => (
    <li>{children}</li>
)
