import React, { CSSProperties } from 'react'
import SlidingPaneComponent from 'react-sliding-pane'
import { Container } from 'layouts'
import LinkArrow from 'layouts/link-arrow'
import { LabelSmall } from 'layouts/typography'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import style from './SlidingPane.module.scss'

interface Props {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode

    className?: string

    colors: SlidingPaneConfigModel
    backLinkText?: string
}

const SlidingPane = ({
    isOpen,
    onClose,
    children,
    colors,
    className,
    backLinkText,
}: Props): JSX.Element => {
    const slidingPaneClassNames = className
        ? `${style.pane} ${className}`
        : style.pane

    if (!backLinkText) {
        backLinkText = 'Back'
    }

    const cssStyles = {
        '--dlp-link-color': colors.linkColor,
        color: colors.textColor,
    } as CSSProperties

    return (
        <SlidingPaneComponent
            className={slidingPaneClassNames}
            overlayClassName={style.overlay}
            hideHeader
            isOpen={isOpen}
            onRequestClose={onClose}
            width="100%"
            from="bottom"
        >
            <div style={cssStyles}>
                <Container>
                    <button
                        type="button"
                        onClick={onClose}
                        className={style.back}
                    >
                        <LinkArrow
                            className={style.arrow}
                            color={colors.arrowColor}
                        />{' '}
                        <LabelSmall style={{ color: `${colors.textColor}` }}>
                            {backLinkText}
                        </LabelSmall>
                    </button>
                    {children}
                </Container>
            </div>
        </SlidingPaneComponent>
    )
}

export default SlidingPane
