// These are all used from react-localize-redux and need to be replaced if we start using another package
export {
    Translate,
    getTranslate,
    setActiveLanguage,
    localizeReducer,
    withLocalize,
    LocalizeProvider,
} from './react-localize-redux'

export type {
    TranslateFunction,
    LocalizeState,
    LocalizedElement,
    onMissingTranslationFunction
} from "./react-localize-redux"
