import React from 'react'
import FeatureVideo from 'features/featureVideo'
import { MediaEmbedType } from '../../types'
import { YT_REGEXP, YT_ID_LENGTH } from './data'

const YoutubeEmbed = ({ mediaUrl, thumbnail: { url } }: MediaEmbedType): JSX.Element => {
    const match = mediaUrl.match(YT_REGEXP)
    if (match && match[2].length === YT_ID_LENGTH) {
        const videoId = match[2]

        return (
            <FeatureVideo
                videoId={videoId}
                image={url || `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
            />
        )
    }

    return <></>
}

export default YoutubeEmbed
