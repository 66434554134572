import React from 'react'
import { useSelector } from 'react-redux'
import BackgroundImage from 'features/background-image'
import NotFound from 'pages/not-found'
import { GlobalState } from 'types/redux'
import getConfig from 'config/web'
import SectionType from 'models/types/ts/sectionType'
import { Loading } from 'layouts'
import NewsType from 'models/types/ts/newsType'
import NewsDetail from './components/NewsDetail'
import s from './NewsDetailContainer.module.scss'
import useNewsItem from './hooks'

interface Props {
    featured: SectionType
}

function renderArticle(article: NewsType, featured: SectionType): JSX.Element {
    return (
        <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
            <BackgroundImage
                url="https://images.ctfassets.net/7lhcm73ukv5p/3kBbL40e9KAKPMlM27jqRw/670eb7dd8cd76eab700ec8445373dd2c/news_detail_background.jpg"
                repeat="no-repeat"
                size="cover"
                position="center center"
                className={s.header}
            />
            <NewsDetail newsItem={article} featured={featured} />
        </BackgroundImage>
    )
}

export default function NewsDetailContainer({ featured }: Props): JSX.Element {
    const location = useSelector((state: GlobalState) => state.location)

    const slug = location && location.payload && location.payload.id

    const language = useSelector((state: GlobalState) => state.language)

    // Too complex call, simplify, hide away
    const {
        contentful: { preview },
    } = getConfig()

    const [loading, error, article, oldArticle] = useNewsItem(
        slug,
        language,
        preview
    )

    // Loading happens in client side rendering when navigationg between
    // This might actually never happen, but it should, test with ssr
    if (loading && !article && !oldArticle) {
        return <Loading />
    }

    // If stopped loading and no article, then not found
    if (!loading && !article && !oldArticle) {
        return <NotFound />
    }

    // We can get error from graphql query hook
    if (error) {
        return <NotFound />
    }

    // if here we should have an article that can be rendered
    if (article) return renderArticle(article, featured)

    if (oldArticle) return renderArticle(oldArticle, featured)

    // if not, show not found
    return <NotFound />
}
