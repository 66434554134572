import React from 'react'
import { useSelector } from 'react-redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import Loading from 'layouts/loading/PageLoading'
import { GlobalState } from 'types'
import PageType from 'models/types/ts/pageType'
import { useHasMounted } from 'hooks/client-only'

const LoadableComponent = React.lazy(
    () => import(/* webpackChunkName: "page-recruit" */ './RecruitContainer')
)

export default function LoadableWWW(): JSX.Element {
    const slug = 'recruit'
    const language = useSelector((state: GlobalState) => state.language)

    const hasMounted = useHasMounted()

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                if (page) {
                    const sections = page.sectionsCollection.items

                    return (
                        <PageWithSeo page={page} showLoading={false}>
                            {hasMounted && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableComponent
                                        sections={sections}
                                        language={language}
                                    />
                                </React.Suspense>
                            )}
                        </PageWithSeo>
                    )
                }
            }}
        </PageBySlugQuery>
    )
}
