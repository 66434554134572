import React from 'react'
import { FieldProps } from 'formik'
import Select from 'react-select-search/dist/cjs'
import { SelectOptionType } from '../../types'
import s from './SelectField.module.scss'

interface Props {
    options: Array<SelectOptionType>
}

const SelectField = ({
    options,
    field,
    form,
}: Props & FieldProps): JSX.Element => {
    const handleChange = (option: SelectOptionType): void => {
        form.setFieldValue(field.name, option)
    }
    return (
        <Select
            className={(key: string) => s[key]}
            options={options}
            onChange={handleChange}
        />
    )
}

export default SelectField
