import React from 'react'
import getConfig from 'config/web'
import YTEmbed from './YTEmbed'
import Iframe from './Iframe'
import TweetEmbed from './TweetEmbed'
import { MediaEmbedType } from '../../types'
import s from './MediaEmbed.module.scss'

const { webBaseUrl } = getConfig()

const MediaEmbed = ({ title, type, mediaUrl, thumbnail, loopVideo }: MediaEmbedType): JSX.Element => {
    const { hostname } = new URL(webBaseUrl)

    if (!mediaUrl) return <></>

    const url = thumbnail?.url;

    switch (type) {
        case 'cdn-video':
            return (
                <video width="100%" controls loop={loopVideo} poster={url && `${url}?w=850`}>
                    <source src={mediaUrl} />
                </video>
            )
        case 'cdn-audio':
            return <audio src={mediaUrl} controls className={s.audioEmbed} />

        case 'youtube':
            return <YTEmbed mediaUrl={mediaUrl} thumbnail={{url}} />

        case 'twitch':
            return (
                <Iframe
                    src={`${mediaUrl}&parent=${hostname}&autoplay=false`}
                    title={title}
                />
            )
        case 'tweet':
            return <TweetEmbed url={mediaUrl} />

        default:
            return <></>
    }
}

export default MediaEmbed
