import React, { memo } from 'react'
import { FaIconName } from 'config/fontawesome/types'
import SocialIcon from './components'

export type SocialIconType =
    | 'facebook'
    | 'twitter'
    | 'youtube'
    | 'twitch'
    | 'instagram'
    | 'vk'
    | 'discord'

interface Props {
    id: SocialIconType
    isLink?: boolean
}

type IconsProps = {
    [key: string]: {
        faName: FaIconName
        href: string
        title: string
    }
}

const icons: IconsProps = {
    facebook: {
        faName: 'facebook-f',
        href: '//www.facebook.com/eveonline/',
        title: 'Facebook',
    },
    twitter: {
        faName: 'twitter',
        href: '//twitter.com/eveonline/',
        title: 'Twitter',
    },
    youtube: {
        faName: 'youtube',
        href: '//www.youtube.com/channel/UCwF3VyalTHzL0L-GDlwtbRw/',
        title: 'YouTube',
    },
    twitch: { faName: 'twitch', href: '//www.twitch.tv/ccp', title: 'Twitch' },
    instagram: {
        faName: 'instagram',
        href: '//www.instagram.com/eveonline/',
        title: 'Instagram',
    },
    vk: { faName: 'vk', href: '//vk.com/eveonline', title: 'VK' },
    discord: {
        faName: 'discord',
        href: '//eveonline.com/discord',
        title: 'Discord',
    },
}

const Icon = ({ id, isLink }: Props): JSX.Element => {
    if (id == null || icons[id] == null) return null
    return (
        <SocialIcon
            id={id}
            title={icons[id].title}
            name={icons[id].faName}
            href={icons[id].href}
            isLink={isLink}
        />
    )
}

Icon.defaultProps = {
    isLink: true,
}

export default memo(Icon)
