import styled from 'styled-components'

interface MapStyleProps {
    children?: React.ReactNode
}

const MapStyles = styled.div<MapStyleProps>`
    .heading {
        margin-bottom: 30px;
    }

    .container {
        padding-bottom: 120px;
    }

    /* .map-container {
        height: 70vh;
        min-height: 700px;
    } */
`

export default MapStyles
