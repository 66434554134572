import React from 'react'
import ReactMarkdown from 'utils/markdown'
import SectionType from 'models/types/ts/sectionType'
import { Container, Section } from 'layouts'
import { Target } from '@ccpgames/eve-web/components/target/Target'
import { Button } from '@ccpgames/eve-web/components/button/Button'
import {
    HeadingMedium,
    HeadingXSmall,
    HeadingRegular,
    headingShadowSmall,
} from 'layouts/typography'
import style from './hero.module.scss'
import CountDown from '../CountDown'

interface Props {
    section: SectionType
}

/* eslint-disable react/jsx-props-no-spreading */

const Hero = ({ section }: Props): JSX.Element => {
    const dateExpire = new Date(section.date)

    return (
        <div className={style.hero}>
            <Container>
                <Section>
                    <div className={style.content}>
                        <div className={style.text}>
                            {section.headline && (
                                <ReactMarkdown
                                    source={section.headline}
                                    renderers={{
                                        h1: ({ children }) => (
                                            <HeadingMedium
                                                as="h1"
                                                {...headingShadowSmall}
                                            >
                                                {children}
                                            </HeadingMedium>
                                        ),
                                        h2: ({ children }): JSX.Element => (
                                            <HeadingRegular
                                                as="h2"
                                                {...headingShadowSmall}
                                            >
                                                {children}
                                            </HeadingRegular>
                                        ),
                                        h3: ({ children }) => (
                                            <HeadingXSmall
                                                as="h3"
                                                {...headingShadowSmall}
                                            >
                                                {children}
                                            </HeadingXSmall>
                                        ),
                                    }}
                                />
                            )}
                        </div>
                        {section.buttonUrl && (
                            <div className={style.cta}>
                                <Target color="#f67c0f">
                                    <Button
                                        href={section.buttonUrl}
                                        className={style.btn}
                                        backgroundColor="#f67909"
                                        style={{
                                            color: 'black',
                                            height: '60px',
                                        }}
                                    >
                                        {section.buttonText}
                                    </Button>
                                </Target>
                            </div>
                        )}
                        <CountDown
                            title={section.teaser}
                            endDate={dateExpire}
                        />
                    </div>
                </Section>
            </Container>
        </div>
    )
}

export default Hero
