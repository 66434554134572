// config names are not nested.
// Page toggle flags start with pageEnabledName and type bool
// Feature toggle flags start with featureEnabledName and type bool
// Event toggle flags start with eventEnabledName and type bool
// API urls start with urlName and type string
// If you add a new settings you need to add it to the model with same name also
// Model is in models/SettingsModel.ts

import SettingsModel from './models/SettingsModel'

interface EnvironmentSettingsModel {
    default: SettingsModel
    local: SettingsModel
    development: SettingsModel
    staging: SettingsModel
    production: SettingsModel
}

const settingsJson: EnvironmentSettingsModel = {
    default: {
        // Default if always first, then if found in environment here or external then that is used instead
        eventEnabledDownload: false,
        urlEventGatewayBaseUrl: 'https://default-gateway-urldoesnotexist',
        urlInstallerDownloadUrl: 'https://binaries.eveonline.com/EveLauncher-',
    },
    local: {
        eventEnabledDownload: true,
        urlEventGatewayBaseUrl:
            'https://dev-public-gateway-http.evetech.net:443/v1/events/',
        urlInstallerDownloadUrl: 'https://dev.ccpeveweb.com/EveLauncher-',
    },
    development: {
        eventEnabledDownload: true,
        urlEventGatewayBaseUrl:
            'https://dev-public-gateway-http.evetech.net:443/v1/events/',
        urlInstallerDownloadUrl: 'https://dev.ccpeveweb.com/EveLauncher-',
    },
    staging: {
        eventEnabledDownload: true,
        urlEventGatewayBaseUrl:
            'https://dev-public-gateway-http.evetech.net:443/v1/events/',
        urlInstallerDownloadUrl: 'https://staging.ccpeveweb.com/EveLauncher-',
    },
    production: {
        eventEnabledDownload: true,
        urlEventGatewayBaseUrl:
            'https://live-public-gateway-http.evetech.net:443/v1/events/',
        urlInstallerDownloadUrl: 'https://www.eveonline.com/EveLauncher-',
    },
}

export default settingsJson

// Below is the settings as it was published in Contentful on 10th November 2022
// Put here for comparison, and the default settings are changed above to reflect that.
// Disconnecting from the settings page in Contentful

// {
//     "local": {
//         "eventEnabledSignup": true,
//         "urlEventGatewayBaseUrl": "https://dev-public-gateway-http.evetech.net:443/v1/events/",
//         "urlInstallerDownloadUrl": "https://dev.ccpeveweb.com/EveLauncher-"
//     },
//     "preprod": {
//         "eventEnabledSignup": true,
//         "eventEnabledDownload": true,
//         "urlEventGatewayBaseUrl": "https://live-public-gateway-http.evetech.net:443/v1/events/",
//         "urlInstallerDownloadUrl": "https://preprod.ccpeveweb.com/EveLauncher-"
//     },
//     "preview": {
//         "eventEnabledSignup": false,
//         "urlEventGatewayBaseUrl": "https://live-public-gateway-http.evetech.net:443/v1/events/"
//     },
//     "staging": {
//         "eventEnabledSignup": true,
//         "eventEnabledDownload": true,
//         "urlEventGatewayBaseUrl": "https://dev-public-gateway-http.evetech.net:443/v1/events/",
//         "urlInstallerDownloadUrl": "https://staging.ccpeveweb.com/EveLauncher-"
//     },
//     "production": {
//         "eventEnabledSignup": true,
//         "eventEnabledDownload": true,
//         "urlEventGatewayBaseUrl": "https://live-public-gateway-http.evetech.net:443/v1/events/",
//         "urlInstallerDownloadUrl": "https://www.eveonline.com/EveLauncher-"
//     },
//     "development": {
//         "eventEnabledSignup": true,
//         "eventEnabledDownload": true,
//         "urlEventGatewayBaseUrl": "https://dev-public-gateway-http.evetech.net:443/v1/events/",
//         "urlInstallerDownloadUrl": "https://dev.ccpeveweb.com/EveLauncher-"
//     }
// }
