import { isProduction, isStaging, isPreProduction } from './web'

interface FlagsFeatures {
    colorThemeEnabled: boolean
    mailcheck: boolean
}

interface FlagsProps {
    isDevelopment: boolean
    isStaging: boolean
    features: FlagsFeatures
}

// Define flags with default values
const flags: FlagsProps = {
    isDevelopment: !(isProduction || isStaging || isPreProduction),
    isStaging,
    features: {
        colorThemeEnabled: true,
        mailcheck: true,
    },
}

export default flags
