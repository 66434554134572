// Platforms
import { faWindows } from '@fortawesome/free-brands-svg-icons/faWindows'
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple'
import { faSteam } from '@fortawesome/free-brands-svg-icons/faSteam'

// Social
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons/faYoutubeSquare'
import { faTwitch } from '@fortawesome/free-brands-svg-icons/faTwitch'
import { faReddit } from '@fortawesome/free-brands-svg-icons/faReddit'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faVk } from '@fortawesome/free-brands-svg-icons/faVk'
import { faDiscord } from '@fortawesome/free-brands-svg-icons/faDiscord'

const platforms = [faWindows, faApple, faSteam]

const social = [
    faFacebookF,
    faTwitter,
    faYoutube,
    faYoutubeSquare,
    faTwitch,
    faReddit,
    faInstagram,
    faVk,
    faDiscord,
]

export default [...platforms, ...social]
