import {
    LinkingRequestModel,
    LinkingRequestResponseModel,
} from 'models/twitch/LinkingModels'
import Logger from 'utils/logging'
import client from '../client'

export async function submitLinkRequest(
    token: string,
    requestBody: LinkingRequestModel
): Promise<LinkingRequestResponseModel> {
    if (!token) {
        throw new Error('Invalid token')
    }

    try {
        const response = await client({
            url: '/twitch/link',
            headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
            },
            method: 'post',
            data: requestBody,
        })

        return response.data
    } catch (error) {
        Logger.captureNetworkException(error, 'twitch/link', 'api.twitch.link')
        throw new Error('Failed to submit link request')
    }
}
