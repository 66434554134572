import { ButtonWithTarget } from 'layouts/button-with-target'
import React from 'react'

interface Props {
    children: React.ReactNode
    url: string
    color?: string
    backgroundColor?: string
}

const CallToActionButton = ({
    children,
    url,
    color,
    backgroundColor,
}: Props): JSX.Element => {
    return (
        <span
            style={{
                display: 'block',
                textAlign: 'center',
                paddingBottom: '10px',
            }}
        >
            <ButtonWithTarget
                url={url}
                color={color}
                backgroundColor={backgroundColor}
            >
                {children}
            </ButtonWithTarget>
        </span>
    )
}

export default CallToActionButton
