import React from 'react'
import ContentType from 'models/types/ts/contentType'
import { Grid } from '@mantine/core'
import { Icon } from 'layouts'
import style from './retailer.module.scss'

interface Props {
    content: ContentType
}

const Retailer = ({ content }: Props): JSX.Element => {
    if (!content) {
        return null
    }

    const { imageFile, headline, name, buttonUrl, buttonText } = content

    return (
        <Grid.Col md={6} sm={12}>
            <div className={style.content}>
                {imageFile?.url && (
                    <img
                        src={imageFile.url}
                        alt={imageFile.description || 'Image'}
                    />
                )}

                <div className={style.copy}>
                    {headline && <p className={style.headline}>{headline}</p>}
                    {name && (
                        <p className={style.name}>
                            {name}
                            {buttonUrl && (
                                <a
                                    href={buttonUrl}
                                    className={style.mobileLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Icon
                                        regular
                                        name="external-link-alt"
                                        className={style.externalLink}
                                    />
                                </a>
                            )}
                        </p>
                    )}
                    {buttonUrl && buttonText && (
                        <a
                            className={style.link}
                            target="_blank"
                            href={buttonUrl}
                            rel="noreferrer"
                        >
                            {buttonText}
                        </a>
                    )}
                </div>
            </div>
        </Grid.Col>
    )
}

export default Retailer
