import React, { ReactNode, useEffect, useState } from 'react'
import { isClient } from 'config/web'
import { Translate } from 'utils/localize'
import { getSessionId } from 'utils/session-id/session-id'
import { getQuery } from 'lib/location/selectors'
import { useSelector } from 'react-redux'
import { getAgeVerificationResponse } from 'services/api/signup/age-verification'
import { AgeVerificationVerifyModel } from 'models/age-verification-model'
import {
    HeadingXSmall,
    ParagraphLarge,
    ParagraphRegular,
} from 'layouts/typography'
import JourneyIdManager from 'events/download-event/helpers/journey-id'
import Logger from 'utils/logging'
import { GlobalState } from 'types'
import FailDisplay from './FailDisplay'
import VerificationManager from './VerificationManager'
// import s from './verificationCallback.module.scss'

interface Props {
    children: ReactNode
}

const VerificationCallback = ({ children }: Props): JSX.Element => {
    const jidManager = new JourneyIdManager()

    const [showFailMessage, setShowFailMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const query = useSelector((state: GlobalState) => getQuery(state))
    const verificationManager = new VerificationManager()

    useEffect(() => {
        async function AgeVerification(): Promise<AgeVerificationVerifyModel> {
            const response = await getAgeVerificationResponse(
                'kr',
                query.tid,
                getSessionId(),
                query.auth_info,
                jidManager.getJourneyId()
            )
            return response
        }
        const doError = (message?: string): void => {
            const extra = {
                tid: query?.tid,
                auth_info: query?.auth_info,
                journeyId: jidManager.getJourneyId(),
            }
            if (message) {
                Logger.captureMessage(message, extra)
                setErrorMessage(message)
            } else if (!query.tid || !query?.auth_info) {
                Logger.captureMessage(
                    'Error during verification callback, missing Tid or auth_info',
                    extra
                )
            } else {
                Logger.captureMessage(
                    'Error during verification callback',
                    extra
                )
            }
            setShowFailMessage(true)
        }
        if (isClient) {
            // console.log('xxxxx')
            // TODO: add a check below to see if we have the tid query parameter

            if (query?.custom_parameter !== getSessionId()) {
                return doError('Invalid state/custom_parameter')
            }

            if (query?.redirectUrl && query?.auth_info && query?.tid) {
                // Do something with the tid and authinfo
                // store in localStorage or cookie
                verificationManager.setSessionStorage(
                    verificationManager.sessionTid,
                    query.tid
                )
                verificationManager.setSessionStorage(
                    verificationManager.sessionauthInfo,
                    query.auth_info
                )

                if (query?.code && query?.message) {
                    // Remove now for testing, need to make sure we are checking
                    // the return status code and message in the Payletter response
                    if (query.code !== '200') {
                        return doError(query?.message)
                    }
                }

                // Call signup api endpoint to check if user is verified and can signup
                // If user is verified, redirect to signup page
                // If user is not verified, show fail message and remove info from sessionStorage

                AgeVerification().then((data) => {
                    if (data.verified) {
                        // Set Users full Name in sessionStorage
                        verificationManager.setSessionStorage(
                            verificationManager.sessionFullName,
                            data.fullName
                        )
                        window.location.href = query?.redirectUrl
                    } else {
                        verificationManager.removeSessionStorage(
                            verificationManager.sessionTid
                        )
                        verificationManager.removeSessionStorage(
                            verificationManager.sessionauthInfo
                        )
                        verificationManager.removeSessionStorage(
                            verificationManager.sessionFullName
                        )
                        verificationManager.removeSessionStorage(
                            verificationManager.sessionEmail
                        )
                        setShowFailMessage(true)
                    }
                })
                // } else if (!query.tid || !query?.auth_info) {
                //     doError()
            } else {
                doError()
            }
        }
    }, [query])

    return (
        <>
            {showFailMessage ? (
                <Translate>
                    {({ translate }) => (
                        <FailDisplay>
                            <HeadingXSmall>
                                {translate(
                                    `signup.ageVerification.validationErrorTitle`
                                )}
                            </HeadingXSmall>
                            <ul>
                                <li>
                                    {translate(
                                        `signup.ageVerification.validationErrorMessageBullet1`,
                                        null,
                                        { renderInnerHtml: true }
                                    )}
                                </li>
                                <li>
                                    {translate(
                                        `signup.ageVerification.validationErrorMessageBullet2`,
                                        null,
                                        { renderInnerHtml: true }
                                    )}
                                </li>
                                <li>
                                    {translate(
                                        `signup.ageVerification.validationErrorMessageBullet3`,
                                        null,
                                        { renderInnerHtml: true }
                                    )}
                                </li>
                            </ul>

                            {errorMessage && (
                                <>
                                    <ParagraphLarge>
                                        {query?.code && query?.code}
                                    </ParagraphLarge>
                                    <ParagraphRegular>
                                        {errorMessage}
                                    </ParagraphRegular>
                                </>
                            )}
                        </FailDisplay>
                    )}
                </Translate>
            ) : (
                <>{children}</>
            )}
        </>
    )
}

export default VerificationCallback
