import React from 'react'
import { HeadingXSmall, ParagraphRegular } from 'layouts/typography'
import SectionType from 'models/types/ts/sectionType'
import ReactMarkdown from 'utils/markdown'

interface SectionProps {
    section: SectionType
    className?: string
    // eslint-disable-next-line react/require-default-props
    TitleComponent?: React.ComponentType | string
    Icon?: JSX.Element | null
}

const ContentSection = ({
    section,
    className = null,
    TitleComponent = HeadingXSmall,
    Icon = null,
}: SectionProps): JSX.Element => {
    const { headline, body } = section || {}
    const Wrapper = className === null ? React.Fragment : 'div'
    const wrapperProps = className === null ? {} : { className }
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Wrapper {...wrapperProps}>
            {headline ? (
                // @ts-ignore - temporary fix
                <TitleComponent>
                    {headline} {Icon ?? ''}
                </TitleComponent>
            ) : (
                <></>
            )}
            {body ? (
                <ReactMarkdown
                    source={body}
                    escapeHtml={false}
                    renderers={{
                        p: ({ children }) => (
                            <ParagraphRegular>{children}</ParagraphRegular>
                        ),
                    }}
                />
            ) : (
                <></>
            )}
        </Wrapper>
    )
}
ContentSection.defaultProps = {
    Icon: null,
    className: null,
}

export default ContentSection
