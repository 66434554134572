import React from 'react'
import classNames from 'classnames'
import { useIsMobile } from 'hooks/device-detect'
import BackgroundImage from 'features/background-image'
import s from './MovingStars.module.scss'

const cdnUrlPrefix = '//web.ccpgamescdn.com/aws/eveonline/images'

interface Props {
    children: React.ReactNode
}

const MovingStars = ({ children }: Props): JSX.Element => {
    const cx = classNames.bind(s)

    const isMobile = useIsMobile()

    return (
        <BackgroundImage
            url="https://web.ccpgamescdn.com/aws/eveonline/images/background_nebula.jpg"
            repeat="no-repeat"
            size="cover"
            position="center top"
            lazy={false}
        >
            <div className={s.container}>
                {!isMobile && (
                    <div className={s.universe}>
                        <div
                            className={cx(s.underlay, s.bgOne)}
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${cdnUrlPrefix}/background_nebula.jpg)`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <div
                            className={cx(s.underlay, s.bgTwo)}
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${cdnUrlPrefix}/background_nebula.jpg)`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <div
                            className={cx(s.underlay, s.one)}
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${cdnUrlPrefix}/stars_and_lines.png)`,
                                backgroundSize: 'cover',
                            }}
                        />
                        <div
                            className={cx(s.underlay, s.two)}
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${cdnUrlPrefix}/stars_and_lines.png)`,
                                backgroundSize: 'cover',
                            }}
                        />
                    </div>
                )}
                <div className={s.children}>{children}</div>
            </div>
        </BackgroundImage>
    )
}

export default MovingStars
