import styled from 'styled-components'

interface TableOfContentsStyleProps {
    children?: React.ReactNode
}

const TableOfContentsStyle = styled.span<TableOfContentsStyleProps>`
    display: block;
    background-color: #29353a;
    padding: 18px;

    a {
        display: inline-block;
        margin: 6px;
        color: #3399ff;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 6px 12px;
        border-radius: 16px;
        transition: 0.4s all;

        :hover {
            filter: brightness(0.85);
        }
    }
`

export default TableOfContentsStyle
