import _get from 'lodash/get'
import { PageType } from 'models/types/ts'

export const pageType = (page: PageType): string => {
    // Bailing out early
    if (!page) return 'not_set'

    // If has config and config has pageType then return that.
    if (page.config && page.config.pageType) {
        return page.config.pageType
    }

    // Defaults to dynamic for now.
    // If changed then make sure that it's reflected in Contentful (and update unit tests)
    return 'dynamic'
}

export const isDynamic = (page: PageType): boolean => {
    const type = pageType(page)
    return type === 'dynamic'
}

export const isSeo = (page: PageType): boolean => {
    const type = pageType(page)
    return type === 'seo'
}

export const isEvent = (page: PageType): boolean => {
    const type = pageType(page)

    return type === 'event'
}

export const isSingle = (page: PageType): boolean => {
    const type = pageType(page)
    return type === 'single'
}
