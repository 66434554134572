import React from 'react'

import {
    FactionLabel,
    useWarzoneFactionStatus,
} from '@ccpgames/eve-web/components'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { FactionType } from 'pages/frontlines/models/frontlines-types'

import SectionType from 'models/types/ts/sectionType'
import StatsStyles from './Stats.styles'
import CustomFactionWarzoneProgressRound from './progress'

interface Props {
    faction: FactionType
    section: SectionType
}

const Stats = ({ faction, section }: Props): JSX.Element => {
    const factionModel = getFaction(faction)
    const enemy = factionModel?.enemy
    // const pirates = factionModel?.pirate

    const factionOwned = useWarzoneFactionStatus(faction)
    const enemyOwned = useWarzoneFactionStatus(enemy)

    const renderObectiveIcon = (color: string): JSX.Element => {
        return (
            <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8 0.374023C3.582 0.374023 0 3.95602 0 8.37402C0 12.792 3.582 16.374 8 16.374C12.418 16.374 16 12.792 16 8.37402C16 3.95602 12.418 0.374023 8 0.374023ZM1.778 8.36802V8.38002C1.778 8.37802 1.778 8.37602 1.778 8.37402C1.778 8.37202 1.778 8.37002 1.778 8.36802ZM8.592 14.566V11.68H7.406V14.564C4.448 14.284 2.088 11.924 1.808 8.96602H4.694V7.78002H1.81C2.09 4.82202 4.45 2.46202 7.408 2.18202V5.06802H8.594V2.18202C11.552 2.46202 13.912 4.82202 14.194 7.78002H11.306V8.96602H14.192C13.91 11.926 11.552 14.284 8.592 14.566Z"
                    fill={color}
                />
            </svg>
        )
    }

    return (
        <StatsStyles>
            <div className="content">
                <div className="main">
                    <div className="top">
                        <div className="text">
                            {section?.headline
                                ? section.headline
                                : 'Systems Controlled'}
                        </div>
                    </div>
                    <CustomFactionWarzoneProgressRound
                        faction1={faction}
                        faction1Systems={
                            factionOwned ? factionOwned.length : null
                        }
                        faction2={enemy}
                        faction2Systems={enemyOwned ? enemyOwned.length : null}
                        // extraFactions={[pirates]}
                    />
                    <p className="factions">
                        <FactionLabel faction={faction} />
                        <span className="vs"> vs </span>
                        <FactionLabel faction={enemy} iconPosition="right" />
                    </p>

                    {section?.teaser && ( // we use teaser and body in the section to render the objective
                        <div className="info">
                            <span className="title">
                                {renderObectiveIcon(factionModel?.color)}
                                {section?.teaser ? section.teaser : 'Objective'}
                            </span>
                            <p className="text">{section?.body}</p>
                        </div>
                    )}
                </div>
            </div>
        </StatsStyles>
    )
}

export default Stats
