import Logger from 'utils/logging'
import { ErrorCodeType } from 'pages/twitch/types'
import client from '../client'

interface UnlinkingRequestResponseModel {
    unlinked: boolean
    errorMessage: string | null
    hasError: boolean
    statusCode: ErrorCodeType
}

export async function submitUnlinkRequest(
    token: string
): Promise<UnlinkingRequestResponseModel> {
    if (!token) {
        throw new Error('Invalid token')
    }

    try {
        const response = await client({
            url: '/twitch/unlink',
            headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
            },
            method: 'post',
        })

        return response.data
    } catch (error) {
        Logger.captureNetworkException(
            error,
            'twitch/unlink',
            'api.twitch.unlink'
        )
        throw new Error('Failed to submit unlink request')
    }
}
