import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import { GlobalState } from 'types/redux'
import Loading from 'layouts/loading/PageLoading'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import PageType from 'models/types/ts/pageType'
import { findSectionByIdentifier } from 'lib/pages/api'
import { useHasMounted } from 'hooks/client-only'

const LoadableComic = React.lazy(
    () => import(/* webpackChunkName: "page-comic" */ './Comic')
)

const ComicContainer = (): JSX.Element => {
    const language = useSelector((state: GlobalState) => state.language)
    const dispatch = useDispatch()
    const hasMounted = useHasMounted()
    const slug = 'comic-capsuleer-chronicles'

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                if (page) {
                    const sections = page?.sectionsCollection?.items
                    const hero = findSectionByIdentifier(sections, 'comic-hero')
                    const chapter1 = findSectionByIdentifier(
                        sections,
                        'comic-issue-1'
                    )
                    const chapter2 = findSectionByIdentifier(
                        sections,
                        'comic-issue-2'
                    )
                    const chapter3 = findSectionByIdentifier(
                        sections,
                        'comic-issue-3'
                    )
                    const chapter4 = findSectionByIdentifier(
                        sections,
                        'comic-issue-4'
                    )
                    const chapter1Links = findSectionByIdentifier(
                        sections,
                        'comic-issue-1-links'
                    )
                    const chapter2Links = findSectionByIdentifier(
                        sections,
                        'comic-issue-2-links'
                    )
                    const chapter3Links = findSectionByIdentifier(
                        sections,
                        'comic-issue-3-links'
                    )
                    const chapter4Links = findSectionByIdentifier(
                        sections,
                        'comic-issue-4-links'
                    )
                    const comicHardCover = findSectionByIdentifier(
                        sections,
                        'comic-issue-hc'
                    )
                    const comicHardCoverLinks = findSectionByIdentifier(
                        sections,
                        'comic-issue-hc-links'
                    )

                    return (
                        <PageWithSeo
                            page={page}
                            showLoading={false}
                            hideSitename={false}
                        >
                            {hasMounted && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableComic
                                        hero={hero}
                                        comicHardCover={comicHardCover}
                                        comicHardCoverLinks={
                                            comicHardCoverLinks
                                        }
                                        chapter4={chapter4}
                                        chapter3={chapter3}
                                        chapter2={chapter2}
                                        chapter1={chapter1}
                                        chapter4Links={chapter4Links}
                                        chapter3Links={chapter3Links}
                                        chapter2Links={chapter2Links}
                                        chapter1Links={chapter1Links}
                                    />
                                </React.Suspense>
                            )}
                        </PageWithSeo>
                    )
                }

                // page not found in contentful
                dispatch({ type: NOT_FOUND })
                return <></>
            }}
        </PageBySlugQuery>
    )
}

export default ComicContainer
