enum IconTypeEnum {
    HomeIcon,
    Careers,
    CareersEnforcerIcon,
    CareersExplorerIcon,
    CareersIndustrialistIcon,
    CareersSoldierOfFortuneIcon,
    Ships,
    ShipsDestroyersIcon,
    ShipsFrigatesIcon,
    ShipsMiningShipsIcon,
    World,
    AuraIcon,
    ArrowRight,
    ArrowDown,
    TextIcon,
    EasyIcon,
    MediumIcon,
    HardIcon,
    ShieldIcon,
    ArmorIcon,
    HullIcon,
    TooltipIcon,
    ActivitiesIcon,
    AirCareerProgram,
}

export default IconTypeEnum
