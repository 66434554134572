import React from 'react'
import BackgroundImage from 'features/background-image'
import { Translate } from 'utils/localize'
import { Container } from 'layouts'
import { HeadingSmall, ParagraphLarge } from 'layouts/typography'
import s from './NotFound.module.scss'

const NotFound = (): JSX.Element => (
    <BackgroundImage
        url="https://images.ctfassets.net/7lhcm73ukv5p/4xd1Q5VkxP2idy4CTJtqQC/0f13926f7190b976aa3680db76c03e96/404-background.jpg"
        size="cover"
        repeat="no-repeat"
        position="50% 50%"
        className={s.error}
    >
        <Translate>
            {({ translate }) => (
                <Container>
                    <h1>
                        <img
                            className={s.img}
                            src="https://images.ctfassets.net/7lhcm73ukv5p/57yVZ6hQQ5eXRkIAF2UTcl/d6f2270bb7d7a09ecd2b9db55422fc54/404-image.png"
                            alt="404"
                        />
                    </h1>
                    <div className={s.content}>
                        <HeadingSmall>
                            {translate('errorPages.notFoundTitle')}
                        </HeadingSmall>
                        <ParagraphLarge>
                            {translate('errorPages.notFoundDescription')}
                        </ParagraphLarge>
                    </div>
                </Container>
            )}
        </Translate>
    </BackgroundImage>
)
export default NotFound
