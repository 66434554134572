import React from 'react'
import { useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import PageWithSeo from 'features/page-with-seo'
import BackgroundImage from 'features/background-image'
import { Container, Loading, Section } from 'layouts'
import { findSectionByIdentifier } from 'lib/pages/api'
import PageType from 'models/types/ts/pageType'
import Frontline from 'pages/www/components/frontline'
import PageBySlugQuery from 'queries/page-by-slug'
import LanguageType from 'models/language-type'
import { useHasMounted } from 'hooks/client-only'
import Message from './components/message'
import Leaderboards from './components/leaderboards'

const LoadableFrontlinesOverview = React.lazy(
    () =>
        import(
            /* webpackChunkName: "page-frontlines-overview" */ './FrontlinesOverview.styles'
        )
)

interface Props {
    prefix: string
    language: LanguageType
}

interface ShowLeaderboardProps {
    showLeaderBoards: boolean
}

const FrontlinesOverview = ({ prefix, language }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const hasMounted = useHasMounted()

    return (
        <PageBySlugQuery slug={prefix} locale={language}>
            {(page: PageType) => {
                if (page) {
                    // Flag to show/hide the Leaderboards, this is a config option in Contentful
                    let showTheBoards: ShowLeaderboardProps = {
                        showLeaderBoards: true,
                    }
                    if (page?.config) {
                        const pageConfig = page?.config as {
                            showLeaderBoards: ShowLeaderboardProps
                        }
                        showTheBoards = pageConfig?.showLeaderBoards
                    }

                    const sections = page?.sectionsCollection?.items
                    const frontline = findSectionByIdentifier(
                        sections,
                        'www-frontline'
                    )

                    const videoSection = findSectionByIdentifier(
                        sections,
                        `${prefix}-video`
                    )

                    return (
                        <PageWithSeo
                            page={page}
                            showLoading={!page}
                            hideSitename={false}
                        >
                            {hasMounted && (
                                <React.Suspense fallback={<Loading />}>
                                    <LoadableFrontlinesOverview>
                                        {frontline && (
                                            <BackgroundImage
                                                className="top"
                                                url={frontline?.imageFile?.url}
                                                repeat="no-repeat"
                                                size="cover"
                                                position="center top"
                                            >
                                                <Frontline
                                                    section={frontline}
                                                    renderBgImg={false}
                                                />
                                            </BackgroundImage>
                                        )}
                                        <Section>
                                            <BackgroundImage
                                                url="https://images.ctfassets.net/7lhcm73ukv5p/3KIOgpf9HtER1irv08rUgT/3a40170fb0695ae23aa312f8d9ffff12/frontlines_leaders_bg.png"
                                                repeat="no-repeat"
                                                size="cover"
                                                position="center bottom"
                                                // className="map-background"
                                                lazy
                                            >
                                                <Container
                                                    xlWide
                                                    className="container"
                                                >
                                                    {showTheBoards && (
                                                        <div
                                                            style={{
                                                                paddingBottom:
                                                                    '80px',
                                                            }}
                                                        >
                                                            <Leaderboards
                                                                showAllFactionIcons
                                                                colorEnlisted
                                                            />
                                                        </div>
                                                    )}
                                                </Container>
                                            </BackgroundImage>
                                        </Section>

                                        {videoSection && (
                                            <Section className="below">
                                                {/* <MovingStars> */}
                                                <Container
                                                    xlWide
                                                    className="container"
                                                >
                                                    <div className="video-container">
                                                        <p className="message-headline">
                                                            {
                                                                videoSection?.headline
                                                            }
                                                        </p>
                                                        <div className="content">
                                                            <Message
                                                                section={
                                                                    videoSection
                                                                }
                                                                color="#5CCBCB"
                                                            />
                                                        </div>
                                                    </div>
                                                </Container>
                                                {/* </MovingStars> */}
                                            </Section>
                                        )}
                                    </LoadableFrontlinesOverview>
                                </React.Suspense>
                            )}
                        </PageWithSeo>
                    )
                }
                dispatch({ type: NOT_FOUND })
                return <Loading />
            }}
        </PageBySlugQuery>
    )
}

export default FrontlinesOverview
