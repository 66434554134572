import React from 'react'
import _map from 'lodash/map'
import Link from 'features/link'
import style from './Tags.module.scss'

interface Props {
    tagList: Array<string>
}

const Tags = ({ tagList }: Props): JSX.Element => (
    <>
        {tagList && (
            <ul className={style.tags}>
                {_map(tagList, (item, i) => (
                    <li key={i}>
                        <Link path={{ page: 'news', subpage: 't', id: item }}>
                            #<span>{item}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        )}
    </>
)

export default Tags
