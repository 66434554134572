import React from 'react'
import BackgroundImage from 'features/background-image'
import { Translate } from 'utils/localize'
import { Container } from 'layouts'
import { HeadingSmall, ParagraphLarge } from 'layouts/typography'
import s from '../not-found/NotFound.module.scss'

const NotFound = (): JSX.Element => (
    <BackgroundImage
        url="https://images.ctfassets.net/7lhcm73ukv5p/10QLcwvb8spYPVxV0wJqT1/e55d062461b38ee851767521857c68d0/error-background.jpg"
        size="cover"
        repeat="no-repeat"
        position="50% 50%"
        className={s.error}
    >
        <Translate>
            {({ translate }) => (
                <Container>
                    <h1>
                        <img
                            className={s.img}
                            src="https://images.ctfassets.net/7lhcm73ukv5p/5FBtjx2gCzFepESfDVmZYb/2af5ccf07c6b9af1a05df3d3cd14ba94/500-text.png"
                            alt="500"
                        />
                    </h1>
                    <div className={s.content}>
                        <HeadingSmall>
                            {translate('errorPages.errorTitle')}
                        </HeadingSmall>
                        <ParagraphLarge>
                            {translate('errorPages.errorDescription')}
                        </ParagraphLarge>
                    </div>
                </Container>
            )}
        </Translate>
    </BackgroundImage>
)

export default NotFound
