import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import { GlobalState } from 'types/redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import BackgroundImage from 'features/background-image'
import PageType from 'models/types/ts/pageType'
import { findSectionByIdentifier } from 'lib/pages/api'
import { Container } from 'layouts'
import ContentType from 'models/types/ts/contentType'
import { Grid } from '@mantine/core'
import { HeadingMedium, HeadingRegular } from 'layouts/typography'
import s from './3rd-party-retailers.module.scss'
import Retailer from './components/retailer'

const Retailers = (): JSX.Element => {
    const language = useSelector((state: GlobalState) => state.language)
    const dispatch = useDispatch()
    const slug = '3rd-party-retailers'

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                if (page) {
                    const sections = page?.sectionsCollection?.items
                    const content = findSectionByIdentifier(
                        sections,
                        'list-of-3rd-party-retailers'
                    )

                    const { pageBackground, siteName, body } = page

                    return (
                        <PageWithSeo
                            page={page}
                            showLoading={false}
                            hideSitename={false}
                        >
                            <BackgroundImage
                                url={pageBackground?.url}
                                className={s.bg}
                                repeat="no-repeat"
                                size="cover"
                                position="center top"
                            >
                                <Container>
                                    <div className={s.copy}>
                                        <HeadingRegular className={s.heading}>
                                            {siteName}
                                        </HeadingRegular>
                                        <p>{body}</p>
                                    </div>

                                    <div className={s.content}>
                                        <Grid gutter="sm" gutterMd={30}>
                                            {content.contentCollection.total >
                                                0 &&
                                                content.contentCollection.items.map(
                                                    (item: ContentType) => (
                                                        <Retailer
                                                            key={item.name}
                                                            content={item}
                                                        />
                                                    )
                                                )}
                                        </Grid>
                                    </div>
                                </Container>
                            </BackgroundImage>
                        </PageWithSeo>
                    )
                }

                // page not found in contentful
                dispatch({ type: NOT_FOUND })
                return <></>
            }}
        </PageBySlugQuery>
    )
}

export default Retailers
