import React from 'react'
import { useCharacterSheet } from 'services/esi/character/characterSheet'

import s from './CharacterPanel.module.scss'

interface CharacterPanelProps {
    characterName: string
    characterId: number
    selected: boolean
    success: boolean
    failed: boolean
}

const CharacterPanel = ({
    characterName,
    characterId,
    selected = false,
    success = false,
    failed = false,
}: CharacterPanelProps): JSX.Element => {
    const { data: characterSheet } = useCharacterSheet(characterId)

    const selectedClass = selected ? s.selectedCharacter : ''
    const successClass = success ? s.successfulLink : selectedClass
    const failedClass = failed ? s.failedLink : successClass
    const extraClass = failedClass !== '' ? ` ${failedClass}` : ''

    return (
        <div className={`${s.characterPanel}${extraClass}`}>
            <h2>{characterName}</h2>
            <div className={s.characterImageOuter}>
                <div className={s.characterImageInner}>
                    <div
                        className={s.characterImage}
                        style={{
                            backgroundImage: `url(https://image.eveonline.com/Character/${characterId}_256.jpg)`,
                        }}
                    >
                        <div className={s.aspectRatio} />
                        {characterSheet?.corporation_id && (
                            <img
                                className={s.corpLogo}
                                src={`https://image.eveonline.com/Corporation/${characterSheet.corporation_id}_128.jpg`}
                                alt="Corporation"
                            />
                        )}
                        {characterSheet?.alliance_id && (
                            <img
                                className={s.allianceLogo}
                                src={`https://image.eveonline.com/Alliance/${characterSheet.alliance_id}_128.jpg`}
                                alt="Alliance"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CharacterPanel
