import {
    WarzoneI18NProvider,
    WarzoneLeaderboardProvider,
    WarzoneStatusProvider,
} from '@ccpgames/eve-web/components'
import React from 'react'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'
import { getWarzoneStatus } from './helpers/get-warzone-status'
import { getWarzoneLeaderboard } from './helpers/get-warzone-leaderboard'

interface Props {
    children: React.ReactNode
    updateInterval?: number
}

export default function WarzoneProviders({
    children,
    updateInterval = 15000,
}: Props): JSX.Element {
    const language = useSelector((state: GlobalState) => state.language)

    return (
        <WarzoneI18NProvider language={language}>
            {/* The WarzoneStatusProvider makes the _CURRENT_ status available to all subcomponents */}
            {/* This does _NOT_ contain the current map layout, just the status */}
            <WarzoneStatusProvider
                updateFunc={getWarzoneStatus}
                updateInterval={updateInterval}
            >
                <WarzoneLeaderboardProvider updateFunc={getWarzoneLeaderboard}>
                    {children}
                </WarzoneLeaderboardProvider>
            </WarzoneStatusProvider>
        </WarzoneI18NProvider>
    )
}
