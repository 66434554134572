/* eslint-disable */

// Code is called but does nothing, if changed then remove eslint-disable comment above and fix

import { isLocal, isDevelopment } from 'config/web'

export default function log(message: string, item?: any) {
    // if (false) {
    //     console.log(message, item)
    // }
}
