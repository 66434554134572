import React from 'react'
import style from './Arrows.module.scss'

interface Props {
    prevClick: () => void
    nextClick: () => void
    className?: string
    color?: string
}

const Arrows = ({
    prevClick,
    nextClick,
    className,
    color = '#ba1f7e',
}: Props): JSX.Element => (
    <div className={style(style.arrows, className)}>
        <svg
            onClick={() => prevClick()}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m5.605 24.648-1.754 1.755A15.924 15.924 0 0 1 0 15.998c0-3.974 1.45-7.605 3.85-10.404l1.755 1.754a13.465 13.465 0 0 0-3.134 8.647c0 3.293 1.179 6.31 3.134 8.653Zm23.924-8.646c0 3.286-1.179 6.3-3.134 8.646l1.754 1.755A15.907 15.907 0 0 0 32 16.002c0-3.974-1.45-7.606-3.85-10.405l-1.755 1.754a13.462 13.462 0 0 1 3.134 8.65ZM16 2.474c3.286 0 6.304 1.179 8.648 3.134l1.754-1.758A15.946 15.946 0 0 0 15.996 0C12.022 0 8.39 1.45 5.591 3.85l1.754 1.758A13.502 13.502 0 0 1 16 2.474Zm0 27.055c-3.286 0-6.3-1.178-8.648-3.134l-1.754 1.758A15.927 15.927 0 0 0 16 32c3.974 0 7.606-1.454 10.405-3.85l-1.754-1.758A13.462 13.462 0 0 1 16 29.529Z"
                fill="silver"
            />
            <path
                d="m8.218 16.02 4.761 4.762a.744.744 0 0 0 1.052-1.052l-3.49-3.491h12.715a.744.744 0 1 0 0-1.488H10.54l3.491-3.492a.744.744 0 0 0-1.052-1.052L8.218 14.97a.744.744 0 0 0 0 1.052Z"
                fill={color}
            />
        </svg>
        <svg
            onClick={() => nextClick()}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m26.395 7.351 1.754-1.754c2.4 2.8 3.851 6.43 3.851 10.405 0 3.974-1.45 7.605-3.85 10.404l-1.755-1.754a13.465 13.465 0 0 0 3.134-8.647c0-3.292-1.179-6.31-3.134-8.654ZM2.47 15.998c0-3.285 1.179-6.3 3.134-8.647L3.851 5.597A15.907 15.907 0 0 0 0 15.998c0 3.974 1.45 7.606 3.85 10.405l1.755-1.755a13.462 13.462 0 0 1-3.134-8.65ZM16 29.526a13.461 13.461 0 0 1-8.648-3.134L5.598 28.15A15.946 15.946 0 0 0 16.003 32c3.975 0 7.607-1.45 10.406-3.85l-1.754-1.758A13.502 13.502 0 0 1 16 29.526Zm0-27.055c3.286 0 6.3 1.178 8.648 3.133l1.754-1.757A15.927 15.927 0 0 0 16 0 15.946 15.946 0 0 0 5.595 3.85l1.754 1.758A13.462 13.462 0 0 1 16 2.471Z"
                fill="silver"
            />
            <path
                d="m23.782 15.98-4.761-4.762a.744.744 0 0 0-1.052 1.052l3.49 3.491H8.745a.744.744 0 0 0 0 1.488H21.46l-3.491 3.492a.744.744 0 0 0 1.052 1.052l4.761-4.762a.744.744 0 0 0 0-1.052Z"
                fill={color}
            />
        </svg>
    </div>
)

Arrows.defaultProps = {
    className: '',
    color: '#ba1f7e',
}

export default Arrows
