import React, { ReactNode } from 'react'
import NewsTagType from 'types/news/NewsTagType'
import TagsList from '../TagsList'
import Search from '../../search'
import style from './TagsContainer.module.scss'

interface Props {
    tags: Array<NewsTagType>
    children?: ReactNode
}

const TagsContainer: React.FunctionComponent<Props> = ({
    tags,
    children,
}): JSX.Element => {
    return (
        <div className={style.TagsContainer}>
            <TagsList tags={tags} />
            {children ? (
                <div className={style.search}>{children}</div>
            ) : (
                <Search />
            )}
        </div>
    )
}

export default TagsContainer
