import React, { useRef, useState, CSSProperties, useEffect } from 'react'
import cx from 'classnames'
import VisibilitySensor from 'react-visibility-sensor'
import LazyLoad from 'react-lazyload'
import SectionType from 'models/types/ts/sectionType'
import getInternalLinkPath from 'lib/link'
import { Link } from 'features'
import { Container } from 'layouts'
import {
    HeadingMedium,
    HeadingRegular,
    ParagraphLarge,
    TaglineRegular,
} from 'layouts/typography'
import AnimatedText from 'layouts/animated-text/AnimatedText2'
import { Target } from '@ccpgames/eve-web/components/target/Target'
import { isClient } from 'config/web'
import Section from 'layouts/common/components/section'
import { CareerLink } from './components'
import characterVideos from './character-videos'
import s from './Careers.module.scss'

interface Props {
    section: SectionType
    isFrontlines?: boolean
}

const Careers = ({ section, isFrontlines }: Props): JSX.Element => {
    const [hovered, setHovered] = useState<number>(0)
    const [scrollTop, setScrollTop] = useState<number>(0)
    const [isVisible, setVisible] = useState<boolean>(false)
    const [hasPlayed, setHasPlayed] = useState<boolean>(false)
    const [blur, setBlur] = useState<number>(0)
    const [darken, setDarken] = useState<number>(0)
    const careersRef = useRef<HTMLElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)

    const contentList = section?.contentCollection?.items
    const { headline, teaser, body, imageFile } = section

    let minScroll = 1500
    let maxScroll = 2500
    if (isClient) {
        const careersFromTop =
            careersRef.current?.getBoundingClientRect().top + window.pageYOffset
        const windowHeight = window.innerHeight
        const careersHeight = careersRef.current?.clientHeight
        minScroll = careersFromTop - windowHeight + 30
        maxScroll = careersFromTop + careersHeight
    }

    useEffect(() => {
        const onScroll = (e): void => {
            setScrollTop(e.target.documentElement.scrollTop)

            if (scrollTop >= minScroll && scrollTop < maxScroll) {
                let blurValue = scrollTop - minScroll
                let darkenValue = scrollTop - minScroll
                blurValue /= 125
                darkenValue /= 300

                if (blurValue <= 12) {
                    setBlur(blurValue)
                }
                if (darkenValue <= 4) {
                    setDarken(parseInt(darkenValue.toString(), 10))
                }
            }
        }

        if (isClient) {
            window.addEventListener('scroll', onScroll)
            return () => window.removeEventListener('scroll', onScroll)
        }
    }, [scrollTop])

    // video controls, only play once
    const handleChange = (visible: boolean): void => {
        if (visible && !hasPlayed) {
            setVisible(true)
            videoRef?.current.play()
            setHasPlayed(true)
        }
    }

    // Sets current video/image for main area based on hovered item
    const getCareerVideo = (index: number, type: 'webm' | 'mp4'): string => {
        switch (index) {
            case 0:
                return characterVideos.aura[type] // Default loaded when no hover
            case 1:
                return characterVideos.explorer[type]
            case 2:
                return characterVideos.industrialist[type]
            case 3:
                return characterVideos.enforcer[type]
            case 4:
                return characterVideos.soldierOfFortune[type]
            default:
                return characterVideos.aura[type]
        }
    }

    function renderCareerItems(): JSX.Element[] {
        return contentList?.map((item, index: number) => (
            <CareerLink
                key={item.headline}
                content={item}
                index={index + 1}
                itemCount={contentList?.length}
                active={index + 1 === hovered}
                setHoveredIndex={setHovered}
            />
        ))
    }

    const bgImage = {
        '--www-careers-bg': `url('${imageFile?.url}?fm=webp')`,
        '--www-careers-bg-sm': `url('${imageFile?.url}?fm=webp&fit=fill&w=768&h=1000')`,
        '--www-careers-blur': `blur(${blur.toString()}px)`,
        '--www-careers-darken': `rgba(16, 16, 16, 0.${darken})`,
    } as CSSProperties

    return (
        <Section
            id={section.identifier}
            className={cx(s.careers, { [s.isFrontlines]: isFrontlines })}
            sectionStyle={bgImage}
            ref={careersRef}
        >
            <div className={cx(s.inner, { [s.blur]: blur })}>
                <div className={s.bg} />
                <Container xlWide>
                    <div className={s.content}>
                        <div className={s.intro}>
                            {headline && (
                                <HeadingRegular as="h2" className={s.title}>
                                    <AnimatedText>{headline}</AnimatedText>
                                </HeadingRegular>
                            )}
                            {teaser && (
                                <TaglineRegular as="h3" className={s.sub}>
                                    <AnimatedText>{teaser}</AnimatedText>
                                </TaglineRegular>
                            )}
                            {body && (
                                <ParagraphLarge fontSize={[18, 22]}>
                                    {body}
                                </ParagraphLarge>
                            )}
                        </div>
                        <div className={s.careerCharacter}>
                            <div key={hovered}>
                                <VisibilitySensor
                                    onChange={handleChange}
                                    offset={{ top: -700 }} // don't make it play until at least some of it is in view
                                    partialVisibility={false}
                                    active
                                >
                                    <LazyLoad offset={250} height={680} once>
                                        <video
                                            preload="auto"
                                            className={s.fadeIn}
                                            ref={videoRef}
                                            playsInline
                                            autoPlay={isVisible}
                                            muted
                                            loop={false}
                                        >
                                            <source
                                                src={getCareerVideo(
                                                    hovered,
                                                    'mp4'
                                                )}
                                                type="video/mp4;codecs=hvc1"
                                            />
                                            <source
                                                src={getCareerVideo(
                                                    hovered,
                                                    'webm'
                                                )}
                                                type="video/webm"
                                            />
                                        </video>
                                    </LazyLoad>
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className={s.selectionWrapper}>
                <Container xlWide>
                    <div className={s.careersSelection}>
                        <Target color="#ba1f7e" className={s.target}>
                            <div className={s.list}>{renderCareerItems()}</div>
                        </Target>
                        {section.buttonUrl && (
                            <div className={s.visit}>
                                <Link
                                    path={getInternalLinkPath(
                                        section.buttonUrl
                                    )}
                                >
                                    {section.buttonText}
                                </Link>
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        </Section>
    )
}

Careers.defaultProps = {
    isFrontlines: false,
}

export default Careers
