import { environment } from 'config/web'
import SettingsModel from './models/SettingsModel'
import settingsJson from './SettingJson'

export function getInternalSettings(): SettingsModel {
    const defaultSettings: SettingsModel = settingsJson.default

    const environmentSettings: SettingsModel = settingsJson[environment]

    const internalSettings: SettingsModel = {
        ...defaultSettings,
        ...environmentSettings,
    }

    return internalSettings
}

// Getting the external settings from specific page in Contentful, settings-page config field.
export default async function getSettings(): Promise<SettingsModel> {
    // We can add back logic to get external settings here, see file history in GitHub
    const internalSettings: SettingsModel = getInternalSettings()
    return internalSettings
}
