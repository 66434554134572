import column from 'layouts/common/components/column'
import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { ImageGalleryEntryType, RichTextAssetType } from '../../types'
import ImageGalleryStyle from './ImageGallery.styles'

interface Props {
    content: ImageGalleryEntryType
}

const ImageGallery = ({ content }: Props): JSX.Element => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    function clickImage(index: number): void {
        setIsOpen(true)
        setSelectedIndex(index)
    }

    const images = content?.imagesCollection?.items

    if (!images) return <></>

    const maxImageThumbnailWidth = 900
    const maxImageFullSizeWidth = 1920

    const imageCount = images?.length

    // Make very sure that we only allow values
    const defaultColumnCount = 1
    let columnCount = content?.columnCount

    if (columnCount < 1 || columnCount > 4) {
        columnCount = defaultColumnCount
    }

    // Here we say that the thumbnail image can be max width of 900px divided by the number of columns
    const imgWidth = Math.ceil(maxImageThumbnailWidth / columnCount)

    const imgThumbQuery = `?w=${imgWidth}`
    const imgFullSizeQuery = `?w=${maxImageFullSizeWidth}`

    return (
        <ImageGalleryStyle columnCount={columnCount}>
            {images?.map((image, i) => {
                return (
                    <img
                        key={image?.sys?.id}
                        src={image.url + imgThumbQuery}
                        alt={image.description}
                        onClick={() => clickImage(i)}
                        loading="lazy"
                    />
                )
            })}

            {/* Allow choosing between links or lightbox? */}
            {/* Links are more work, then we need yet another content type */}

            {isOpen && (
                <Lightbox
                    mainSrc={images[selectedIndex]?.url + imgFullSizeQuery}
                    nextSrc={
                        images[(selectedIndex + 1) % imageCount]?.url +
                        imgFullSizeQuery
                    }
                    prevSrc={
                        images[(selectedIndex + imageCount - 1) % images.length]
                            ?.url + imgFullSizeQuery
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setSelectedIndex(
                            (selectedIndex + imageCount - 1) % imageCount
                        )
                    }
                    onMoveNextRequest={() =>
                        setSelectedIndex((selectedIndex + 1) % imageCount)
                    }
                />
            )}
        </ImageGalleryStyle>
    )
}

export default ImageGallery
