import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { TranslateFunction } from 'utils/localize'
// import flags from 'config/flags'
import Link from 'features/link'
import { pushNavigationClickEventToDataLayer } from 'utils/analytics/datalayer/dataLayerFunctions'
import Icon from 'layouts/font-awesome'
import NavigationItemType from '../../../../models/navigation-item-type'
import style from './NavItem.module.scss'

const cx = classnames.bind(style)

const getIcon = (external: boolean): JSX.Element => {
    const iconName = external ? 'external-link-alt' : 'chevron-right'
    const className = style('icon', { external, mobileView: !external })
    return <Icon regular name={iconName} className={className} />
}

const getChildren = (
    external: boolean,
    translate: TranslateFunction,
    translationId: string,
    dropdownEnabled: boolean
): JSX.Element[] => {
    const bar = <div key="bar" className={style.bar} />
    const text = (
        <div key="text" className={style.text}>
            <div className={style.title}>
                {translate(translationId).toString()}
            </div>
            {dropdownEnabled && (
                <Icon className={style.arrow} regular name="angle-down" />
            )}

            {!dropdownEnabled && external && (
                <div className={style.logo}>{getIcon(external)}</div>
            )}
        </div>
    )
    return [bar, text]
}

// TODO, almost the same as getChildren above, maybe can be the same
const renderNavItem = (
    external: boolean,
    translate: TranslateFunction,
    translationId: string,
    dropdownEnabled: boolean
): JSX.Element[] => {
    const text = (
        <div key="text" className={style.text}>
            <div className={style.title}>
                {translate(translationId).toString()}
            </div>
            {dropdownEnabled && (
                <div className={style.text}>
                    <Icon className={style.arrow} regular name="angle-down" />
                </div>
            )}
            {external && <div className={style.logo}>{getIcon(external)}</div>}
        </div>
    )
    return [text]
}

interface Props {
    className?: string
    selected?: boolean
    external?: boolean
    page?: string
    subpage?: string
    sub?: string
    href?: string
    dataId?: string
    translate?: TranslateFunction
    translationId?: string
    children?: Array<NavigationItemType>
    fpThemeColor?: boolean
    isMobile?: boolean
    active?: boolean
    setActiveItem?: (index: number) => void
    index?: number
}

const NavItem = ({
    selected,
    external,
    page,
    subpage,
    sub,
    href,
    dataId,
    className: externalClass,
    translate,
    translationId,
    children,
    fpThemeColor,
    isMobile,
    active,
    setActiveItem,
    index,
}: Props): JSX.Element => {
    const menuRef = React.useRef(null)
    const [initialHeight, setInitialHeight] = useState(undefined)

    useEffect(() => {
        if (menuRef?.current && isMobile) {
            setInitialHeight(menuRef.current.offsetHeight)
        }
    }, [])

    let className = cx(style.item, {
        [style.mobile]: isMobile,
        [style.active]: active,
        [style.selected]: selected,
        [style.fpTheme]: fpThemeColor,
        [style.withChildren]: children !== undefined, // Add extra class if this item has sub nav items
    })

    if (externalClass !== undefined) {
        className = `${externalClass} ${className}`
    }

    let dropdownEnabled = false
    if (children) dropdownEnabled = true

    // TODO this code can be made more type safe and try to not use props-spread for readability
    let Tag: typeof Link | string = Link

    const props: React.HTMLProps<HTMLAnchorElement> & {
        'data-id': string
        path?: { page: string; subpage: string }
    } = {
        'data-id': dataId,
    }

    if (page) {
        props.path = { page, subpage: sub }
    } else {
        Tag = 'div'
        props.href = href

        if (external) {
            Tag = 'a'
            props.target = '_blank'
            props.rel = 'noopener noreferrer'
        }
    }

    const handleClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        pushNavigationClickEventToDataLayer(dataId)

        if (setActiveItem && index && dropdownEnabled && isMobile) {
            e.stopPropagation()

            if (active) {
                setActiveItem(undefined)
                // menuRef.current.style.height = 'auto'
            } else {
                setActiveItem(index)
            }
        }
    }

    const getStyle = (): { height: number | string } => {
        if (initialHeight && isMobile) {
            return active ? { height: initialHeight } : { height: 50 }
        }

        return { height: 'auto' }
    }

    return (
        <div ref={menuRef} className={className} style={getStyle()}>
            <Tag onClick={handleClick} {...props}>
                {getChildren(
                    external,
                    translate,
                    translationId,
                    dropdownEnabled
                )}
            </Tag>
            {children && (
                <div
                    className={cx(
                        isMobile ? style.dropdownMobile : style.dropdown
                    )}
                >
                    {children.map((item) => {
                        if (item.href) {
                            return (
                                <a
                                    key={item.dataId}
                                    href={item.href}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {renderNavItem(
                                        item.external,
                                        translate,
                                        item.translationId,
                                        false
                                    )}
                                </a>
                            )
                        }
                        return (
                            <Link
                                key={item.dataId}
                                path={{
                                    page: item.page,
                                    subpage: item.subpage,
                                }}
                                className={style({
                                    [style.selected]:
                                        subpage && subpage === item.page,
                                })}
                            >
                                {translate(item.translationId)}
                            </Link>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

NavItem.defaultProps = {
    className: '',
    selected: false,
    external: false,
    page: undefined,
    subpage: undefined,
    sub: undefined,
    href: undefined,
    dataId: undefined,
    translate: undefined,
    translationId: undefined,
    children: undefined,
    fpThemeColor: false,
    isMobile: false,
    active: undefined,
    setActiveItem: null,
    index: undefined,
}

export default NavItem
