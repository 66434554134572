import React, { useEffect, useState } from 'react'
import { Section, Container } from 'layouts'
import SectionType from 'models/types/ts/sectionType'
import { useHasMounted } from 'hooks/client-only'
import { HeadingSmall } from 'layouts/typography'
import JourneyIdManager from 'events/download-event/helpers/journey-id/journey-id-manager'
import { useLauncherConnection } from 'hooks/launcher'
import DownloadButton from '../../../../features/DownloadButton'
import SystemRequirements from './SysReq'
import style from './Download.module.scss'

interface Props {
    title: string
    body: string
    sections: Array<SectionType>
    hasContent: boolean
}

const LauncherDownloadHandler = (): JSX.Element => {
    const journeyIdManager = new JourneyIdManager()
    const parameters: Record<string, string> = {
        journeyId: journeyIdManager.getJourneyId()
    }
    const path = '/journey'

    const { success, failed, openProtocolHandler } = useLauncherConnection({
        automatic: true,
        path,
        parameters,
        automaticAttempts: 60,
        automaticInterval: 1000,
        keepTrying: true
    })
    useEffect(() => {
        if (success) {
            console.log('The launcher has been summoned')
        }
    }, [success])
    useEffect(() => {
        if (failed) {
            console.log(
                'The launcher refuses to listen to our hails, we must use different means of summoning'
            )
            openProtocolHandler()
        }
    }, [failed, openProtocolHandler])

    return <></>
}

const Download = ({
    title,
    body,
    sections,
    hasContent,
}: Props): JSX.Element => {
    const hasMounted = useHasMounted()

    return (
        <div className={style.download}>
            <Section hasContent={hasContent}>
                <Container>
                    <div style={{ textAlign: 'center' }}>
                        <HeadingSmall>{title}</HeadingSmall>

                        <div
                            style={{
                                maxWidth: '400px',
                                marginTop: '40px',
                                textAlign: 'center',
                                margin: '0 auto',
                            }}
                        >
                            {hasMounted && <DownloadButton />}
                        </div>
                    </div>
                    <SystemRequirements sections={sections} />
                    <LauncherDownloadHandler />
                </Container>
            </Section>
        </div>
    )
}

export default Download
