import _get from 'lodash/get'
import { GlobalState } from 'types'

export const showNavigation = (state: GlobalState): boolean => _get(state, 'navigation.show', true)
export const mobileMenuOpen = (state: GlobalState): boolean =>
    _get(state, 'navigation.mobileOpen', false)
export const mobileAccountMenuOpen = (state: GlobalState): boolean =>
    _get(state, 'navigation.mobileAccountOpen', false)

/** @deprecated This property is deprecated and will be removed in future versions. */
export const getCurrentPage = (state: GlobalState): string => state?.location?.pathname
