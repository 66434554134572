import React from 'react'
import s from './Row.module.scss' // Updated import for CSS module

interface RowProps {
    children?: React.ReactNode
    className?: string
}

const Row = ({ children, className = '' }: RowProps): JSX.Element => {
    return <div className={`${s.row} ${className}`}>{children}</div>
}

export default Row
