import React from 'react'
import cx from 'classnames'
import s from './FormGroup.module.scss'

interface Props {
    children: React.ReactNode
    className?: string
    slim?: boolean
}

const FormGroup = ({ children, slim, className }: Props): JSX.Element => (
    <div className={cx(s.formGroup, { [s.slim]: slim }, className)}>
        {children}
    </div>
)

FormGroup.defaultProps = {
    className: '',
    slim: false,
}

export default FormGroup
