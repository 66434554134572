import frontpageTranslations from './pages/www/translations/frontpage.json'
import recruitTranslations from './pages/recruit/translations/recruit.json'
import recruitRetroactiveTranslations from './pages/recruit/retroactive/translations/retro-recruit.json'
import signupTranslations from './pages/signup/translations/signup.json'
import commonTranslations from './layouts/app/translations.json'
import navigationTranslations from './features/navigation/translations/index.json'
import PartnershipProgram from './pages/partnership-program/translations/partnerShipProgram.json'
import News from './pages/news/translations/news.json'
import Academy from './pages/academy/translations/academy.json'
import AcademyNav from './pages/academy/translations/academyNavigation.json'
import TwitchLinking from './pages/twitch/translations/twitchLink.json'
import Monument from './pages/eve-monument/translations/monument.json'

export default {
    ...frontpageTranslations,
    ...recruitTranslations,
    ...recruitRetroactiveTranslations,
    ...signupTranslations,
    ...commonTranslations,
    ...navigationTranslations,
    ...PartnershipProgram,
    ...News,
    ...Academy,
    ...AcademyNav,
    ...TwitchLinking,
    ...Monument,
}
