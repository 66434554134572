import React from 'react'
import { useHasMounted } from 'hooks/client-only'
import { Loading } from 'layouts'

const LoadableComponent = React.lazy(
    () => import(/* webpackChunkName: "page-test" */ './TestContainer')
)

export default function TestPage(): JSX.Element {
    const hasMounted = useHasMounted()
    return (
        hasMounted && (
            <React.Suspense fallback={<Loading />}>
                <LoadableComponent />
            </React.Suspense>
        )
    )
}
