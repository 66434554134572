enum AcademyPageTemplateEnum {
    Frontpage,
    Careers,
    CareerDetails,
    Ships,
    ShipClass,
    ShipDetails,
    World,
    CommunityResources,
    Article,
    Activity,
}

export default AcademyPageTemplateEnum
