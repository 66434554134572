import React from 'react'
import { redirect } from 'redux-first-router'
import getConfig from 'config/web'
import flags from 'config/flags'
import SEOComponent from 'features/seo'
import WWW from 'pages/www'
import Omega from 'pages/omega'
import ArticlesCategory from 'pages/articles-category'
import Article from 'pages/article'
import Download from 'pages/download'
import Discord from 'pages/discord'
import Signup from 'pages/signup'
import SignupVerify from 'pages/signup-verify'
import PartnershipProgram from 'pages/partnership-program'
import Policies from 'pages/policies'
import TestErrorPage from 'pages/testerrorpage'
import DynamicPage from 'pages/dynamic-page'
import SeoPage from 'pages/seo-page'
import Discovery from 'pages/discovery'
import Recruit from 'pages/recruit'
import News from 'pages/news'
import Academy from 'pages/academy'
import Frontlines from 'pages/frontlines'
import ReturnToEve from 'pages/return-to-eve'
import CareerSignup from 'pages/career-signup'
import Fanfest from 'pages/fanfest'
import Vegas from 'pages/vegas'
import Launch from 'pages/launch'
import LauncherCallback from 'pages/launcher-callback'
import Comic from 'pages/comic'
import Anywhere from 'pages/anywhere'
import Twitch from 'pages/twitch'
import TestPage from 'pages/testpage'
import SitemapModel from 'models/sitemap-model'
import Loading from 'layouts/loading'
import Login from 'packages/authentication/components/login'
import Logout from 'packages/authentication/components/logout'
import Callback from 'packages/authentication/components/callback'
import VerificationCallback from 'packages/authentication/components/verification-callback'

import RetroactiveRecruitment from 'pages/recruit/retroactive'

import EveMonument from 'pages/eve-monument'
import Retailers from 'pages/3rd-party-retailers'
import { redirect as redirectAction } from '../lib/pages/actions'

import getRedirectFromPath from '../utils/redirect/redirect'
import {
    runArticleRedirects,
    getArticleFromApiForNewNews,
} from './sitemap/helpers'

const { articlesApiUrl, authentication } = getConfig()

const sitemap: SitemapModel = {
    home: {
        path: '/',
        render: () => <WWW />,
        hideMenuBorder: true,
        hideCTA: true,
        showTwitch: true,
    },
    omega: {
        path: '/omega',
        render: (): JSX.Element => <Omega />,
        hideCTA: true,
        showTwitch: true,
    },
    download: {
        path: '/download',
        render: (): JSX.Element => <Download />,
        hideCTA: true,
    },
    discord: {
        path: '/discord',
        render: (): JSX.Element => <Discord />,
        hideCTA: true,
    },
    'eve-academy': {
        path: '/eve-academy',
        render: (): JSX.Element => <Academy />,
        hideMenuBorder: true,
        showTwitch: false,
    },
    frontlines: {
        path: '/frontlines',
        render: (): JSX.Element => <Frontlines />,
        hideMenuBorder: true,
    },
    anywhere: {
        path: '/anywhere',
        render: (): JSX.Element => <Anywhere />,
        hideMenuBorder: true,
    },
    fanfest: {
        path: '/fanfest',
        render: (): JSX.Element => <Fanfest />,
        hideMenuBorder: true,
        showTwitch: false,
    },
    return: {
        path: '/return',
        render: (): JSX.Element => <ReturnToEve />,
        hideMenuBorder: true,
        hideCTA: true,
        showTwitch: true,
    },
    vegas: {
        path: '/vegas',
        render: (): JSX.Element => <Vegas />,
    },
    'capsuleer-chronicles': {
        path: '/capsuleer-chronicles',
        render: (): JSX.Element => <Comic />,
        hideMenuBorder: true,
    },
    launch: {
        path: '/launch',
        render: (): JSX.Element => <Launch />,
        hideMenuBorder: true,
        hideCTA: true,
    },
    expansion: {
        path: '/expansion',
        redirect: redirectAction(),
    },
    articles: {
        path: '/articles',
        render: (): JSX.Element => <></>,
        async populate(dispatch) {
            // this redirects from /articles to /news
            const action = redirect(redirectAction('news'))
            return dispatch(action)
        },
        children: {
            // This seems to be the only thing still used in the old articles code
            // This does the /articles/:slug and renders it the oldest way to make old articles still available in list based on the categories
            values: ['news', 'dev-blogs', 'patch-notes', 'scope'],
            render: (): JSX.Element => (
                <ArticlesCategory endpoint={articlesApiUrl} />
            ),
        },
    },
    article: {
        path: '/article',
        render: (): JSX.Element => <Article />,
        async populate(dispatch, getState, language) {
            // this redirects from /articles/({id})/{slug} to /news/{slug}
            return runArticleRedirects(dispatch, getState, language)
        },
    },
    'alliance-tournament': {
        path: 'alliance-tournament',
        render: (): JSX.Element => (
            <DynamicPage slugOverride="alliance-tournament" />
        ),
        hideMenuBorder: true,
        hideCTA: true,
        showTwitch: false,
    },
    now: {
        path: '/now',
        render: (): JSX.Element => <DynamicPage />,
        hideMenuBorder: true,
        hideCTA: true,
        showTwitch: false,
    },
    p: {
        path: '/p',
        render: (): JSX.Element => <SeoPage />,
        hideMenuBorder: true,
    },
    signup: {
        id: 'signup',
        path: '/signup',
        render: (): JSX.Element => <Signup />,
        hideMenu: true,
    },
    signup2: {
        path: '/signup2',
        redirect: redirectAction('signup'),
    },
    'signup-verify': {
        id: 'signupVerify',
        path: '/signup-verify',
        render: (): JSX.Element => <SignupVerify />,
        disabled: false,
        hideMenu: true,
    },
    'launcher-callback': {
        id: 'launcherCallback',
        path: '/launcher-callback',
        render: (): JSX.Element => <LauncherCallback />,
        disabled: false,
    },
    discovery: {
        path: '/discovery',
        render: (): JSX.Element => <Discovery />,
    },
    monument: {
        path: '/monument',
        render: (): JSX.Element => <EveMonument />,
    },
    news: {
        path: '/news',
        render: (): JSX.Element => <News />,
        disabled: false,
        hideMenuBorder: true,
        showTwitch: true,
        async populate(dispatch, getState, language) {
            // Getting article from API the old way and putting in state the old way, to be used to render old news if needed, not found in contentful.
            return getArticleFromApiForNewNews(dispatch, getState, language)
        },
    },
    recruit: {
        id: 'recruit',
        path: '/recruit',
        render: (): JSX.Element => <Recruit />,
        hideMenuBorder: true,
        showLogin: true,
        showTwitch: true,
    },
    'recruit-retro': {
        id: 'recruit-retro',
        path: '/recruit/retro',
        render: (): JSX.Element => <RetroactiveRecruitment />,
        hideMenu: true,
        showLogin: true,
    },
    policies: {
        path: '/policies',
        render: (): JSX.Element => <Policies />,
        // Data is populated in component with graphql query.
        disabled: !flags.isDevelopment,
    },
    partners: {
        path: '/partners',
        render: (): JSX.Element => <PartnershipProgram />,
        hideMenuBorder: true,
        // Data is populated in component with graphql query.
    },
    'eve-portal': {
        path: '/eve-portal',
        redirect: redirectAction(),
    },
    register: {
        path: '/register',
        render: (): JSX.Element => <CareerSignup />,
        hideMenuBorder: true,
    },
    'third-party-retailers': {
        path: '/third-party-retailers',
        render: (): JSX.Element => <Retailers />,
        hideMenuBorder: true,
    },
    loading: {
        id: 'loading',
        path: '/loading',
        render: (): JSX.Element => <Loading />,
        disabled: !flags.isDevelopment,
    },
    login: {
        id: 'login',
        path: '/login',
        render: (): JSX.Element => (
            <Login config={authentication}>
                <Loading />
            </Login>
        ),
    },
    logout: {
        id: 'logout',
        path: '/logout',
        render: (): JSX.Element => (
            <Logout logoutUrl={authentication.logoutUrl}>
                <Loading />
            </Logout>
        ),
    },
    callback: {
        id: 'callback',
        path: '/callback',
        render: (): JSX.Element => (
            <Callback getRedirectFromPath={getRedirectFromPath}>
                <Loading />
            </Callback>
        ),
    },
    'verification-callback': {
        id: 'verification-callback',
        path: '/verification-callback',
        hideMenu: true,
        render: (): JSX.Element => (
            <VerificationCallback>
                <Loading />
            </VerificationCallback>
        ),
    },
    twitch: {
        path: '/twitch',
        render: (): JSX.Element => <Twitch />,
        hideMenuBorder: true,
    },
    test: {
        id: 'test',
        path: '/test',
        render: (): JSX.Element => <TestPage />,
        hideMenuBorder: true,
        disabled: !flags.isDevelopment,
        // checkSettings: true
    },
    testerror: {
        id: 'testerror',
        path: '/',
        render: (): JSX.Element => <TestErrorPage />,
    },
}

export default sitemap
