import React from 'react'
import { Link } from 'react-scroll'
import { NewsEmbedInline } from './components'
import { AssetHyperlinkType, NewsEntryType } from './types'

export const renderHyperlink = (
    uri: string,
    children: React.ReactNode
): JSX.Element => {
    // if starts with # it is an internal link on the page
    if (uri?.startsWith('#')) {
        // if starts with # then remove it for proper usage with react-scroll
        const url = uri?.substring(1)

        return (
            <Link
                key={url}
                href={`#${url}`}
                to={url}
                smooth
                spy
                hashSpy
                offset={-110}
            >
                {children}
            </Link>
        )
    }

    // We could also detect if it starts with a / and then treat it as internal link between pages
    if (uri?.startsWith('/')) {
        // console.log('renderHyperlink, on other internal page link')
        // NOT TARGET BLANK THEN
        // We can even try to use redux-first-router here for smoothness
    }

    // This is a regular hyperlink to external site
    return (
        <a target="_blank" href={uri} rel="noreferrer">
            {children}
        </a>
    )
}

export const renderEntryHyperlink = (
    entry: NewsEntryType,
    children: React.ReactNode
): JSX.Element => {
    return <NewsEmbedInline content={entry}>{children}</NewsEmbedInline>
}

export const renderAssetHyperlink = (
    entry: AssetHyperlinkType,
    children: React.ReactNode
): JSX.Element => {
    // TODO, we know it is an asset
    // We can control image size here if we want
    return (
        <a target="_blank" href={entry.url} rel="noreferrer">
            {children}
        </a>
    )
}
