import React from 'react'
import { KnownFactionName } from '@ccpgames/eve-web/components/warzone/types/faction'
import { WarzonePanel, WarzoneMapProvider } from '@ccpgames/eve-web/components'
import SectionType from 'models/types/ts/sectionType'
import Markdown from 'utils/markdown'
import { TaglineRegular } from 'layouts/typography'
import { useFetcherForFaction } from '../../helpers/get-warzone-status'
import MapStyles from './Map.styles'

interface Props {
    faction: KnownFactionName
    section?: SectionType
}

const Map = ({ faction, section }: Props): JSX.Element => {
    const mapFetcher = useFetcherForFaction(faction)

    const { body, headline } = section || {}

    return (
        <MapStyles>
            <WarzoneMapProvider updateFunc={mapFetcher}>
                <div className="map-container">
                    <WarzonePanel
                        mapBackgroundColor="#0E0E0E"
                        backgroundColor="#0E0E0E"
                        sidebarBackgroundColor="rgba(16, 16, 16, 0.6)"
                        factionTheme={faction}
                        tableSelectMode="text"
                        blur={4}
                    >
                        <div className="sidebar-content">
                            {headline && (
                                <TaglineRegular as="h4">
                                    {headline}
                                </TaglineRegular>
                            )}
                            {body && <Markdown source={body} />}
                        </div>
                    </WarzonePanel>
                </div>
            </WarzoneMapProvider>
        </MapStyles>
    )
}

export default Map
