import { NewsHooksWithOldArticleModel } from './NewsHookModel'
import { useContentfulNewsItem } from './useContentfulNewsItem'
import { useOldNewsItem } from './useOldNewsItem'

/**
 * @param slug - The slug of the news item
 * @param locale - The locale of the news item
 * @param preview - Use preiview api or not
 * @returns - The news item
 * @description - This handles getting a news item, either from Contentful or from the old API.
 * Is always checks first in Contentful, and if it doesn't find it there, it will try the old API.
 * @example - const [loading, error, newsItem] = useNewsItem(slug, locale, preview)
 *  */
export function useNewsItem(
    slug: string,
    locale: string,
    preview: boolean
): NewsHooksWithOldArticleModel {
    const contentfulNewsItem = useContentfulNewsItem(slug, locale, preview)

    const [loading, error, newArticle] = contentfulNewsItem
    const oldArticle = useOldNewsItem()

    // it this happens we don't have a new article, might have an old article
    if (!loading && !newArticle) {
        return [false, false, null, oldArticle]
    }

    return [loading, error, newArticle, oldArticle]

    return null
}

export default useNewsItem
