import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GlobalState } from 'types/redux'
import { useLauncherConnection } from 'hooks/launcher'
import JourneyIdManager from 'events/download-event/helpers/journey-id'
import LauncherTokenManager from 'events/download-event/helpers/launcher-token'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import PageType from 'models/types/ts/pageType'
import { Button, Container, Section, Icon } from 'layouts'
import { findSectionByIdentifier } from 'lib/pages/api'
import SectionType from 'models/types/ts/sectionType'
import ReactMarkdown from 'utils/markdown'
import { HeadingXSmall, ParagraphRegular } from 'layouts/typography'
import cx from 'classnames'
import s from './LauncherCallbackPage.module.scss'

interface Props {
    section: SectionType
    className?: string
    TitleComponent?: React.ComponentType | string
}

const ContentSection = ({
    section,
    className = null,
    TitleComponent = HeadingXSmall,
}: Props): JSX.Element => {
    const { headline, body } = section || {}
    const Wrapper = className === null ? React.Fragment : 'div'
    const wrapperProps = className === null ? {} : { className }
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Wrapper {...wrapperProps}>
            {headline && (
                // @ts-ignore - temporary fix
                <TitleComponent>
                    {headline} {Icon ?? ''}
                </TitleComponent>
            )}
            {body && (
                <ReactMarkdown
                    source={body}
                    escapeHtml={false}
                    renderers={{
                        p: ({ children }) => (
                            <ParagraphRegular>{children}</ParagraphRegular>
                        ),
                    }}
                />
            )}
        </Wrapper>
    )
}

const LauncherCallbackPage = (): JSX.Element => {
    const language = useSelector((state: GlobalState) => state.language)
    const slug = 'launcher-callback'

    const journeyIdManager = new JourneyIdManager()
    const launcherTokenManager = new LauncherTokenManager()
    const journeyId = journeyIdManager.getJourneyId()

    let parameters: Record<string, string> = {
        journeyId,
    }
    let path = '/focus'

    if (launcherTokenManager.hasLauncherToken()) {
        const token = launcherTokenManager.getLauncherToken()
        path = '/connect'
        parameters = {
            journeyId,
            singleUseToken: token,
            token,
        }
    }

    const { success, failed, openProtocolHandler } = useLauncherConnection({
        automatic: true,
        path,
        parameters,
        automaticAttempts: 60,
        automaticInterval: 1000,
        keepTrying: true,
    })
    useEffect(() => {
        if (success) {
            console.log('The launcher has been summoned')
            if (launcherTokenManager.hasLauncherToken()) {
                launcherTokenManager.clearLauncherToken()
            }
        }
    }, [success])
    useEffect(() => {
        if (failed) {
            console.log(
                'The launcher refuses to listen to our hails, we must use different means of summoning'
            )
            openProtocolHandler()
        }
    }, [failed, openProtocolHandler])

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                const sections = page?.sectionsCollection?.items
                const hero = findSectionByIdentifier(sections, 'hero')
                const content = findSectionByIdentifier(sections, 'content')
                return (
                    <div className={cx(s.launcherCallback)}>
                        <Container>
                            <PageWithSeo
                                page={page}
                                showLoading={false}
                                hideSitename={false}
                            >
                                <ContentSection section={hero} />
                                <ContentSection section={content} />
                            </PageWithSeo>
                        </Container>
                    </div>
                )
            }}
        </PageBySlugQuery>
    )
}

export default LauncherCallbackPage
