import React from 'react'
import TwitterEmbed from 'react-tweet-embed'
import s from './MediaEmbed.module.scss'

interface TweetEmbedProps {
    url: string
}

const TweetEmbed = ({ url }: TweetEmbedProps): JSX.Element => {
    const getTweetIdFromUrl = (): string => {
        const urlParts = url.split('/')
        const tweetId = urlParts[urlParts.length - 1]

        return tweetId
    }

    return (
        <TwitterEmbed
            tweetId={getTweetIdFromUrl()}
            options={{ theme: 'dark' }}
            className={s.tweetEmbed}
        />
    )
}

export default TweetEmbed
