import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NOT_FOUND, push } from 'redux-first-router'
import cx from 'classnames'
import { GlobalState } from 'types/redux'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import PageType from 'models/types/ts/pageType'
// import { findSectionByIdentifier } from 'lib/pages/api'
// import Hero from './components/hero'
import Signup from 'pages/dynamic-page/components/signup'

import { Container, Section, Visibility } from 'layouts'
import {
    HeadingMedium,
    HeadingXSmall,
    ParagraphRegular,
} from 'layouts/typography'
import AnimatedText from 'layouts/animated-text'
import BackgroundImage from 'features/background-image'
import IconColorEnum from 'pages/academy/models/icon-color-enum'
import Icons from 'pages/academy/components/icons'
import IconTypeEnum from 'pages/academy/models/icon-type-enum'
import { getQuery } from 'lib/location/selectors'
import { findSectionByIdentifier } from 'lib/pages/api'

import SectionType from 'models/types/ts/sectionType'
import ContentType from 'models/types/ts/contentType'
import Card from 'pages/academy/components/card'

import s from './CareerSignup.module.scss'

enum CareerPageEnum {
    Explorer = 'explorer',
    Enforcer = 'enforcer',
    Industrialist = 'industrialist',
    SoldierOfFortune = 'soldieroffortune',
}

interface CareerType {
    id: CareerPageEnum
    bgImage: string
    icon: IconTypeEnum
    color: IconColorEnum
}

const careersArray = [
    {
        id: CareerPageEnum.Explorer,
        bgImage:
            'https://images.ctfassets.net/7lhcm73ukv5p/4hX59F0Nig1qptJZkPVMTx/2d25a9bc89fb88bc44348f790a44b101/Explorer_3840x2160.jpg?q=75&fm=webp',
        icon: IconTypeEnum.CareersExplorerIcon,
        color: IconColorEnum.WhiteColor,
    },
    {
        id: CareerPageEnum.Enforcer,
        bgImage:
            'https://images.ctfassets.net/7lhcm73ukv5p/6UXBvyNhU1wl3usHqZIAHO/b041166e051561eb251278894bb3513b/Enforcer_3840x2160.jpg?q=75&fm=webp',
        icon: IconTypeEnum.CareersEnforcerIcon,
        color: IconColorEnum.WhiteColor,
    },
    {
        id: CareerPageEnum.Industrialist,
        bgImage:
            'https://images.ctfassets.net/7lhcm73ukv5p/2TTxsEAiu2vrDESXZJh7O5/3964c00ed773b1acab463f48c82f5ccb/industrialist_bg_career_signup.jpg?q=75&fm=webp',
        icon: IconTypeEnum.CareersIndustrialistIcon,
        color: IconColorEnum.WhiteColor,
    },
    {
        id: CareerPageEnum.SoldierOfFortune,
        bgImage:
            'https://images.ctfassets.net/7lhcm73ukv5p/4ZHApD9OEvRrp0wvJoPMP0/27d4c2e4ec7ac849d0ad49c6ee3f1725/Soilder-Of-Fortune_3840x2160.jpg?q=75&fm=webp',

        icon: IconTypeEnum.CareersSoldierOfFortuneIcon,
        color: IconColorEnum.WhiteColor,
    },
]

const CareerSignup = (): JSX.Element => {
    const language = useSelector((state: GlobalState) => state.language)
    const dispatch = useDispatch()
    const careerQueryParam = useSelector((state: GlobalState) =>
        getQuery(state)
    )
    const [careerSelected, setCareerSelected] = useState<CareerType>(
        careersArray[0]
    )

    const slug = 'career-signup'

    const handleCareerSelection = (careerId: CareerPageEnum): void => {
        let langPrefix = ''
        if (language !== 'en') {
            langPrefix = `/${language}`
        }
        push(`${langPrefix}/register?path=${careerId}`)
    }

    useEffect(() => {
        if (careerQueryParam && careerQueryParam.path) {
            const career = careersArray.find(
                (careerItem) => careerItem.id === careerQueryParam.path
            )
            if (career) {
                setCareerSelected(career)
            }
        }
    }, [careerQueryParam])

    const getCareerContent = (sections: Array<SectionType>): SectionType => {
        const sectionToShow =
            sections &&
            sections.find(
                (section) =>
                    section.identifier === `signup-${careerSelected.id}-content`
            )
        return sectionToShow
    }

    // const { webBaseUrl } = getConfig()

    const getCardLink = (itemUrl: string): string => {
        // let langPrefix = ''
        // if (language !== 'en') {
        //     langPrefix = `/${language}`
        // }
        return `/eve-academy${itemUrl}`
    }

    const renderCareerSelection = (
        arrayOfSections: Array<SectionType>
    ): JSX.Element => {
        return (
            <div className={cx(s.careerSelection)}>
                <h5>{getCareerContent(arrayOfSections).name}</h5>
                <ul>
                    {careersArray.map((career) => (
                        <li
                            key={career.id}
                            className={cx(s.careerItem, {
                                [s.active]: careerSelected === career,
                            })}
                            onClick={() => handleCareerSelection(career.id)}
                        >
                            <Icons icon={career.icon} color={career.color} />
                            <div className={cx(s.line)} />
                        </li>
                    ))}
                </ul>
                <div className={cx(s.desc)}>
                    <ParagraphRegular>
                        <AnimatedText>
                            {getCareerContent(arrayOfSections).headline}
                        </AnimatedText>
                    </ParagraphRegular>
                </div>
            </div>
        )
    }

    return (
        <PageBySlugQuery slug={slug} locale={language}>
            {(page: PageType) => {
                if (page) {
                    const sections = page?.sectionsCollection?.items

                    const signupFormCopy = findSectionByIdentifier(
                        sections,
                        'signup-form-copy'
                    )

                    const explorerContent = findSectionByIdentifier(
                        sections,
                        'signup-explorer-content'
                    )

                    const enforcerContent = findSectionByIdentifier(
                        sections,
                        'signup-enforcer-content'
                    )
                    const industrialistContent = findSectionByIdentifier(
                        sections,
                        'signup-industrialist-content'
                    )

                    const soldieroffortuneContent = findSectionByIdentifier(
                        sections,
                        'signup-soldieroffortune-content'
                    )

                    const arrayOfSections = [
                        explorerContent,
                        enforcerContent,
                        industrialistContent,
                        soldieroffortuneContent,
                    ]

                    return (
                        <PageWithSeo
                            page={page}
                            showLoading={false}
                            hideSitename={false}
                        >
                            <BackgroundImage url="https://images.ctfassets.net/7lhcm73ukv5p/1mv6Tg8PUsMWyGauWckSOo/5be5f00d5fb6d445f420b7a1c669fd26/star-bg.jpg">
                                <div
                                    className={cx(s.bg)}
                                    style={{
                                        backgroundImage: `url(${careerSelected.bgImage})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <Container>
                                        <div className={cx(s.careerSignup)}>
                                            <div className={cx(s.signupForm)}>
                                                <HeadingMedium>
                                                    <AnimatedText>
                                                        {
                                                            signupFormCopy?.headline
                                                        }
                                                    </AnimatedText>
                                                </HeadingMedium>
                                                <HeadingXSmall>
                                                    <AnimatedText>
                                                        {signupFormCopy?.teaser}
                                                    </AnimatedText>
                                                </HeadingXSmall>
                                                <Signup />
                                            </div>
                                            <div>
                                                {renderCareerSelection(
                                                    arrayOfSections
                                                )}
                                            </div>
                                        </div>
                                    </Container>
                                </div>
                                <Container className={cx(s.extraWrapper)}>
                                    <Section
                                        className={cx(s.careerExtraContent)}
                                    >
                                        {arrayOfSections.length > 0 &&
                                            getCareerContent(
                                                arrayOfSections
                                            ) && (
                                                <Visibility
                                                    direction="fadeUp"
                                                    transitionDelay="1s"
                                                >
                                                    <div
                                                        className={cx(
                                                            s.cardContainer
                                                        )}
                                                    >
                                                        {getCareerContent(
                                                            arrayOfSections
                                                        ) &&
                                                            getCareerContent(
                                                                arrayOfSections
                                                            ).contentCollection.items.map(
                                                                (
                                                                    item: ContentType,
                                                                    index: number
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            item.name
                                                                        }
                                                                        className={cx(
                                                                            s.inner,
                                                                            `item${index}`
                                                                        )}
                                                                    >
                                                                        <Card
                                                                            content={
                                                                                item
                                                                            }
                                                                            itemUrl={getCardLink(
                                                                                item.buttonUrl
                                                                            )}
                                                                        />
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </Visibility>
                                            )}
                                    </Section>
                                </Container>
                            </BackgroundImage>
                        </PageWithSeo>
                    )
                }

                // page not found in contentful
                dispatch({ type: NOT_FOUND })
                return <></>
            }}
        </PageBySlugQuery>
    )
}

export default CareerSignup
