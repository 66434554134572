import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import contentful from 'contentful'
import { RichTextAssetType } from '../../types'

interface Props {
    image: RichTextAssetType
}

const ImageLightbox = ({ image }: Props): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    function clickImage(): void {
        setIsOpen(true)
    }

    // We can assume we are working with a contentful image so image API usage is safe
    // TODO manipulate img sizes

    const queryUrlMin = '?w=850&fm=jpg&fl=progressive&q=75'
    const queryUrlMax = '?w=2000&fm=jpg&fl=progressive&q=75'

    if (image && image.url) {
        return (
            <>
                <img
                    style={{ cursor: 'pointer', marginBottom: '10px' }}
                    src={image.url + queryUrlMin}
                    alt={image.description}
                    onClick={() => clickImage()}
                    loading="lazy"
                />

                {isOpen && (
                    <Lightbox
                        mainSrc={image.url + queryUrlMax}
                        onCloseRequest={() => setIsOpen(false)}
                    />
                )}
            </>
        )
    }

    // Return nothing if no image or no url
    return <></>
}

export default ImageLightbox
