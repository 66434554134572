import React from 'react'

export const ConfirmIcon = (): JSX.Element => (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.6282 0.615312C15.1332 0.11961 14.3293 0.119922 13.8336 0.615312L5.75659 8.69264L2.1667 5.10279C1.671 4.60708 0.867479 4.60708 0.371777 5.10279C-0.123926 5.59849 -0.123926 6.40201 0.371777 6.89771L4.85894 11.3849C5.10663 11.6326 5.43143 11.7567 5.75625 11.7567C6.08107 11.7567 6.40617 11.6329 6.65387 11.3849L15.6282 2.41021C16.1239 1.91485 16.1239 1.11098 15.6282 0.615312Z"
            fill="#2EB954"
        />
    </svg>
)

export const CloseIcon = (): JSX.Element => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.0625 12C10.8141 12 10.575 11.9016 10.3992 11.7258L6 7.32656L1.60078 11.7258C1.23516 12.0914 0.639844 12.0914 0.274219 11.7258C-0.0914062 11.3602 -0.0914062 10.7648 0.274219 10.3992L4.67344 6L0.274219 1.60078C-0.0914062 1.23516 -0.0914062 0.639844 0.274219 0.274219C0.639844 -0.0914062 1.23516 -0.0914062 1.60078 0.274219L6 4.67344L10.3969 0.274219C10.7625 -0.0914062 11.3578 -0.0914062 11.7234 0.274219C12.0891 0.639844 12.0891 1.23516 11.7234 1.60078L7.32656 6L11.7234 10.3969C12.0914 10.7625 12.0914 11.3555 11.7258 11.7234C11.5523 11.9016 11.3133 12 11.0625 12Z"
            fill="#D81F27"
        />
    </svg>
)
