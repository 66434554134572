import { FrontlinePage, FactionIcon } from '../models/frontlines-types'

const sitemap: FrontlinePage[] = [
    {
        name: 'Amarr',
        faction: 'amarr',
        slug: 'amarr',
        color: '#E7B815',
        icon: FactionIcon.AmarrIcon,
        enemy: 'minmatar',
        pirate: 'angel',
        stats: 100,
    },
    {
        name: 'Minmatar',
        faction: 'minmatar',
        slug: 'minmatar',
        color: '#FE3743',
        icon: FactionIcon.MinmatarIcon,
        enemy: 'amarr',
        pirate: 'angel',
        stats: 500,
    },
    {
        name: 'Gallente',
        faction: 'gallente',
        slug: 'gallente',
        color: '#2EB954',
        icon: FactionIcon.GallenteIcon,
        enemy: 'caldari',
        pirate: 'guristas',
        stats: 187,
    },
    {
        name: 'Caldari',
        faction: 'caldari',
        slug: 'caldari',
        color: '#00ACD1',
        icon: FactionIcon.CaldariIcon,
        enemy: 'gallente',
        pirate: 'guristas',
        stats: 254,
    },
]

export default sitemap
