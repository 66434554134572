import TagManager from 'react-gtm-module'
import { isClient } from 'config/web'
import DataLayer from './datalayer'

/*

Are all the datalayer variables required for each call?

Parity:
PLEX
1. /PLEX/default.aspx?step=SelectProduct&flow=BuyPlex
2. /PLEX/default.aspx?step=PaymentInfo&flow=BuyPlex
3. /PLEX/default.aspx?step=Receipt&flow=BuyPlex

For future versions, the product and flow needs to be passed through the process, i.e. via some form of state

---

Sanitizing

We need to sanitize the Data Layer Variables, as they will persist indefinately through the session

product
page.title
virtualPath
isEcommerce
ReceiptPath
ReceiptType
isReceipt

---

Aggregate the Store Tracking events, they consist of the following steps:

    Analytics.PushVariable('page.title', `${props.pageTitle} - Receipt`)
    Analytics.EcommerceSteps('PLEX', 'Receipt', 'BuyPlex')
    Analytics.PushEvent('pageTransition')
    Analytics.SanitizeVirtualPath()

Set the title
Set the steps
Push Transition
Sanitize

Redesigned function:

PushStoreStep(flow, step)

Title is created based on step


*/

// Used in new virtualPath logic in onBeforeChange
export function addVirtualPathToDatalayer(
    event: unknown,
    virtualPath: unknown
): void {
    if (isClient) {
        const tagManagerArgs = {
            dataLayer: {
                event,
                virtualPath,
            },
        }
        TagManager.dataLayer(tagManagerArgs)
    }
}

class Analytics {
    // Event
    // sender is only for debugging purposes, to see what part of the code is sending the event.
    static PushEvent = (event: string, sender = null): void => {
        DataLayer.PushEventToDataLayer(event)
    }

    static PushEventObject = (event: object, sender = null): void => {
        DataLayer.PushEventObjectToDataLayer(event)
    }

    // TODO: Remove, not used
    static EcommerceSteps_Revised_1 = (
        flow: string,
        productSlug: string,
        step: string
    ): void => {
        // 	/store/checkout/plex-110?step=PaymentMethod
        const virtualPath = `/store/${flow}/checkout/${productSlug}?step=${step}`

        if (step === 'Receipt') {
            DataLayer.Legacy_PushToDataLayer(
                'sendVirtualPageView',
                virtualPath,
                'yes',
                'eo-www',
                productSlug,
                true
            )
        } else {
            DataLayer.Legacy_PushToDataLayer(
                'sendVirtualPageView',
                virtualPath,
                'yes',
                'eo-www',
                productSlug,
                false
            )
            // DataLayer.VirtualPageView(virtualPath)
        }

        console.log(
            '%cAnalytics::EcommerceSteps_Revised_1()',
            'color:#ffeb19; font-size: 16px',
            virtualPath
        )
    }

    // Intended to clear out all the DataLayer Variables that are used, otherwise the variables
    // persist until overriden or the page reloads.
    // TODO: Remove, not used
    static SanitizeAll(): void {
        const dataLayerVariables = [
            'product',
            'page.title',
            'virtualPath',
            'isEcommerce',
            'ReceiptPath',
            'ReceiptType',
            'isReceipt',
        ]

        DataLayer.SanitizeArray(dataLayerVariables)
    }
}

export default Analytics
