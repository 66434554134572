import React, { CSSProperties, useEffect, useState } from 'react'
import useScrollBlock from 'hooks/useScrollBlock'
import ReactMarkdown from 'utils/markdown'
import ContentType from 'models/types/ts/contentType'
import { LabelSmall } from 'layouts/typography'
import LinkArrow from 'layouts/link-arrow'
import style from './ToggleSlidingPane.module.scss'
import SlidingPane from './SlidingPane'
import { useSlidingPane } from './useSlidingPane'

interface Props {
    content: ContentType
    colors: SlidingPaneConfigModel
}

export const ToggleSlidingPane = ({
    content,
    colors = {
        primaryColor: '#FFF',
        textColor: '#000',
        linkColor: '#101010',
        arrowColor: '#000',
    },
}: Props): JSX.Element => {
    const { isOpen, toggleSlidingPane, closeSlidingPane } = useSlidingPane()
    const [blockScroll, allowScroll] = useScrollBlock()

    useEffect(() => {
        if (isOpen) {
            // block page scroll
            blockScroll()

            // allow page scroll on !isOpen and unmount
            return () => allowScroll()
        }
    }, [isOpen])

    let paneImg = ''
    if (content) {
        paneImg = `${content?.imageFile?.url}?w=630&fm=webp`
        paneImg = paneImg.replace(
            'downloads.ctfassets.net',
            'images.ctfassets.net'
        )
    }

    function renderLink(): JSX.Element {
        return (
            <div
                className={style.link}
                onClick={() => (content?.body ? toggleSlidingPane() : null)}
                role="button"
                tabIndex={0}
            >
                <LabelSmall>Read more</LabelSmall>{' '}
                <LinkArrow color={colors.arrowColor} />
            </div>
        )
    }

    return (
        <>
            {content.body && renderLink()}
            <SlidingPane
                isOpen={isOpen}
                onClose={closeSlidingPane}
                colors={colors}
            >
                <div
                    style={
                        {
                            '--bullet-color': colors?.arrowColor,
                        } as CSSProperties
                    }
                    className={style.container}
                >
                    <div>
                        <ReactMarkdown
                            source={content.body}
                            escapeHtml={false}
                        />
                    </div>
                    <img src={paneImg} className={style.img} alt="" />
                </div>
            </SlidingPane>
        </>
    )
}
