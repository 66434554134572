import React from 'react'
import { useDispatch } from 'react-redux'
import { NOT_FOUND } from 'redux-first-router'
import PageBySlugQuery from 'queries/PageBySlugQuery'
import PageWithSeo from 'features/page-with-seo'
import LanguageType from 'models/language-type'
import PageType from 'models/types/ts/pageType'
import { findSectionByIdentifier } from 'lib/pages/api'
import Careers from 'pages/www/components/careers'
import { Container, Loading, Section } from 'layouts'
import BackgroundImage from 'features/background-image'
import MovingStars from 'layouts/moving-stars/MovingStars'
import { useHasMounted } from 'hooks/client-only'
import Hero from './components/hero'
import Map from './components/map'
import Message from './components/message'
import sitemap from './sitemap'
import { FactionType, FrontlinePage } from './models/frontlines-types'
import { getFaction } from './helpers/frontlines-helper'
import Leaderboards from './components/leaderboards'

const LoadableFrontlinesFaction = React.lazy(
    () =>
        import(
            /* webpackChunkName: "page-frontlines-faction" */ './FrontlinesFaction.styles'
        )
)

interface Props {
    subpage: string
    prefix: string
    language: LanguageType
}

interface ShowLeaderboardProps {
    showLeaderBoards: boolean
}

const FrontlinesFaction = ({
    subpage,
    prefix,
    language,
}: Props): JSX.Element => {
    const dispatch = useDispatch()
    const hasMounted = useHasMounted()

    const getActiveSitemapPage = (): FrontlinePage =>
        sitemap.find((p) => p.slug === subpage)
    const currentSitemapPage = getActiveSitemapPage()

    if (currentSitemapPage) {
        const { slug } = currentSitemapPage
        const faction: FactionType = slug
        const contentfulSlugWithFaction = `${prefix}-${slug}` // slug is the faction name
        const color = getFaction(slug).color

        return (
            <PageBySlugQuery slug={contentfulSlugWithFaction} locale={language}>
                {(page: PageType) => {
                    if (!page) {
                        return <Loading />
                    }
                    if (page) {
                        // Flag to show/hide the Leaderboards, this is a config option in Contentful
                        let showTheBoards: ShowLeaderboardProps = {
                            showLeaderBoards: true,
                        }
                        if (page?.config) {
                            const pageConfig = page?.config as {
                                showLeaderBoards: ShowLeaderboardProps
                            }
                            showTheBoards = pageConfig?.showLeaderBoards
                        }

                        const sections = page?.sectionsCollection?.items

                        const heroSection = findSectionByIdentifier(
                            sections,
                            `${contentfulSlugWithFaction}-hero`
                        )
                        const statsSection = findSectionByIdentifier(
                            sections,
                            `${contentfulSlugWithFaction}-stats`
                        )
                        const sidebarSection = findSectionByIdentifier(
                            sections,
                            `${contentfulSlugWithFaction}-sidebar`
                        )

                        const arcGoalsSection = findSectionByIdentifier(
                            sections,
                            `${contentfulSlugWithFaction}-arc-goals`
                        )

                        // Not used yet and design does not account for it, so removing until added later, then code needs to be changes also
                        // const messageSection = findSectionByIdentifier(
                        //     sections,
                        //     `${contentfulSlugWithFaction}-message`
                        // )

                        // Below are common sections used on all faction pages, but need to be added to the faction page in Contentful
                        // Identifier syntax is "frontlines-<section>"
                        const videoSection = findSectionByIdentifier(
                            sections,
                            `${prefix}-video`
                        )
                        const mapSection = findSectionByIdentifier(
                            sections,
                            `${prefix}-map`
                        )

                        const careersSection = findSectionByIdentifier(
                            sections,
                            `${prefix}-careers`
                        )

                        // Trying this out since the hero was not showing up fast enough on the faction pages
                        if (!heroSection) {
                            return <Loading />
                        }

                        return (
                            <PageWithSeo
                                page={page}
                                showLoading={false}
                                hideSitename={false}
                            >
                                {hasMounted && (
                                    <React.Suspense fallback={<Loading />}>
                                        <LoadableFrontlinesFaction
                                            color={color}
                                        >
                                            {heroSection && (
                                                <Hero
                                                    faction={faction}
                                                    heroSection={heroSection}
                                                    statsSection={statsSection}
                                                />
                                            )}

                                            <Section className="leaderboard-section">
                                                <BackgroundImage
                                                    url="https://images.ctfassets.net/7lhcm73ukv5p/3KIOgpf9HtER1irv08rUgT/3a40170fb0695ae23aa312f8d9ffff12/frontlines_leaders_bg.png"
                                                    repeat="no-repeat"
                                                    size="cover"
                                                    position="center top"
                                                    // className="map-background"
                                                    lazy
                                                >
                                                    <Container
                                                        xlWide
                                                        className="frontlines-map-container"
                                                    >
                                                        {showTheBoards && (
                                                            <>
                                                                <Leaderboards
                                                                    faction={
                                                                        faction
                                                                    }
                                                                    color={
                                                                        color
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </Container>
                                                </BackgroundImage>
                                            </Section>

                                            <Section className="map-section">
                                                <MovingStars>
                                                    <Container
                                                        xlWide
                                                        className="frontlines-map-container"
                                                    >
                                                        <p className="message-headline">
                                                            {
                                                                mapSection?.headline
                                                            }
                                                        </p>

                                                        <Map
                                                            faction={faction}
                                                            section={
                                                                sidebarSection
                                                            }
                                                        />
                                                    </Container>
                                                </MovingStars>
                                            </Section>

                                            {videoSection && (
                                                <Section className="below">
                                                    <Container
                                                        xlWide
                                                        className="container"
                                                    >
                                                        <div className="video-container">
                                                            <p className="message-headline">
                                                                {
                                                                    videoSection?.headline
                                                                }
                                                            </p>

                                                            <div className="content">
                                                                <Message
                                                                    section={
                                                                        videoSection
                                                                    }
                                                                    color={
                                                                        color
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Container>
                                                </Section>
                                            )}
                                            {careersSection && (
                                                <Careers
                                                    section={careersSection}
                                                    isFrontlines
                                                />
                                            )}
                                        </LoadableFrontlinesFaction>
                                    </React.Suspense>
                                )}
                            </PageWithSeo>
                        )
                    }
                }}
            </PageBySlugQuery>
        )
    }
    // We did not find the page in contentful
    dispatch({ type: NOT_FOUND })
    return <></>
}

export default FrontlinesFaction
