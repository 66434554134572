import React from 'react'
import { NewsEntryType } from '../../types'
import { Card } from '../../../../../cards'
import s from './NewsEmbed.module.scss'

interface Props {
    content: NewsEntryType
}

const NewsEmbed = ({ content }: Props): JSX.Element => (
    <Card newsItem={content} className={s.embed} filled showDescription />
)

export default NewsEmbed
