import React from 'react'
import SectionType from 'models/types/ts/sectionType'
import ContentType from 'models/types/ts/contentType'
import { ParagraphLarge, TaglineRegular } from 'layouts/typography'
import ReactMarkdown from 'utils/markdown'
import s from '../page/twitch.module.scss'

interface RewardsProps {
    section: SectionType
}

const Rewards = (rewards: RewardsProps): JSX.Element => {
    const renderRewards = (item: ContentType): JSX.Element => {
        if (!item) return <></>

        return (
            <div className={s.item} key={item.name}>
                <div className={s.rewardImage}>
                    <img
                        src={`${item.imageFile.url}?w=410&h=230&fit=fill`}
                        alt={item.imageFile.description}
                    />
                </div>
                <div className={s.rewardText}>
                    <h4>{item?.headline}</h4>
                    <ReactMarkdown source={item?.body} />
                </div>
            </div>
        )
    }

    return (
        <div style={{ paddingTop: '40px' }}>
            <TaglineRegular fontWeight={500}>
                {rewards && rewards.section.headline}
            </TaglineRegular>
            <ParagraphLarge>{rewards && rewards.section.body}</ParagraphLarge>

            <div className={s.rewards}>
                <div className={s.grid}>
                    {rewards &&
                        rewards.section.contentCollection?.items.map((item) => {
                            return renderRewards(item)
                        })}
                </div>
            </div>
        </div>
    )
}

export default Rewards
