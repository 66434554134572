import React from 'react'
// import s from './Platforms.module.scss'

const IconDownload = (): JSX.Element => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3153_1585)">
            <ellipse cx="12" cy="12.5" rx="11" ry="11.5" fill="#101010" />
            <path
                d="M12.3711 0.719971C9.99772 0.719971 7.67765 1.42376 5.70426 2.74234C3.73087 4.06091 2.1928 5.93506 1.28454 8.12777C0.376293 10.3205 0.138653 12.7333 0.601676 15.0611C1.0647 17.3888 2.20759 19.527 3.88582 21.2053C5.56405 22.8835 7.70224 24.0264 10.03 24.4894C12.3578 24.9524 14.7706 24.7148 16.9633 23.8065C19.156 22.8983 21.0302 21.3602 22.3487 19.3868C23.6673 17.4134 24.3711 15.0933 24.3711 12.72C24.3711 9.53737 23.1068 6.48513 20.8564 4.23469C18.6059 1.98425 15.5537 0.719971 12.3711 0.719971ZM16.3076 20.45H8.4346C8.16938 20.45 7.91503 20.3446 7.72749 20.1571C7.53996 19.9695 7.4346 19.7152 7.4346 19.45C7.4346 19.1848 7.53996 18.9304 7.72749 18.7429C7.91503 18.5553 8.16938 18.45 8.4346 18.45H16.3076C16.5728 18.45 16.8272 18.5553 17.0147 18.7429C17.2022 18.9304 17.3076 19.1848 17.3076 19.45C17.3076 19.7152 17.2022 19.9695 17.0147 20.1571C16.8272 20.3446 16.5728 20.45 16.3076 20.45ZM17.0146 12.928L13.0751 16.87C12.8876 17.0574 12.6333 17.1628 12.3681 17.1628C12.1029 17.1628 11.8486 17.0574 11.6611 16.87L7.7276 12.937C7.53969 12.7495 7.43397 12.495 7.43369 12.2295C7.43341 11.9641 7.53859 11.7094 7.7261 11.5215C7.91361 11.3336 8.16808 11.2278 8.43354 11.2276C8.699 11.2273 8.95369 11.3325 9.1416 11.52L11.3711 13.7495V4.90247C11.3711 4.63725 11.4765 4.3829 11.664 4.19536C11.8515 4.00783 12.1059 3.90247 12.3711 3.90247C12.6363 3.90247 12.8907 4.00783 13.0782 4.19536C13.2657 4.3829 13.3711 4.63725 13.3711 4.90247V13.7435L15.6006 11.514C15.7892 11.3318 16.0418 11.231 16.304 11.2333C16.5662 11.2356 16.817 11.3407 17.0024 11.5262C17.1878 11.7116 17.293 11.9624 17.2953 12.2246C17.2976 12.4868 17.1968 12.7394 17.0146 12.928Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_3153_1585">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.371094 0.719971)"
                />
            </clipPath>
        </defs>
    </svg>
)

export default IconDownload
