export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE'

// Define the type for the actions handled by this reducer
export interface LanguageAction {
    type: string
    payload: string
}

export const languageChange = (language: string): LanguageAction => ({
    type: LANGUAGE_CHANGE,
    payload: language,
})
