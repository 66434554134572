import React from 'react'
import {
    FactionLabel,
    FactionWarzoneProgressRound,
} from '@ccpgames/eve-web/components'
import { getFaction } from 'pages/frontlines/helpers/frontlines-helper'
import { FactionType } from 'pages/frontlines/models/frontlines-types'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import CustomFactionWarzoneProgressRoundStyles from './CustomFactionWarzoneProgressRound.styles'

interface Props {
    faction1: FactionType
    faction1Systems: number
    faction2: FactionType
    faction2Systems: number
    extraFactions?: FactionType[]
    thickness?: number
    size?: number
    iconSize?: number
}

const CustomFactionWarzoneProgressRound = ({
    faction1,
    faction1Systems,
    faction2,
    faction2Systems,
    extraFactions = [],
    thickness = 7,
    size = 185,
    iconSize = 75,
}: Props): JSX.Element => {
    const { ref, inView } = useInView({
        threshold: 0.3,
        triggerOnce: true,
    })

    const renderCountUp = (count: number, extraClass: string): JSX.Element => {
        if (count)
            return (
                <div className="numberContainer">
                    <div className={`numberContent ${extraClass}`}>
                        <CountUp
                            start={0}
                            end={inView ? count : 0}
                            duration={2}
                        />
                    </div>
                </div>
            )
    }

    const faction1Color = getFaction(faction1).color
    const faction2Color = getFaction(faction2).color
    const allFactions: FactionType[] = [faction2, ...extraFactions, faction1]

    return (
        <CustomFactionWarzoneProgressRoundStyles
            ref={ref}
            borderColor1={faction1Color}
            borderColor2={faction2Color}
        >
            <FactionLabel
                faction={faction1}
                iconPosition="none"
                style={{
                    textAlign: 'right',
                    marginRight: '20px',
                }}
            >
                {renderCountUp(faction1Systems, 'color1')}
            </FactionLabel>
            <FactionWarzoneProgressRound
                thickness={thickness}
                size={size}
                iconSize={iconSize}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                factions={allFactions} // This is in reverse order to display circle progress correctly
                backgroundColor="#101010"
                glowSize={3}
            />
            <FactionLabel
                faction={faction2}
                iconPosition="none"
                style={{
                    textAlign: 'left',
                    marginLeft: '20px',
                }}
            >
                {renderCountUp(faction2Systems, 'color2')}
            </FactionLabel>
        </CustomFactionWarzoneProgressRoundStyles>
    )
}

export default CustomFactionWarzoneProgressRound
