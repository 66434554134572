import NewsType from 'models/types/ts/newsType'
import { useSelector } from 'react-redux'
import { GlobalState } from 'types'

// eslint-disable-next-line
function mapOldArticleToNew(old: any): NewsType {
    try {
        const mappedArticle: NewsType = {
            ...old,
            metaImageUrl: {
                url: old.metaImageUrl,
            },
            sys: {
                publishedAt: old.publishingDate,
            },
        }
        return mappedArticle
    } catch (error) {
        return null
    }
}

export function useOldNewsItem(): NewsType {
    const oldArticle = useSelector(
        (state: GlobalState) => state && state.pages && state.pages.article
    )

    if (oldArticle) {
        const article = mapOldArticleToNew(oldArticle)

        return article
    }

    return null
}

export default useOldNewsItem
