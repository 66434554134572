import client from 'services/api/client'
import {
    WarzoneFactionLeaderboard,
    WarzoneLeaderboardCharacter,
    WarzoneArcGoalInformationProps,
    WarzoneFactionArcGoals,
} from '@ccpgames/eve-web/components'

import Logger from 'utils/logging'

export const getWarzoneLeaderboard = async (): Promise<WarzoneFactionLeaderboard> => {
    const ret = await client({
        url: `/warzone/leaderboard`,
        method: 'get',
    })
        .then((result) => {
            const data = result?.data as WarzoneFactionLeaderboard
            if (!data) return null
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone/leaderboard',
                'getWarzoneLeaderboard'
            )
            return null
        })
    return ret
}

export const getWarzonePersonalLeaderboard = async (
    token: string
): Promise<WarzoneLeaderboardCharacter[]> => {
    const ret = await client({
        url: `/warzone/character-stats`,
        method: 'post',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((result) => {
            const data = result?.data as WarzoneLeaderboardCharacter[]
            if (!data || !Array.isArray(data)) return null
            if (!data) return null
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone/character-stats',
                'getWarzonePersonalLeaderboard'
            )
            return null
        })
    return ret
}

export const getArcGoals = async (): Promise<WarzoneFactionArcGoals> => {
    const ret = await client({
        url: `/arcgoals/current-goals`,
        method: 'get',
    })
        .then((result) => {
            const data = result?.data as WarzoneFactionArcGoals
            if (!data) return null
            return data
        })
        .catch((error) => {
            Logger.captureNetworkException(
                error,
                'api/warzone/arc-goals',
                'getWarzoneArcGoals'
            )
            return null
        })
    return ret
}
