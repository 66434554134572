import { WarzoneLeaderboardCharacter } from '@ccpgames/eve-web/components'
import { useMemo } from 'react'

const PLACEHOLDER_CORPS: Partial<WarzoneLeaderboardCharacter>[] = [
    {
        corporation: { id: 1000182, name: 'Tribal Liberation Force' },
        faction: { id: 500002 },
    },
    {
        corporation: { id: 1000181, name: 'Federal Defense Union' },
        faction: { id: 500004 },
    },
    {
        corporation: { id: 1000180, name: 'State Protectorate' },
        faction: { id: 500001 },
    },
    {
        corporation: { id: 1000179, name: '24th Imperial Crusade' },
        faction: { id: 500003 },
    },
]

const PLACEHOLDER_CHARACTERS: Partial<WarzoneLeaderboardCharacter>[] = [
    { id: 2116789855, name: 'Detinate' },
    { id: 112972308, name: 'Damage' },
    { id: 92727244, name: 'Graven' },
    { id: 2116405266, name: 'Nero' },
    { id: 2119893075, name: 'Stroopwafel' },
    { id: 2117475975, name: 'Troglodyte' },
    { id: 1016535936, name: 'Placebo' },
    { id: 91355915, name: 'Señor Coconut' },
    { id: 2120174626, name: 'Shawarma' },
]

function shuffle<T>(items: T[]): T[] {
    return items.sort(() => Math.random() - 0.5)
}

const rand = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const usePlaceholderCharacters = (): WarzoneLeaderboardCharacter[] => {
    return useMemo(() => {
        const items = []
        const characters = shuffle(PLACEHOLDER_CHARACTERS)
        for (let i = 0; i < 3; i++) {
            const char = characters[i]
            const corp = shuffle(PLACEHOLDER_CORPS)[0]
            const currentRank = rand(3, 9)
            const highestRank = Math.max(rand(5, 9), currentRank)
            items.push({
                enlisted: true,
                currentRank,
                highestRank,
                kills: {
                    total: rand(1000, 10000),
                    lastWeek: rand(100, 1000),
                    yesterday: rand(10, 100),
                },
                victoryPoints: {
                    total: rand(10000, 500000),
                    lastWeek: rand(1000, 50000),
                    yesterday: rand(100, 5000),
                },
                ...corp,
                ...char,
            } as WarzoneLeaderboardCharacter)
        }
        return items
    }, [])
}
