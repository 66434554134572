import React from 'react'
import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { HeadingXSmall, ParagraphRegular } from 'layouts/typography'

import RichTextType, { RichTextLinksType } from 'models/types/ts/richTextType'
import { BlockQuote, Table, UlList, OlList } from './components'
import {
    AssetHyperlinkType,
    BasicEntryType,
    NewsEntryType,
    RichTextAssetType,
} from './types'
import {
    renderEmbeddedBlockEntry,
    renderEmbeddedInlineEntry,
} from './renderEntries'
import { renderEmbeddedAssets } from './renderAssets'
import {
    renderAssetHyperlink,
    renderEntryHyperlink,
    renderHyperlink,
} from './renderHyperlinks'

interface Items {
    assets: Map<unknown, unknown>
    entries: Map<unknown, unknown>
}

// TODO move to helper
export const getItemsMap = (links: RichTextLinksType): Items => {
    // Create assets and entry maps
    const items: Items = {
        assets: new Map(),
        entries: new Map(),
    }

    // loop through the assets and add them to the map
    links?.assets?.block?.forEach((asset) => {
        items.assets.set(asset.sys.id, asset)
    })
    links?.assets?.hyperlink?.forEach((asset) => {
        items.assets.set(asset.sys.id, asset)
    })

    // loop through the block linked entries and add them to the map
    links?.entries?.block?.forEach((entry) => {
        items.entries.set(entry.sys.id, entry)
    })

    // loop through the inline linked entries and add them to the map
    links?.entries?.inline?.forEach((entry) => {
        items.entries.set(entry.sys.id, entry)
    })

    links?.entries?.hyperlink?.forEach((entry) => {
        items.entries.set(entry.sys.id, entry)
    })

    return items
}

const getRenderOptions = (document: RichTextType): Options => {
    const items = getItemsMap(document.links)

    // Todo doing same in two places, might break, find better way
    let heading2Index = 0
    let heading3Index = 0

    return {
        // other options...
        // renderMark: {
        //     // [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        //     [MARKS.HEADING_2]: (text) => <HeadingXSmall>{text}</HeadingXSmall>,
        // },

        renderNode: {
            // other options...
            [INLINES.EMBEDDED_ENTRY]: (node, children) => {
                // TODO We could make helper for this, done in two places
                const sysId = node?.data?.target?.sys?.id // TODO possible null reference error

                if (!sysId) return null

                const entry = items.entries.get(sysId) as BasicEntryType

                if (!entry) return null

                return renderEmbeddedInlineEntry(entry, document.json)
            },
            [INLINES.HYPERLINK]: (node, children) => {
                const uri = node?.data?.uri
                return renderHyperlink(uri, children)
            },
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                // TODO We could make helper for this, done same in few places
                const sysId = node?.data?.target?.sys?.id

                if (!sysId) return null

                const entry = items.entries.get(sysId) as BasicEntryType

                return renderEntryHyperlink(entry as NewsEntryType, children)
            },
            [INLINES.ASSET_HYPERLINK]: (node, children) => {
                // TODO We do this in many places, get sys id and basic entry
                const sysId = node?.data?.target?.sys?.id
                if (!sysId) return <></>

                const entry = items.assets.get(sysId) as BasicEntryType
                if (!entry) return <></>

                const assetEntry = entry as AssetHyperlinkType

                return renderAssetHyperlink(assetEntry, children)
            },
            // [BLOCKS.HEADING_2]: (text) => <HeadingXSmall>{text}</HeadingXSmall>,
            [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
                const sysId = node?.data?.target?.sys?.id
                if (!sysId) return null

                const entry = items.entries.get(sysId) as BasicEntryType
                if (!entry) return null

                return renderEmbeddedBlockEntry(entry, document.json)
            },
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const sysId = node?.data?.target?.sys?.id
                if (!sysId) return <></>

                const asset = items.assets.get(sysId) as RichTextAssetType
                if (!asset) return <></>

                return renderEmbeddedAssets(asset)
            },
            // [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>, // This removes <p> when paragraph is rendered // Sometimes there is <p> rendered and then the children are some components we make, and then e.g. <div> is rendered inside <p> which is not allowed and gives nasty console error
            [BLOCKS.PARAGRAPH]: (node, children) => (
                <ParagraphRegular fontSize={[18, 22]}>
                    {children}
                </ParagraphRegular>
            ),
            [BLOCKS.HEADING_2]: (node, children) => {
                // Todo makes heading with ids to be anchorable
                heading2Index += 1 // Increment before
                const anchorId = `h2-${heading2Index}` // must be string

                return (
                    <HeadingXSmall key={anchorId} as="h2" id={anchorId}>
                        {children}
                    </HeadingXSmall>
                )
            },
            [BLOCKS.HEADING_3]: (node, children) => {
                // Todo makes heading with ids to be anchorable
                heading3Index += 1 // Increment before
                const anchorId = `h3-${heading3Index}` // must be string

                return (
                    <HeadingXSmall
                        key={anchorId}
                        fontSize={[18, 22]}
                        textTransform="unset"
                        id={anchorId}
                    >
                        {children}
                    </HeadingXSmall>
                )
            },
            [BLOCKS.QUOTE]: (node, children) => (
                <BlockQuote>{children}</BlockQuote>
            ),
            [BLOCKS.UL_LIST]: (node, children) => <UlList>{children}</UlList>,
            [BLOCKS.OL_LIST]: (node, children) => <OlList>{children}</OlList>,
            [BLOCKS.TABLE]: (node, children) => <Table>{children}</Table>,
        },
        renderText: (text) => {
            // render line breaks
            return text.split('\n').reduce((children, textSegment, index) => {
                return [
                    ...children,
                    index > 0 && <br key={index} />, // eslint-disable-line
                    textSegment,
                ]
            }, [])
        },
    }
}

export default getRenderOptions
