import React from 'react'
import { isMobile as reactDeciveDetectIsMobile } from 'react-device-detect'

export function useIsMobile(): boolean {
    const [isMobile, setIsMobile] = React.useState(false)
    React.useEffect(() => {
        setIsMobile(reactDeciveDetectIsMobile)
    }, [])
    return isMobile
}

interface Props {
    children: React.ReactNode
    delegated?: unknown
}

export function NotMobile({ children, ...delegated }: Props): JSX.Element {
    const isMobile = useIsMobile()

    if (isMobile) {
        return null
    }

    return <div {...delegated}>{children}</div>
}

export function NotMobileFragment({ children }: Props): JSX.Element {
    const isMobile = useIsMobile()

    if (isMobile) {
        return null
    }

    return <>{children}</>
}

export function OnlyMobile({ children, ...delegated }: Props): JSX.Element {
    const isMobile = useIsMobile()

    if (!isMobile) {
        return null
    }

    return <div {...delegated}>{children}</div>
}

export function OnlyMobileFragment({ children }: Props): JSX.Element {
    const isMobile = useIsMobile()

    if (!isMobile) {
        return null
    }

    return <>{children}</>
}