import React, { useContext, useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { UserContext } from 'utils/context/UserContext'
import style from './SpecialOfferCount.module.scss'

const SpecialOfferCount = (): JSX.Element => {
    const [fadeIn, setFadeIn] = useState(false)
    const { specialOfferCount } = useContext(UserContext)

    const cx = classNames.bind(style)

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true)
        }, 2000) // 2000 milliseconds = 2 seconds
    }, [])

    if (specialOfferCount === 0) {
        return <></>
    }

    return (
        <div
            className={cx(style.specialBubble, {
                [style.fadeIn]: fadeIn,
            })}
        >
            {/* <div className="inner">{specialOfferCount}</div> */}
        </div>
    )
}

export default SpecialOfferCount
