import { WarzoneArcGoalsProvider } from '@ccpgames/eve-web/components'
import React from 'react'
import { getArcGoals } from './helpers/get-warzone-leaderboard'

interface Props {
    children: React.ReactNode
    updateInterval?: number
}

export default function ArcGoalsProviders({
    children,
    updateInterval = 15000,
}: Props): JSX.Element {
    return (
        <WarzoneArcGoalsProvider
            updateInterval={updateInterval}
            updateFunc={getArcGoals}
        >
            {children}
        </WarzoneArcGoalsProvider>
    )
}
