export const HIDE_NAVIGATION = 'HIDE_NAVIGATION'
export const TOGGLE_MOBILE_NAVIGATION = 'TOGGLE_MOBILE_NAVIGATION'
export const CLOSE_MOBILE_NAVIGATION = 'CLOSE_MOBILE_NAVIGATION'
export const TOGGLE_MOBILE_NAVIGATION_ACCOUNT =
    'TOGGLE_MOBILE_NAVIGATION_ACCOUNT'

export interface MenuAction {
    type: string
    payload?: boolean
}

export const hideNavigation = (hide: boolean): MenuAction => ({
    type: HIDE_NAVIGATION,
    payload: hide,
})
export const closeMobileNavigation = (): MenuAction => ({
    type: CLOSE_MOBILE_NAVIGATION,
})
export const toggleMobileNavigation = (): MenuAction => ({
    type: TOGGLE_MOBILE_NAVIGATION,
})
