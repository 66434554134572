import React from 'react'
import cx from 'classnames'
import { useInView } from 'react-intersection-observer'
import s from './AnimatedText.module.scss'

interface Props {
    children: React.ReactNode
    delay?: string
}

const AnimatedText = ({ children, delay }: Props): JSX.Element => {
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
        triggerOnce: true,
    })

    const renderContent = (): JSX.Element =>
        // @ts-ignore - // TODO: figure out a correct type for 'children'
        children.split('\n').map((text: string) => {
            return (
                <span key={text} className={s.outer} ref={ref}>
                    <span
                        className={cx(s.inner, {
                            [s.visible]: inView,
                        })}
                        style={delay && { transitionDelay: delay }}
                    >
                        {text}
                    </span>
                </span>
            )
        })

    return children ? renderContent() : <></>
}

AnimatedText.defaultProps = {
    delay: '0',
}

export default AnimatedText
