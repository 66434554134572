import React from 'react'
import { Badge } from '@mantine/core'
// import StatusLabelStyle from './StatusLabel.styles'

interface Props {
    color?: string
    backgroundColor?: string
    children: React.ReactNode
}

const StatusLabel = ({
    color,
    backgroundColor,
    children,
}: Props): JSX.Element => {
    return (
        <>
            <Badge
                component="span"
                radius={0}
                style={{
                    backgroundColor,
                    color,
                    // TODO move to theme, and there the fontFamily should already be set
                    fontFamily: 'Shentox, Roboto, sans-serif',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    margin: '0 10px',
                    padding: '10px 5px',
                    position: 'relative',
                    top: '-1px',
                }}
            >
                {children}
            </Badge>
            {/* <StatusLabelStyle
                backgroundColor={backgroundColor}
                textColor={textColor}
            >
                {children}
            </StatusLabelStyle> */}
        </>
    )
}

StatusLabel.defaultProps = {
    color: '#fff',
    backgroundColor: '#212121',
}

export default StatusLabel
