import React, { FC } from 'react'
import s from './MediaEmbed.module.scss'

interface IframeProps {
    src: string
    title?: string
}

const Iframe: FC<IframeProps> = ({ src, title }): JSX.Element => (
    <div className={s.videoEmbed}>
        <iframe
            src={src}
            title={title}
            frameBorder={0}
            allowFullScreen
            loading="lazy"
        />
    </div>
)

export default Iframe
