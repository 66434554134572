import NavigationItemType from '../../models/navigation-item-type'

// This is the new nav item structure
const navItems: Array<NavigationItemType> = [
    {
        translationId: 'navigation.news',
        page: 'news',
        dataId: 'eveNav_news',
        selectedValues: ['news', 'articles'],
    },
    {
        translationId: 'navigation.eveStore',
        href: 'https://store.eveonline.com',
        locHref: 'https://store.eveonline.com/{language}',
        dataId: 'eveNav_eveStore',
    },
    {
        translationId: 'navigation.discoverEve',
        dataId: 'eveNav_discover',
        selectedValues: ['eve-academy', 'frontlines', 'capsuleer-chronicles'],
        children: [
            {
                translationId: 'navigation.eveAcademyDetail',
                dataId: 'eveNav_eveAcademy',
                page: 'eve-academy',
            },
            {
                translationId: 'navigation.frontlines',
                dataId: 'eveNav_frontlines',
                page: 'frontlines',
            },
            {
                translationId: 'navigation.eveUniverse',
                dataId: 'eveNav_eveUniverse',
                href: 'https://universe.eveonline.com',
                external: true,
            },
            {
                translationId: 'navigation.capsuleerChronicles',
                dataId: 'capsuleerChronicles',
                page: 'capsuleer-chronicles',
            },
        ],
    },
    {
        translationId: 'navigation.community',
        dataId: 'eveNav_community',
        selectedValues: ['fanfest', 'partners'],
        children: [
            {
                translationId: 'navigation.forums',
                dataId: 'eveNav_forums',
                page: 'https://forums.eveonline.com',
                href: 'https://forums.eveonline.com',
                external: true,
            },
            {
                translationId: 'navigation.fanfest',
                dataId: 'eveNav_fanfest',
                page: 'fanfest',
            },
            {
                translationId: 'navigation.allianceTournament',
                dataId: 'eveNav_allianceTournament',
                page: 'alliance-tournament',
            },
            {
                translationId: 'navigation.communityTournaments',
                dataId: 'eveNav_communityTournaments',
                href: 'https://www.eveonline.com/now/community-tournaments',
            },
            {
                translationId: 'navigation.eveOnTwitch',
                dataId: 'eveNav_eveOnTwitch',
                href: 'https://www.twitch.tv/directory/game/EVE%20Online',
                external: true,
            },
            {
                translationId: 'navigation.partnershipProgram',
                dataId: 'eveNav_partnershipProgram',
                page: 'partners',
            },
            {
                translationId: 'navigation.volunteerSystem',
                dataId: 'eveNav_volunteersSystem',
                href: 'https://volunteers.eveonline.com',
                external: true,
            },
            {
                translationId: 'navigation.discord',
                dataId: 'eveNav_discord',
                page: 'discord',
            },
        ],
    },
    {
        translationId: 'navigation.support',
        href: 'https://support.eveonline.com/',
        locHref: 'https://support.eveonline.com/hc/{language}',
        dataId: 'eveNav_support',
    },
    {
        translationId: 'navigation.download',
        page: 'download',
        dataId: 'eveNav_download',
        selectedValues: ['download'],
    },
]

export default navItems
